<template>
  <index-loader></index-loader>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>
  <!-- Page Wrapper -->
  <div class="row">
    <div class="col-md-12">
      <!-- <dashboard-header /> -->

      <div class="row position-relative m-0">
        <div class="organisation-data-form" v-if="sendFromOrg">
          <Form @submit="sendLOA">
            <h4 class=" pb-4 text-center">LETTER OF AUTHORITY</h4>
            <div class="row">
              <div class="col-md-12 company-field">
                <div class="form-wrap">
                  <label class="col-form-label">Agent Email </label>
                  <Field name="agent_email" as="input" disabled type="text" class="form-control"
                    v-model="formData.AgentEmail" />
                  <ErrorMessage name="agent_email" class="text-danger" />
                </div>
              </div>
              <div class="col-md-12 company-field">
                <div class="form-wrap">
                  <label class="col-form-label">LOA Template </label>
                  <Field as="select" name="loa_temp" class="form-select" v-model="formData.loa_template">
                    <option value=""><b>DocuSign Templates</b></option>
                    <option v-for="res in loaTempOptions" :key="res.value" :value="res.value">
                      {{ res.text }}
                    </option>
                  </Field>
                </div>
              </div>
              <div class="col-md-12 company-field">
                <div class="form-wrap">
                  <label class="col-form-label">LOA Start Date</label>
                  <div class="icon-form">
                    <date-picker textInput placeholder="" class="datetimepicker form-control" :editable="true"
                      :clearable="true" v-model="formData.MainDetailsData.loa_start_date"
                      :input-format="dateFormat" />
                    <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                  </div>
                </div>
              </div>
              <div class="col-md-12 company-field">
                <div class="form-wrap">
                  <label class="col-form-label">LOA Expiry</label>
                  <div class="icon-form">
                    <date-picker textInput placeholder="" class="datetimepicker form-control" :editable="true"
                      :clearable="true" v-model="formData.MainDetailsData.loa_end_date"
                      :input-format="dateFormat" />
                    <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="submit-button text-end mb-3">
              <button type="submit" class="btn btn-primary">
                Send LOA
              </button>
            </div>
          </Form>
        </div>
        <div class="user-accordion-item" v-else>
          <a href="javascript:;" class="accordion-wrap collapsed" data-bs-toggle="collapse"
            data-bs-target="#basicDetails"><span><i class="ti ti-details"></i></span>Send LOA</a>
          <div class="accordion-collapse collapse" id="basicDetails" data-bs-parent="#list-accord">
            <div class="content-collapse m-0 p-0">
              <div class="row mx-0">
                <div class="col-md-12">
                  <Form @submit="sendLOA" :validation-schema="schema">
                    <h5 class="my-2 pt-1">LETTER OF AUTHORITY</h5>
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-wrap">
                          <label class="col-form-label">Agent Email </label>
                          <Field name="agent_email" as="input" type="text" class="form-control"
                            v-model="formData.AgentEmail" />
                          <ErrorMessage name="agent_email" class="text-danger" />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-wrap">
                          <label class="col-form-label">LOA Template </label>
                          <Field as="select" name="loa_temp" class="form-select" v-model="formData.loa_template">
                            <option value=""><b>DocuSign Templates</b></option>
                            <option v-for="res in loaTempOptions" :key="res.value" :value="res.value">
                              {{ res.text }}
                            </option>
                          </Field>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-wrap">
                          <label class="col-form-label">LOA Start Date</label>
                          <div class="icon-form">
                            <date-picker textInput placeholder="" class="datetimepicker form-control" :editable="true"
                              :clearable="true" v-model="formData.MainDetailsData.loa_start_date"
                              :input-format="dateFormat" />
                            <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="form-wrap">
                          <label class="col-form-label">LOA Expiry</label>
                          <div class="icon-form">
                            <date-picker textInput placeholder="" class="datetimepicker form-control" :editable="true"
                              :clearable="true" v-model="formData.MainDetailsData.loa_end_date"
                              :input-format="dateFormat" />
                            <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h5 class="mb-2">CONTACT DETAILS</h5>
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-wrap mb-1">
                          <label class="col-form-label">Contact List</label>
                          <Field as="select" name="sales_contact" class="form-select" v-model="selectedContact"
                            @change="handleContactChange">
                            <option value="">-----Select-----</option>
                            <option v-for="res in orgPersons" :key="res.id" :value="res.id">
                              {{ res.name }}
                            </option>
                          </Field>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-wrap mb-1">
                          <label class="col-form-label">Title</label>
                          <select id="status" class="form-select" v-if="options && options.org_p_salutation"
                            v-model="formData.MainDetailsData.title">
                            <option value="">-----Select-----</option>
                            <option v-for="res in options.org_p_salutation" :key="res.value" :value="res.value">
                              {{ res.text }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-wrap mb-1">
                          <label class="col-form-label">Contact Name <span class="text-danger">*</span></label>
                          <Field name="contact_name" as="input" type="text" class="form-control"
                            v-model="formData.MainDetailsData.contact_name" />
                          <ErrorMessage name="contact_name" class="text-danger" />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-wrap mb-1">
                          <label class="col-form-label">Job Title <span class="text-danger">*</span></label>
                          <Field name="job_title" as="input" type="text" class="form-control"
                            v-model="formData.MainDetailsData.job_title" />
                          <ErrorMessage name="job_title" class="text-danger" />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <!-- <div class="col-md-3">
                    <div class="form-wrap">
                      <label class="col-form-label">Site Contact</label>
                      <Field name="site_contact" as="input" type="text" class="form-control" v-model="formData.AgentEmail"/>
                      <ErrorMessage name="site_contact" class="text-danger" />
                    </div>
                  </div> -->
                      <div class="col-md-3">
                        <div class="form-wrap mb-1">
                          <label class="col-form-label">Direct Line</label>
                          <Field name="direct_line" as="input" type="text" class="form-control"
                            v-model="formData.MainDetailsData.direct_line" />
                          <ErrorMessage name="direct_line" class="text-danger" />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-wrap mb-1">
                          <label class="col-form-label">Landline <span class="text-danger">*</span></label>
                          <Field name="landline" as="input" type="text" class="form-control"
                            v-model="formData.MainDetailsData.landline" />
                          <ErrorMessage name="landline" class="text-danger" />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-wrap mb-1">
                          <label class="col-form-label">Mobile</label>
                          <Field name="mobile" as="input" type="text" class="form-control"
                            v-model="formattedTelephone" />
                          <ErrorMessage name="mobile" class="text-danger" />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-wrap mb-1">
                          <label class="col-form-label">Fax</label>
                          <Field name="fax" as="input" type="text" class="form-control"
                            v-model="formData.MainDetailsData.fax" />
                          <ErrorMessage name="fax" class="text-danger" />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-wrap mb-1">
                          <label class="col-form-label">Email <span class="text-danger">*</span></label>
                          <Field name="person_email" as="input" type="text" class="form-control"
                            v-model="formData.MainDetailsData.email" />
                          <ErrorMessage name="person_email" class="text-danger" />
                        </div>
                      </div>
                    </div>
                    <div class="submit-button text-end mb-3">
                      <button type="submit" class="btn btn-primary">
                        Send LOA
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>

<script>
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { onMounted, computed } from "vue";
import { useStore, mapGetters } from "vuex";
import { message } from "ant-design-vue";
import { useToast } from "vue-toast-notification";
import * as Yup from "yup";
import apiClient from "@/axios";
import { Form, Field, ErrorMessage } from "vee-validate";
import { changeFormate } from '../../../../util'
export default {
  props: {
    orgPersons: {
      type: Array,
      default: () => [],
    },
    opportunityData: {
      type: Object,
      default: () => ({}),
    },
    sendFromOrg: {
      type: Boolean,
      default: () => false,
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      dateFormat: "dd/MM/yyyy",
    };
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const transformedData = ref({});
    const loaTempOptions = ref([
      {
        value: "power",
        text: "POWER Standard LOA",
      },
      {
        value: "u4b",
        text: "U4B LOA",
      },
      {
        value: "sse",
        text: "SSE - LOA",
      },
    ]);
    const loaHeaderToUse = ref([
      {
        value: "Site Name",
        text: "Site Name",
      },
      {
        value: "Company Name",
        text: "Company Name",
      },
    ]);
    const options = ref([]);
    const selectedContact = ref(props.opportunityData?.sales_contact || "");
    const handleContactChange = async () => {
      try {
        const res = await apiClient.get(
          `/organisation/viewPerson/${selectedContact.value}`
        );
        const data = res.data.data;
        if (data) {
          formData.value.MainDetailsData.title = data.salutation;
          formData.value.MainDetailsData.contact_name =
            data.first_name + " " + (data.last_name || "");
          formData.value.MainDetailsData.job_title = data.job_title;
          formData.value.MainDetailsData.landline = data.direct_telephone;
          formData.value.MainDetailsData.mobile = data.mobile_telephone;
          formData.value.MainDetailsData.email = data.email;
        }
      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
    };

    const formData = ref({
      AgentEmail: props.opportunityData?.sales_responsible_data?.email || props.opportunityData?.agent_email || "", // sales responsible email
      service_type: props.opportunityData?.main_production_service || "",
      loa_template: "power",
      MainDetailsData: {
        premises_name: props.opportunityData?.organization?.name || props.opportunityData?.name || "", // org name
        email: props.opportunityData?.persons?.[0]?.email || "", // person email
        contact_name: props.opportunityData?.persons?.[0]?.first_name + props.opportunityData?.persons?.[0]?.last_name  || "", // person name
        company_number:
          props.opportunityData?.organization?.company_reg_no || props.opportunityData?.company_reg_no || "", // org Company Reg No:
        post_code: props.opportunityData?.supply_address?.postcode || props.opportunityData?.postcode || "", // opp supply addres post code
        address_1: props.opportunityData?.supply_address?.building_name || props.opportunityData?.address || "", // opp supply building name
        company_landline: props.opportunityData?.organization?.telephone ||  props.opportunityData?.telephone || "", // org landline / phone number / mobile
        landline: props.opportunityData?.persons?.[0]?.landline || "", // person landline
        expiry_date: null, // TBD
        reg_no: props.opportunityData?.organization?.company_reg_no || props.opportunityData?.company_reg_no || "", // org Company Reg No:
        mobile: props.opportunityData?.persons?.[0]?.mobile_telephone || "", // person mobile
        loa_start_date: null, // loa start date
        loa_end_date: null, // loa end date
        business_type: "LTD", //
        address_2: props.opportunityData?.supply_address?.street || "", // opp street
        address_3: props.opportunityData?.supply_address?.city || props.opportunityData?.city ||"", // city
        job_title: props.opportunityData?.persons?.[0]?.job_title || "", // person job title
        title: props.opportunityData?.persons?.[0]?.salutation || "Mr", // person title
        fax: "", // person fax
        direct_line: props.opportunityData?.persons?.[0]?.direct_line || "",
      },
      AuxiliaryDetailsData: {
        BrokerAgentFullName:
          props.opportunityData?.sales_responsible_data?.first_name + props.opportunityData?.sales_responsible_data?.last_name || props.opportunityData?.responsible_name || "", // sales responsible name
        BrokerAgentEmail:
          props.opportunityData?.sales_responsible_data?.email || props.opportunityData?.persons[0]?.email || "", // sales responsible email
        BrokerAgentPhoneNumber:
          props.opportunityData?.sales_responsible_data?.phone_number ||  props.opportunityData?.persons[0]?.mobile_telephone  || "", // sales phone
        PremisesName: props.opportunityData?.organization?.name || props.opportunityData?.name || "", // org name
        PremisesAddress1:
          props.opportunityData?.supply_address?.building_name ||  props.opportunityData?.address || "", // opp supply add building name
        PremisesAddress2: props.opportunityData?.supply_address?.street || props.opportunityData?.address ||"", // opp street
        PremisesAddress3: props.opportunityData?.supply_address?.street || props.opportunityData?.city || "", // opp street
        PremisesAddress4: props.opportunityData?.supply_address?.city || props.opportunityData?.state || "", // opp city
        PremisesPostcode: props.opportunityData?.supply_address?.postcode || props.opportunityData?.postcode || "", // opp post code
        CompanyName: props.opportunityData?.organization?.name || props.opportunityData?.name || "", // org name
        CompanyAddress1:
          props.opportunityData?.supply_address?.building_name || props.opportunityData?.address || "", // opp supply add building name
        CompanyAddress2: props.opportunityData?.supply_address?.street || props.opportunityData?.address || "", // opp street
        CompanyAddress3: props.opportunityData?.supply_address?.street || props.opportunityData?.city ||"", // opp street
        CompanyAddress4: props.opportunityData?.supply_address?.city || props.opportunityData?.state || "", // opp city
        CompanyPostcode: props.opportunityData?.supply_address?.postcode || props.opportunityData?.postcode || "", // opp post code
        CompanyNumber:
          props.opportunityData?.organization?.company_reg_no || props.opportunityData?.company_reg_no || "", // org Company Reg No:
        siteorcompanyname: props.opportunityData?.organization?.name || props.opportunityData?.name || "", // org name
        siteorcompanyaddress1:
          props.opportunityData?.supply_address?.building_name || props.opportunityData?.address || "", // opp supply add building name
        siteorcompanyaddress2:
          props.opportunityData?.supply_address?.street || props.opportunityData?.address || "", // opp street
        siteorcompanyaddress3:
          props.opportunityData?.supply_address?.street ||  props.opportunityData?.city || "", // opp street
        siteorcompanyaddress4:
          props.opportunityData?.supply_address?.city || props.opportunityData?.state || "", // opp city
        siteorcompanypostcode:
          props.opportunityData?.supply_address?.postcode || props.opportunityData?.postcode || "", // opp post code
        siteorcompanylandline: "", // person landline
        siteorcompanyemail: "", // person email
        siteorcompanycontactname: "", // person name
        siteorcompanyjobtitle: "", //person job title
      },
    });
    const schema = Yup.object().shape({
      contact_name: Yup.string().required("Contact name is required"),
      job_title: Yup.string().required("Job title is required"),
      landline: Yup.string().required("Landline number is required"),
      person_email: Yup.string().required("Contact email is required"),
    });
    const mapMainToAuxiliary = () => {
      formData.value.AuxiliaryDetailsData.siteorcompanylandline =
        formData.value.MainDetailsData.landline;
      formData.value.AuxiliaryDetailsData.siteorcompanyemail =
        formData.value.MainDetailsData.email;
      formData.value.AuxiliaryDetailsData.siteorcompanycontactname =
        formData.value.MainDetailsData.contact_name;
      formData.value.AuxiliaryDetailsData.siteorcompanyjobtitle =
        formData.value.MainDetailsData.job_title;
      transformData();
    };

    const transformData = () => {
      transformedData.value = {
        service_type: formData.value.service_type === 'Gas' ? 'gas' : 'elec',
        loa_template: formData.value.loa_template,
        AgentEmail: formData.value.AgentEmail,
        docusignDetails: {
          MainDetailsData: Object.entries(formData.value.MainDetailsData).map(
            ([key, value]) => {
              if (key === 'loa_start_date' || key === 'loa_end_date') {
                return ({
                  Key: key.replace(/_/g, " "),
                  Value: changeFormate(value),
                })
              }
              else {
                return ({
                  Key: key.replace(/_/g, " "),
                  Value: value,
                })
              }
            }
          ),
          AuxiliaryDetailsData: Object.entries(
            formData.value.AuxiliaryDetailsData
          ).map(([key, value]) => ({
            Key: key,
            Value: value,
          })),
          TemplateOptions: {
            HalfHourly: false,
            ChangeOfTenancy: false,
            PlanType: "",
          },
        },
      };
    };
    const sendLOA = async () => {
      mapMainToAuxiliary();
      const response = await apiClient.post(
        "docusign/sendloa",
        transformedData.value
      );
      if (response?.data?.response?.SendLOAResult?.Error === null) {
        toast.success("Letter of Authority has been sent successfully");
      }
      else {
        toast.error(response?.data?.response?.SendLOAResult?.Error?.Message)
      }
    };

    // LOA
    const fetchOptions = async () => {
      try {
        const response = await apiClient.get("settings/organisation-person");
        options.value = response.data.data.dropdowns;
      } catch (error) {
        console.error("Error fetching options data:", error);
      }
    };

    // Assuming form is a reactive object
    const formattedTelephone = computed({
      get() {
        // Ensure form and form.value.telephone are defined
        if (!formData.value || !formData.value.MainDetailsData.mobile) {
          return ''; // Return an empty string if there's no telephone number
        }

        const number = formData.value.MainDetailsData.mobile.replace(/\D/g, '');

        // Check if the number is valid (e.g., 10 digits)
        if (number.length === 10) {
          // Format the number
          return `(${number.substring(0, 3)}) ${number.substring(3, 6)}-${number.substring(6)}`;
        } else {
          // Return the original value if the number is not valid
          return formData.value.MainDetailsData.mobile;
        }
      },
      set(value) {
        // Clean the input value to store only digits
        const cleanedValue = value.replace(/\D/g, '');

        // If the cleaned value is empty, set form.telephone to an empty string
        formData.value.MainDetailsData.mobile = cleanedValue.length ? cleanedValue : '';
      }
    });

    onMounted(async () => {
      fetchOptions();
      if (selectedContact.value !== "") {
        handleContactChange();
      }
    });

    return {
      schema,
      transformedData,
      mapMainToAuxiliary,
      sendLOA,
      loaTempOptions,
      options,
      fetchOptions,
      loaHeaderToUse,
      formData,
      store,
      route,
      router,
      handleContactChange,
      selectedContact,
      formattedTelephone,
    };
  },
};
</script>

<style scoped>
.filter-panel {
  position: fixed;
  right: -400px;
  top: 57px;
  width: 290px;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease-in-out;
  z-index: 9999;
  height: 100%;
}

.filter-panel.open {
  right: 0;
}

.filter_btn {
  position: absolute;
  left: -20px;
}
.company-field {
    /* height: 100px; */
    padding: 0 !important;
    margin: 0 !important;
}
</style>
