<template>
<div class="account-page">
  <div class="main-wrapper">
    <div class="account-content">
      <div class="login-wrapper account-bg forgot-bg">
        <div class="login-content">
          <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
            <div class="login-user-info">
              <div class="login-logo">
                <img src="@/assets/img/anviam.png" class="img-fluid" alt="Logo" />
              </div>
              <div class="login-heading">
                <h4>Forgot Password?</h4>
                <p>
                  If you forgot your password, well, then we’ll email you instructions
                  to reset your password.
                </p>
              </div>
              <div class="form-wrap">
                <label class="col-form-label">Email Address</label>
                <div class="form-wrap-icon">
                  <Field name="email" type="text" v-model="email" placeholder="Enter Your Email" class="form-control" :class="{ 'is-invalid': errors.email }" />
                  <div class="invalid-feedback">{{ errors.email }}</div>
                  <div class="emailshow text-danger" id="email"></div>
                  <i class="ti ti-mail" v-if="!Object.keys(errors).length"></i>
                </div>
              </div>
              <div class="form-wrap">
                <button type="submit" class="btn btn-primary">Submit</button>
              </div>
              <div class="login-form text-center">
                <h6>
                  Return to <router-link to="/" class="hover-a">Log In</router-link>
                </h6>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import * as Yup from 'yup';
import { Form, Field } from "vee-validate";
import { message } from 'ant-design-vue';

export default {
  components: {
    Form,
    Field,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const email = ref('');

    const schema = Yup.object().shape({
      email: Yup.string()
        .required("Email is required")
        .email("Email is invalid"),
    });

    const onSubmit = async (values) => {
      try {
       await store.dispatch('auth/forgotPassword', values.email);
      } catch (error) {
        console.error(error);
      }
    };

    return {
      schema,
      onSubmit,
      email,
    };
  },
};
</script>

