<template>
<a-table class="stripped table-hover" :columns="columns" :data-source="data" :pagination="pagination" :row-selection="rowSelection" @change="handleTableChange">
  <template #bodyCell="{ column, record }">
    <template v-if="column.key === 'Name'">
      <h2 class="table-avatar d-flex align-items-center">
        <router-link to="/crm/company-details" class="profile-split d-flex flex-column">{{ record.name }}</router-link>
      </h2>
    </template>
    <template v-else-if="column.key === 'action'">
      <div class="icon-field">
            
            <router-link :to="{ path: '/territory/create-territory', query: { territoryId: record.key}}" title="Edit" class="btn table_action_btn"><i class="ti ti-edit"></i>  Edit</router-link>
            </div>
    </template>
  </template>
</a-table>
</template>

<script>
import {
  useRouter
} from 'vue-router';

import apiClient from "@/axios";
import {
  message
} from "ant-design-vue";
const columns = [{
    title: "Name",
    dataIndex: "name",
    key: "Name",
    sorter: {
      compare: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Created",
    dataIndex: "created",
    sorter: {
      compare: (a, b) => {
        a = a.created;
        b = b.created;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    dataIndex: "Action",
    key: "action",
    // sorter: true,
  },
];

export default {
  data() {
    return {
      columns,
      selectedUserId: null,
      showDeleteModal: false,
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      rowSelection: {
        onChange: () => {},
        onSelect: () => {},
        onSelectAll: () => {},
      },
    };
  },
  mounted() {
    this.fetchTerritories();
  },
  setup() {
    const router = useRouter();

    // const openEditModal = (id) => {
    //   router.push({
    //     path: "/territory/create-territory",
    //     query: {
    //       territoryId: id
    //     }
    //   });
    // };

    return {
      // openEditModal,
    };
  },
  methods: {
    async fetchTerritories(page = 1, pageSize = 10) {
      try {
        const token = localStorage.getItem("authToken"); // Adjust the key if necessary
        if (!token) {
          return;
        }
        const response = await apiClient.get("/territories", {
          params: {
            page,
            pageSize
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status) {
          this.data = response.data.data.data.map((territory) => ({
            key: territory.id,
            name: territory.name,
            created: this.formatDate(territory.created_at),
          }));
          this.pagination.total = response.data.data.total;
        } else {
          console.error("Failed to fetch territories:", response);
        }
      } catch (error) {
        console.error("Error fetching territories:", error);
      }
    },
    formatDate(dateTime){
       return dateTime.replace(/-/g, '/');
    },
    handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.fetchTerritories(pagination.current, pagination.pageSize);
    },
  },
};
</script>
