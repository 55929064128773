// store/modules/user.js

import apiClient from "@/axios";

const state = {
  territories: [],
};

const getters = {
  getterritories: (state) => state.territories,
};


const mutations = {
  SET_TERRITORIES(state, territories) {
    state.territories = territories;
  },
};

const actions = {
  async fetchTerritories({ commit }) {
    try {
      const response = await apiClient.get('/dropdown/territories');

      commit('SET_TERRITORIES', response.data);
    } catch (error) {
      console.error('Error fetching territories:', error);
    }
  },
  async fetchCompanyPermissions({ commit }) {
    try {
      const response = await apiClient.get('/settings/permissions-list');
      return response.data.data;
    } catch (error) {
      console.error('Error fetching company permissions:', error);
    }
  },
  async fetchUserPermissions({ commit }) {
    try {
      const response = await apiClient.get('/settings/assigned-permissions');
      return response.data.data;
    } catch (error) {
      console.error('Error fetching user permissions:', error);
    }
  },

  async createCompanyAdmin({ commit }, form) {
    try {
      const formData = new FormData();
    
      // Append all fields to FormData
      Object.keys(form).forEach(key => {
        const value = form[key];
    
        if (key === 'admin_user' && typeof value === 'object') {
          // Handle the nested admin_user object
          Object.keys(value).forEach(innerKey => {
            const innerValue = value[innerKey];
            formData.append(`admin_user[${innerKey}]`, innerValue);
          });
        } else if (Array.isArray(value)) {
          // Handle arrays
          if (value.length > 0) {
            value.forEach((item, index) => {
              formData.append(`${key}[${index}]`, item);
            });
          } else {
            formData.append(key, ''); // Send empty array
          }
        } else if (value !== null) {
          // Only append non-null values
          formData.append(key, value);
        }
      });
    
      // Handle image for updates
      if (form.id && typeof form.company_logo === 'string') {
        formData.set('company_logo', '');
      }
    
      // API request
      const endpoint = form.id
        ? `/admin/update-company-admin/${form.id}`
        : '/admin/create-company-admin';
    
      const response = await apiClient.post(endpoint, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    
      return response;
    } catch (error) {
      console.error('Error saving user data:', error);
      return {
        status: false,
        errors: error.response?.data?.errors || 'Unknown error',
      };
    }
  },
  

  async createUser({ commit }, form) {
    try {
      const formData = new FormData();
  
      // Append all fields to FormData
      Object.keys(form).forEach(key => {
        const value = form[key];
  
        if (Array.isArray(value)) {
          // Handle arrays
          if (value.length > 0) {
            value.forEach((item, index) => {
              formData.append(`${key}[${index}]`, item);
            });
          } else {
            formData.append(key, ''); // Send empty array
          }
        } else if (value !== null) {
          // Only append non-null values
          formData.append(key, value);
        }
      });

      // Handle image for updates
      if (form.id && typeof form.image === "string") {
        formData.set('image', "");
      }
      // API request
      const endpoint = form.id
        ? `/company/users/update/${form.id}`
        : '/company/user/register';
  
      const response = await apiClient.post(endpoint, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
  
      return response;
    } catch (error) {
      console.error('Error saving user data:', error);
      return {
        status: false,
        errors: error.response?.data?.errors || 'Unknown error',
      };
    }
  },
  async createPipeline({ commit }, form) {
    try {
      // API request
      const endpoint = form.id
        ? `/pipeline-level/update/${form.id}`
        : '/pipeline-level/create';
  
      const response = await apiClient.post(endpoint, form);
      return response;
    } catch (error) {
      console.error('Error saving user data:', error);
      return {
        status: false,
        errors: error.response?.data?.errors || 'Unknown error',
      };
    }
  },
  
  async getCompanyAdmins({ commit }, params) {
    try {
      const response = await apiClient.get('/admin/company-admins', { params });
      return response.data;
    } catch (error) {
      console.error('Error saving company data:', error);
    }
  },
  async getCompanyData({ commit }, id) {
    try {
      const response = await apiClient.get(`/admin/view-company-admin/${id}`);
      return response.data.data;
    } catch (error) {
      console.error('Error saving organization data:', error);
    }
  },
  async changeCompanyStatus({ commit }, params) {
    try {
      const response = await apiClient.post(
        `/admin/activate-deactivate/${params.id}`,
        {
          status: params.text
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error saving organization data:', error);
      // return { status: false, errors: error.response.data.errors };
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
