<template>
<a-table class="stripped table-hover" :columns="columns" :data-source="data" :pagination="pagination" :row-selection="rowSelection" @change="handleTableChange">
  <template #bodyCell="{ column, record }">
    <template v-if="column.key === 'Name'">
      <h2 class="table-avatar d-flex align-items-center">
        <router-link to="/crm/company-details" class="profile-split d-flex flex-column">{{ record.name }}</router-link>
      </h2>
    </template>
    <template v-else-if="column.key === 'status'">
      <span class="badge badge-pill" :class="record.status === 1 ? 'badge-status bg-success' : 'badge-status bg-danger'">
        {{ record.status === 1 ? 'Active' : 'Inactive' }}
      </span>
    </template>
    <template v-else-if="column.key === 'action'">
      <div class="dropdown table-action">
        <a href="javascript:;" class="action-icon" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="fa fa-ellipsis-v"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right">
          <router-link :to="{ path: '/companies/create-company', query: { comp_id: record.key }}" title="Edit"><i class="ti ti-edit text-blue"></i>Edit</router-link>
          <a class="dropdown-item" href="javascript:;" @click="changeStatus(record.key, record.status === 1 ? 'Deactivate' : 'Activate')">
            <i class="ti ti-trash text-danger"></i> {{ record.status === 1 ? 'Inactive' : 'Active' }}
          </a>
        </div>
      </div>

      <!-- <div class="icon-field">
        <router-link :to="{ path: '/companies/create-company', query: { comp_id: record.key }}" title="Edit"><i class="ti ti-edit text-blue"></i></router-link>
      </div> -->
    </template>
  </template>
</a-table>

</template>

<script>
import {
  ref,
  reactive,
  onMounted,
  watchEffect
} from 'vue';
import {
  useRouter
} from 'vue-router';
import {
  useStore
} from "vuex";
import apiClient from "@/axios";
import {
  message
} from "ant-design-vue";

export default {
  props: {
    searchQuery: String,
    // filters: Object,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    // Reactive state
    const columns = [{
        title: "Company Name",
        dataIndex: "comp_name",
        key: "comp_name",
        sorter: {
          compare: (a, b) => {
            a = a.comp_name;
            b = b.comp_name;
            return a > b ? -1 : b > a ? 1 : 0;
          },
        },
      },
      {
        title: "Company Email",
        dataIndex: "comp_email",
        sorter: {
          compare: (a, b) => {
            a = a.comp_email;
            b = b.comp_email;
            return a > b ? -1 : b > a ? 1 : 0;
          },
        }
      },
      {
        title: "Admin Name",
        dataIndex: "user_name",
        sorter: {
          compare: (a, b) => {
            a = a.user_name;
            b = b.user_name;
            return a > b ? -1 : b > a ? 1 : 0;
          },
        }
      },
      {
        title: "Admin Email",
        dataIndex: "user_email",
        sorter: {
          compare: (a, b) => {
            a = a.user_email;
            b = b.user_email;
            return a > b ? -1 : b > a ? 1 : 0;
          },
        }
      },
      {
        title: "Admin Contact",
        dataIndex: "user_phone_number",
        sorter: {
          compare: (a, b) => {
            a = a.user_phone_number;
            b = b.user_phone_number;
            return a > b ? -1 : b > a ? 1 : 0;
          },
        }
      },
      {
        title: "Total Users",
        dataIndex: "total_user",
        sorter: {
          compare: (a, b) => {
            a = a.total_user;
            b = b.total_user;
            return a > b ? -1 : b > a ? 1 : 0;
          },
        }
      },
      {
        title: "Expiry",
        dataIndex: "expiry",
        sorter: {
          compare: (a, b) => {
            a = a.expiry;
            b = b.expiry;
            return a > b ? -1 : b > a ? 1 : 0;
          },
        }
      },
      {
        title: "Status",
        dataIndex: "status",
        key: 'status',
        sorter: {
          compare: (a, b) => {
            a = a.status;
            b = b.status;
            return a > b ? -1 : b > a ? 1 : 0;
          },
        }
      },
      {
        title: "Action",
        dataIndex: "Action",
        key: "action",
      },
    ];

    const data = ref([]);
    const pagination = ref({
      current: 1,
      pageSize: 10,
      total: 0
    });
    const rowSelection = reactive({
      onChange: () => {},
      onSelect: () => {},
      onSelectAll: () => {},
    });

    const fetchCompanies = async () => {
      const params = {
        company_name: props.searchQuery,
        page: pagination.value.current,
        pageSize: pagination.value.pageSize,
      };
      try {
        const response = await store.dispatch('users/getCompanyAdmins', params);
        if (response.status) {
          data.value = response.data.data.map((user) => ({
            key: user?.id,
            comp_name: user?.name,
            comp_email: user?.email,
            user_name: user?.admin_user?.last_name ? `${user?.admin_user?.first_name} ${user?.admin_user?.last_name}` : user?.admin_user?.first_name,
            user_email: user?.admin_user?.email,
            user_phone_number: formatPhoneNumber(user?.admin_user?.phone_number),
            total_user:user?.users_count,
            expiry: user?.subscription_end_date,
            status: user?.is_active,
          }));
          pagination.value.total = response.data.total;
        } else {
          console.error("Failed to fetch users:", response);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    const formatPhoneNumber = (number) => {
      if (!number) {
        return '';
      }
      const cleaned = number.replace(/\D/g, '');
      if (cleaned.length === 10) {
        return `(${cleaned.substring(0, 3)}) ${cleaned.substring(3, 6)}-${cleaned.substring(6)}`;
      } else {
        return number;
      }
    };

    const changeStatus = async (id, text) => {
      const params = {
        id: id,
        text: text 
      }
      const data = await store.dispatch('users/changeCompanyStatus', params)
      if(data.status){
        fetchCompanies();
        message.error(data.message);
      }
    };

    const handleTableChange = (newPagination) => {
      pagination.value.current = newPagination.current;
      pagination.value.pageSize = newPagination.pageSize;
      fetchCompanies();
    };
    // watchEffect(() => {
    //   fetchCompanies();
    // });
    // Lifecycle hook
    onMounted(() => {
      fetchCompanies();
    });

    return {
      changeStatus,
      columns,
      data,
      pagination,
      rowSelection,
      fetchCompanies,
      formatPhoneNumber,
      handleTableChange,
    };
  },
};
</script>
