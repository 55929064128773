<template>
<div>
  <div class="row mb-3">
    <div class="col-md-6">
      <label for="min-amount">Min (Months) </label>
      <input type="number" id="min-amount" v-model="minValue" @input="updateSlider" class="form-control" />
    </div>
    <div class="col-md-6">
      <label for="max-amount">Max (Months)</label>
      <input type="number" id="max-amount" v-model="maxValue" @input="updateSlider" class="form-control" />
    </div>
  </div>
  <div class="row m-0">
    <div class="col-12">
      <div id="slider-range"></div>
    </div>
  </div>
</div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/slider';

export default {
  props: {
    // Accept minValue and maxValue as props to maintain the values between component re-renders
    initialMinValue: {
      type: Number,
      default: 12,  // Set the default value to 12 if not provided by parent
    },
    initialMaxValue: {
      type: Number,
      default: 60,  // Set the default value to 60 if not provided by parent
    },
  },
  setup(props, { emit }) {
    // Initialize state with props, so it won't reset to default on tab change
    const minValue = ref(props.initialMinValue);
    const maxValue = ref(props.initialMaxValue);

    onMounted(() => {
      // Initialize jQuery slider only once
      $("#slider-range").slider({
        range: true,
        min: 12,
        max: 60,
        values: [minValue.value, maxValue.value],
        slide: function (event, ui) {
          const roundedMin = Math.round(ui.values[0] / 6) * 6;
          const roundedMax = Math.round(ui.values[1] / 6) * 6;

          minValue.value = roundedMin;
          maxValue.value = roundedMax;

          emit('update-min', roundedMin);
          emit('update-max', roundedMax);

          $(this).slider("values", 12, roundedMin);
          $(this).slider("values", 1, roundedMax);
        },
      });
    });

    // Watch for changes in minValue and maxValue to update the slider
    watch([minValue, maxValue], () => {
      $("#slider-range").slider("values", 12, minValue.value);
      $("#slider-range").slider("values", 1, maxValue.value);
      emit('update-min', minValue.value);
      emit('update-max', maxValue.value);
    });

    const updateSlider = () => {
      minValue.value = Math.max(12, Math.min(60, minValue.value));
      maxValue.value = Math.max(12, Math.min(60, maxValue.value));

      // Update the slider values when the input values change
      $("#slider-range").slider("values", 12, minValue.value);
      $("#slider-range").slider("values", 1, maxValue.value);

      emit('update-min', minValue.value);
      emit('update-max', maxValue.value);
    };

    return {
      updateSlider,
      minValue,
      maxValue,
    };
  },
};

</script>

<style scoped>

</style>
