// src/store/modules/org.js
import apiClient from '@/axios';
import { message } from 'ant-design-vue';
import { error } from 'jquery';

const state = {
  suppliers: [],
  dropdowns: {},
  lookUpData: [],
  lookUpElectricData: [],
  suppliersWithPrice: [],
  suppliersName: [],
};

const getters = {
  getSuppliers: (state) => state.suppliers,
  getDropdowns: (state) => state.dropdowns,
  getlookUpData: (state) => state.lookUpData,
  getlookUpElectricData: (state) => state.lookUpElectricData,
  getsuppliersWithPrice: (state) => state.suppliersWithPrice,
  getsuppliersName: (state) => state.suppliersName,
};

const mutations = {
  SET_SUPPLIERS(state, data) {
    state.suppliers = data;
  },
  SET_DROPDOWNS(state, data) {
    state.dropdowns = data;
  },
  SET_LOOKUP_DATA(state, data) {
    state.lookUpData = data;
  },
  SET_LOOKUP_ELECTRIC_DATA(state, data) {
    state.lookUpElectricData = data;
  },
  SET_SUPPLIERS_PRICE_DATA(state, data) {
    state.suppliersWithPrice = data;
  },
  SET_SUPPLIERS_NAME(state, data) {
    state.suppliersName = data;
  }
};

const actions = {
  async fetchSuppliers({ commit }) {
    try {
      const response = await apiClient.get('/quote/settings');
      if (response) {
        commit('SET_SUPPLIERS', response.data.data.supplier);
        commit('SET_DROPDOWNS', response.data.data.dropdowns);
        return response.data.data;
      }
    } catch (error) {
      console.error('Error fetching SET_SUPPLIERS:', error);
    }
  },

  async fetchLookupData({ commit }, paramsData) {
    try {
      // Make a POST request with paramsData
      const response = await apiClient.post('/lookup/site', paramsData);
      if (response) {
        commit('SET_LOOKUP_DATA', response.data.data.supplier); // Commit the fetched data
        return response.data.data; // Return the data for further use
      }
    } catch (error) {
      console.error('Error fetching lookup', error); // Log any errors
    }
  },

  async fetchSearchPropertyData({ commit }, paramsData) {

    try {
      // Make a POST request with paramsData
      const response = await apiClient.post('/lookup/energy/property/search-by-address-id', paramsData);

      if (response) {
        commit('SET_LOOKUP_ELECTRIC_DATA', response.data);
        return response.data;
      }
    } catch (error) {
      return error.response.data
    }
  },

  async getSupplierPrice({ commit }, formData) {

    try {
      // Make a POST request with formData
      const response = await apiClient.post('/power/pricing', formData);

      let suppliersPriceData = [];

      if (response.data.data.GetGasRatesResult) {
        suppliersPriceData = response.data.data.GetGasRatesResult.Rates;
      } else if (response.data.data.GetElectricRatesResult) {
        suppliersPriceData = response.data.data.GetElectricRatesResult.Rates;
      }
      // Filter suppliers where AnnualPrice is not null or zero
      const filteredSuppliers = suppliersPriceData.filter(supplier => supplier.AnnualPrice);
      commit('SET_SUPPLIERS_PRICE_DATA', filteredSuppliers);
      const supplierPriceData = new Set(filteredSuppliers.map(supplier => supplier.Supplier));
      commit('SET_SUPPLIERS_NAME', supplierPriceData)
      return filteredSuppliers; // Return the filtered data
    } catch (error) {
      console.error('Error fetching lookup', error);
    }
  },
  async downloadPdf({ commit }, paramsData) {
    try {
      // Make a POST request with paramsData
      const response = await apiClient.post('power/export-price-pdf', paramsData);
      if (response.status === 200) {
        // commit('SET_LOOKUP_DATA', response.data.data.supplier); // Commit the fetched data
        return response; // Return the data for further use
      }
    } catch (error) {
      console.error('Error fetching export-price-pdf', error); // Log any errors
    }
  },
  async downloadExcel({ commit }, excelData) {
    try {
      // Make a POST request with paramsData
      const response = await apiClient.post('power/export-price-excel', excelData);
      if (response.status === 200) {
        return response; // Return the data for further use
      }
    } catch (error) {
      console.error('Error fetching export-price-excel', error); // Log any errors
    }
  },
  async downloadSupplierPdf({ commit }, params) {
    try {
      // Make a POST request with paramsData
      const response = await apiClient.post('power/contract/generate-pdf', params);
      if (response.status === 200) {
        return response.data.response; // Return the data for further use
      } else {
        message.error('Error fetching export supplier pdf');
      }
    } catch (error) {
      console.error('Error fetching export-supplier-pdf', error); // Log any errors
      return { success: false, errors: error.response.data.message };
    }
  },

  async sendSupplierPdf({ commit }, params) {
    try {
      // Make a POST request with paramsData
      const response = await apiClient.post('docusign/send', params);
      if (response.data.success) {
        if (params.opportunity_id) {
          message.success('Email send successfully');
        } else {
          message.success('Opportunity created and Email send successfully');
        }
        return response.data; // Return the data for further use
      } else {
        // message.error(error.response.data.message);
      }
    } catch (error) {
      return { success: false, errors: error.response.data.message };
    }
  },
  async sendSmtpDocument({ commit }, params) {
    try {
      // Make a POST request with paramsData
      const response = await apiClient.post('power/mail-price-document', params);
      if (response.data.status) {
        message.success(response.data.message);
        return response.data; // Return the data for further use
      }
    } catch (error) {
      return { success: false, errors: error.response.data.errors };
    }
  },
  async sendSmtpPdf({ commit }, params) {
    try {
      // Make a POST request with paramsData
      const response = await apiClient.post('power/contract/generate-and-send-pdf', params);
      if (response.data.success) {
        message.success(response.data.message);
        return response.data; // Return the data for further use
      }
    } catch (error) {
      return { success: false, errors: error.response.data.message };
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
