<template>
    <div ref="promptModal" class="modal fade" id="prompt_modal" tabindex="-1" role="dialog"
        aria-labelledby="promptLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header border-0 m-0 p-0 justify-content-end">
                    <button class="btn-close" id="close-prompt" data-bs-dismiss="modal" aria-label="Close"
                        style="visibility: hidden;">
                        <i class="ti ti-x"></i>
                    </button>
                </div>
                <div class="modal-body confirm">
                    <Form class="toggle-height">
                        <div class="pro-create">
                            <div v-for="(group, groupIndex) in formData.filters" :key="groupIndex">
                                <div v-if="groupIndex > 0">
                                    <hr />
                                    <div class="pb-3"><strong>And Condition</strong></div>
                                </div>
                                <div v-for="(row, rowIndex) in group" :key="rowIndex">
                                    <div class="row align-items-center mb-3">

                                        <div class="col-md-4">
                                            <div class="">{{ `${row.module_name} / ${row.field_name}` }} </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="">
                                                <select v-model="row.condition"
                                                    class="form-select custom_form_select py-0"
                                                    :disabled="!row.field_id">
                                                    <option value="">Choose condition...</option>
                                                    <option v-for="(label, value) in settingField.critera_options"
                                                        :key="label" :value="value">
                                                        {{ label }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="">
                                                <input type="text" class="form-control custom_form_select py-0"
                                                    placeholder="Enter value" v-model="row.value" />
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div class="mt-4 d-flex justify-content-center">
                                <button class="btn btn-light me-3 px-3" @click.prevent="cancelPrompt">
                                    Cancel
                                </button>
                                <button class="btn btn-info px-3" @click.prevent="applyChange">
                                    Update
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Modal } from "bootstrap";

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  props: {
    content: {
      type: Object,
      required: true, 
    },
    generateReport: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const module_data = ref([])
    const data = ref([])
    const formData = ref({
      filters: [],
    });
    const settingField = ref({});

    const modifiedData = ref(null)

    const fetchReportData = async (id) => {
      const data = await store.dispatch("report/fetchReportData", id);
      formData.value = data || { filters: [] };

      if (formData.value.filters.length > 0) {
        module_data.value=props.content
        let updatedFilters = formData.value?.filters?.map((data) => {
          return data
            ?.filter((item) => item.prompt_user === 1)
            .map((item) => {
              const updatedItem = module_data.value
                ?.map((module) => {
                  return module.children?.map((child) => {
                    if (child.id === item.field_id) {
                      return { ...item, module_name: module.label, field_name: child.label };
                    }
                  }).filter(Boolean); 
                })
                .flat(); 

              return updatedItem && updatedItem.length > 0 ? updatedItem[0] : item;
            })
            .filter(Boolean); 
        }).filter(arr => arr.length > 0); 

        formData.value.filters = updatedFilters;

        const settingsData = await store.dispatch("report/fetchDropdownOptions");
        settingField.value = settingsData;

        if (formData.value.filters.length > 0) {
          const modal = new Modal(document.getElementById("prompt_modal"));
          modal.show();
        }
        else{
          const reportId = route.query.reportId;
          await props.generateReport(reportId, formData.value.filters);
        }
      }
    };


    const cancelPrompt = async () => {
      document.getElementById("close-prompt").click();
      const reportId = route.query.reportId;
      await props.generateReport(reportId);

    }

    const applyChange = async () => {
      document.getElementById("close-prompt").click();
      const reportId = route.query.reportId;
      await props.generateReport(reportId, formData.value.filters);
    }

    onMounted(async () => {
      const reportId = route.query.reportId;
      const moduleId = route.query.moduleId;
      if (reportId) {
        // module_data.value=props.content
        await fetchReportData(reportId);

      }
    });

    return {
      data,
      formData,
      cancelPrompt,
      applyChange,
      settingField,
      modifiedData,
      generateReport: props.generateReport,
      content:props.content
    
    };
  },
};
</script>

<style>
.modal-content{
    border-radius: 14px !important;
}
.custom_form_select {
  min-height: 37px;
  max-height: 37px;
  box-shadow: none;
  border: 1px solid #ddd;
}
</style>