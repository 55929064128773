<template>
  <div class="dashborad-view-section">
    <!-- Loop over organisations if available -->
    <div class="row m-3" v-if="organisations && organisations.length > 0">
      <div
        class="col-md-6 border py-2"
        v-for="item in organisations"
        :key="item.id"
      >
        <router-link
          :to="{ path: '/crm/organisation-details', query: { org_Id: item.id, territory_id: item.territory_id } }"
          class="text-teal"
        >
          <!-- <i class="ion-navicon-round text-gray me-2"></i> -->
          <span>{{ item.name }}</span
          ><span v-if="item.postcode"> - {{ item.postcode }}</span>
        </router-link>
      </div>
    </div>
    <!-- Loop over userReports if available -->
    <div class="row m-3" v-if="userReports && userReports.length > 0">
      <div
        class="col-md-6 border py-2"
        v-for="item in userReports"
        :key="item.id"
      >
        <router-link
          :to="{ path: '/crm/overview/report', query: { modN: item.module.name, repN: item.name, reportId: item.id, moduleId: item.module.id } }"
          class="text-teal"
        >
          <span><i class="ti ti-list text-dark"></i
            > {{ item.name }}</span>
        </router-link>
      </div>
    </div>
    <!-- Loop over overDueOpportunities if available -->
    <div
      class="row mx-3"
      v-if="overDueOpportunities && overDueOpportunities.length > 0"
      v-for="opportunity in overDueOpportunities"
      :key="opportunity.id"
    >
      <div class="col-md-4 border py-2">
        <router-link
          :to="{
            path: '/crm/meter-opportunities-edit',
            query: {
              add: 'dashboard',
              territory_id: opportunity.territory_id,
              org_Id: opportunity.organization.id,
              opp_Id: opportunity.id,
            },
          }"
          class="text-teal"
        >
          {{ formatDate(opportunity.create_date) }}
        </router-link>
      </div>
      <div class="col-md-4 border py-2">
        <p class="mb-0">
          <span>{{ opportunity.meter_opportunity_nr_auto }}</span>
          <span v-if="opportunity.mpr"
            >- {{ opportunity.mpr }}</span
          >
          <span v-if="opportunity.mpan_top"
            >- {{ opportunity.mpan_top }} {{ opportunity.mpan_bottom }}</span
          >
        </p>
      </div>
      <div class="col-md-4 border py-2">
        <router-link
          :to="{
            path: '/crm/organisation-details',
            query: { org_Id: opportunity.organization.id,territory_id: opportunity.territory_id,opp_Id: opportunity.id },
          }"
          class="text-teal"
        >
          <span>{{ opportunity.organization.name }}</span
            ><span v-if="opportunity.organization.postcode"> - {{ opportunity.organization.postcode }}</span>
        </router-link>
      </div>
    </div>
    <!-- Loop over searchResults if available -->
    <div
      class="row mx-3"
      v-if="searchResults && searchResults.length > 0"
      v-for="search in searchResults"
      :key="search.id"
    >
      <div class="col-md-12 border py-2" v-if="search.name">
        <router-link
          :to="{
            path: '/crm/organisation-details',
            query: {
              org_Id: search.id,
            },
          }"
          class="text-teal"
        ><i class="ti ti-building-community text-gray me-2"></i>
        <span>{{ search.name }}</span
            ><span v-if="search.postcode"> - {{ search.postcode }}</span>
        </router-link>
      </div>
      <div class="col-md-12 border py-2" v-if="search.direct_telephone">
      <div class="row">
       <div class="col-12">
            <router-link
            :to="{
                path: '/crm/organisation-details',
                query: {
                  org_Id: search.organization.id,
                    territory_id: search.territory_id 
                },
            }"
            class="text-teal"
            ><i class="ti ti-building-community text-gray me-2"></i>
            <span>{{ search.organization.name }}</span
                ><span v-if="search.organization.postcode"> - {{ search.organization.postcode }}</span>
            </router-link>
       
       </div>
      </div>
      <div class="row">
       <div class="col-12">
        <router-link
          :to="{
            path: '/crm/create-person',
            query: {
                personId: search.id,
                org_Id: search.organization.id,
                territory_id: search.territory_id
            },
          }"
          class="text-teal"
        ><i class="ion-person text-gray me-2"></i>
        <span>{{ search.first_name }}</span
            ><span v-if="search.last_name"> - {{ search.last_name }}</span>
        </router-link>
       </div>
      </div>
        <p class="mb-0"><span v-if="search.organization.telephone">T: {{ search.organization.telephone }}</span> <span v-if="search.direct_telephone">D: {{ search.direct_telephone }}</span></p>
      </div>
    </div>
    <!-- Loop over userActivities if available -->
    <div
      class="row mx-3"
      v-if="userActivities && userActivities.length > 0"
      v-for="res in userActivities"
      :key="res.id"
    >
      <div class="col-md-4 border py-2">
        <router-link
          :to="{
            path: '/crm/edit-activity',
            query: {
              territory_id: res.territory_id,
              org_Id: res.organization.id,
              actId: res.id,
            },
          }"
          class="text-teal"
        >
        <span>{{ res.date }}</span
            ><span v-if="res.time"> {{ res.time }}</span>
        </router-link>
      </div>
      <div class="col-md-4 border py-2">
        <p class="mb-0">
         
          <span
            >{{ res.action }}</span
          >
        </p>
      </div>
      <div class="col-md-4 border py-2">
        <router-link
          :to="{
            path: '/crm/organisation-details',
            query: { org_Id: res.organization.id },
          }"
          class="text-teal"
        >
           <!-- <i class="ion-navicon-round text-gray me-2"></i> -->
           <span>{{ res.organization.name }}</span
          ><span v-if="res.organization.postcode"> - {{ res.organization.postcode }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    organisations: {
      type: Array,
      default: () => [],
    },
    overDueOpportunities: {
      type: Array,
      default: () => [],
    },
    searchResults: {
      type: Array,
      default: () => [],
    },
    userActivities: {
      type: Array,
      default: () => [],
    },
    userReports: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    // Function to convert date format
    const formatDate = (date) => {
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    };

    return {
      formatDate
    }
  }
};
</script>
<style scoped lang="scss">
  .dashborad-view-section{
    max-height: 312px;
    overflow: auto;
  }
</style>
