export const allowOnlyNumbers = (event) => {
  const charCode = event.charCode ? event.charCode : event.keyCode;

  // Allow numbers (0-9), decimal point (.), and prevent multiple decimal points
  if (
    (charCode < 48 || charCode > 57) && // Numbers 0-9
    charCode !== 46 // Decimal point
  ) {
    event.preventDefault();
  }

  // Prevent multiple decimal points
  if (charCode === 46 && event.target.value.includes('.')) {
    event.preventDefault();
  }
};


export const changeFormate = (date) => {
  if (!date) return ""; 
  const contractRenewalDate = new Date(date);

  if (isNaN(contractRenewalDate.getTime())) {
    return ""; 
  }

  const year = contractRenewalDate.getFullYear();
  const month = String(contractRenewalDate.getMonth() + 1).padStart(2, "0");
  const day = String(contractRenewalDate.getDate()).padStart(2, "0");
  const hours = String(contractRenewalDate.getHours()).padStart(2, "0");
  const minutes = String(contractRenewalDate.getMinutes()).padStart(2, "0");
  const seconds = String(contractRenewalDate.getSeconds()).padStart(2, "0");

  // Format date to "YYYY-MM-DDTHH:MM:SS" in local timezone
  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  return formattedDate;
};
