import { createApp } from 'vue';
import { router } from '@/router';
import App from "@/App.vue";
import store from '@/store';
import { BootstrapVue3, BToastPlugin } from 'bootstrap-vue-3'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/reset.css';
import VueSelect from 'vue3-select2-component';
import VueApexCharts from 'vue3-apexcharts';
import DatePicker from 'vue3-datepicker';
import DateTimePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import FlagIcon from 'vue-flag-icon';
import VueFeather from 'vue-feather';
import Vue3Autocounter from 'vue3-autocounter';
import StarRating from "vue-star-rating";
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import ThemifyIcon from "vue-themify-icons";
import SimpleLineIcons from "vue-simple-line";
import VueEasyLightbox from "vue-easy-lightbox";
import CKEditor from '@ckeditor/ckeditor5-vue';

import { allowOnlyNumbers } from '@/util';


import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@fortawesome/fontawesome-free/css/fontawesome.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'sweetalert2/dist/sweetalert2.min.css';
import "boxicons/css/boxicons.min.css";
import 'pe7-icon/dist/dist/pe-icon-7-stroke.css';
import 'typicons.font/src/font/typicons.css';
import 'weathericons/css/weather-icons.css'
import 'ionicons-npm/css/ionicons.css';
import '@/assets/css/feather.css'
import '@/assets/css/tabler-icons.css'
import '@/assets/scss/main.scss';
import 'vue-multiselect/dist/vue-multiselect.css';

import 'jquery-ui/themes/base/theme.css'; // Import jQuery UI theme
import 'jquery-ui/themes/base/slider.css'; // Import slider styles


// *************** Components ***************
import Breadcrumb from "@/components/breadcrumb/page-header.vue"
import DashboardHeader from "@/components/breadcrumb/dashboard-header.vue"

// *************** Layout Component ***************
import Header from "@/views/layouts/layouts-header.vue"
import Sidebar from "@/views/layouts/layouts-sidebar.vue"
import VerticalSidebar from '@/views/layouts/vertical-sidebar.vue'
import FilesSidebar from "@/views/layouts/files-sidebar.vue";
import SideSettings from '@/views/layouts/side-settings.vue'
import IndexLoader from '@/views/layouts/index-loader.vue'

// *************** Page Component ***************
import ReportTeble from '@/views/pages/crm/reports/report-table.vue'
import ReportContent from '@/views/pages/crm/reports/report-content.vue'
import ReportOverviewTable from '@/views/pages/crm/reports/report-overview-table.vue'
import ReportChart from '@/views/pages/crm/reports/report-chart.vue'

// organisation module
import OrganisationsTable from '@/views/pages/crm/organisation/organisations-table.vue'
import OrganisationsPerson from '@/views/pages/crm/organisation/organisation-persons.vue'
import OrgActivities from '@/views/pages/crm/organisation/organisation-activities.vue'
import OrgContracts from '@/views/pages/crm/organisation/organisation-contracts.vue'
import OrgOpprtunities from '@/views/pages/crm/organisation/organisation-opportunities.vue'

// quoting
import GasQuoting from '@/views/pages/quoting/quoting-gas.vue';
import ElectricQuoting from '@/views/pages/quoting/quoting-electricity.vue';
import QuotingData from '@/views/pages/quoting/quoting-data.vue';
import QuotingElecData from '@/views/pages/quoting/quoting-elec-data.vue';
import RefineFilter from '@/views/pages/quoting/refine-filter.vue';
import CustomRange from '@/views/pages/quoting/CustomMinMaxSlider.vue';

import PipeLinesTable from '@/views/pages/settings/pipelines/pipelines-table.vue'
import PersonsTable from '@/views/pages/crm/person/persons-table.vue'
import UsersTable from '@/views/pages/settings/users/users-table.vue'
import CompanyTable from '@/views/pages/companies/company-table.vue'
import TerritoryTable from '@/views/pages/settings/territory/territory-table.vue'
import General_Sidebar from '@/views/pages/settings/general-settings/general-sidebar.vue'
import Website_Sidebar from '@/views/pages/settings/data-import/website-sidebar.vue'
import User_Sidebar from '@/views/pages/settings/users/users-list.vue'
import Pipeline_Sidebar from '@/views/pages/settings/pipelines/pipelines-list.vue'
import CompaniesList from '@/views/pages/companies/companies-list.vue'
import Settings_Tab from '@/views/pages/settings/settings-tabs.vue'
import OppLeadsTable from '@/views/pages/crm/meter-opportunities/meter-opportunities-table.vue'
import QuotedPriceTable from '@/views/pages/crm/meter-opportunities/quoted-prices-table.vue'
// contracts table
import ContractsTable from '@/views/pages/crm/contracts/contracts-table.vue'
import Manage_Filter from '@/views/pages/user/manage-filter.vue'
import Delete_Filter from '@/views/pages/user/delete-filter.vue'
import Lead_Report_Filter from '@/views/pages/report/lead-reports/lead-report-filter.vue'
import Deal_Report_Filter from '@/views/pages/report/deal-reports/deal-report-filter.vue'
import Contact_Report_Filter from '@/views/pages/report/contact-reports/contact-report-filter.vue'
import Company_Report_Filter from '@/views/pages/report/company-reports/company-report-filter.vue'
import Project_Report_Filter from '@/views/pages/report/project-reports/project-report-filter.vue'
import Task_Report_Filter from '@/views/pages/report/task-reports/task-report-filter.vue'
import LeadsBoard from '@/views/pages/crm/meter-opportunities/meter-opportunities-board.vue'
import sendLoa from './views/pages/crm/meter-opportunities/send-loa.vue';
import LeadsTable from '@/views/pages/crm/leads/leads-table.vue'
import Contracts_Filter from '@/views/pages/crm/contracts/contracts-filter.vue'
import ActivitiesTable from '@/views/pages/crm/activities/activities-table.vue'
import DealsDashboardTable from '@/views/pages/dashboard/deals-dashboard/deals-dashboard-table.vue'
// *************** modal Component ***************
import ReportsModal from '@/components/modal/report-modal.vue'
import Email_Modal from '@/components/modal/email-modal.vue'
import Lead_Report_Modal from '@/components/modal/lead-report-modal.vue'
import Company_Report_Modal from '@/components/modal/company-report-modal.vue'
import Activities_List_Modal from '@/components/modal/activities-list-modal.vue'
import Analytics_List_Modal from '@/components/modal/analytics-list-modal.vue'
import Leads_List_Modal from '@/components/modal/opportunities-list-modal.vue'
import ContactDetailsModal from '@/components/modal/report-details-modal.vue';
import CompaniesListModal from '@/components/modal/companies-list-modal.vue';
import DealsListModal from '@/components/modal/deals-list-modal.vue';
import DealsDetailsModal from '@/components/modal/deals-details-modal.vue';
import Contracts_Modal from '@/components/modal/contracts-modal.vue'

import LookUpModal from '@/components/modal/look-up-modal.vue';
import QuodedOpportunity from '@/components/modal/quoted-opportunity.vue';
import SmtpEmail from '@/components/modal/send-smtp-email.vue'

import swal from 'sweetalert2';


window.Swal = swal;

const app = createApp(App)
app.use(store);

// *************** Components ***************
app.component('page-header', Breadcrumb)
app.component('dashboard-header', DashboardHeader)

// *************** Layout Component ***************
app.component('layouts-header', Header)
app.component('layouts-sidebar', Sidebar)
app.component('vertical-sidebar', VerticalSidebar)
app.component("files-sidebar", FilesSidebar);
app.component('side-settings', SideSettings)
app.component('index-loader', IndexLoader)

// *************** Page Component ***************
app.component('report-table', ReportTeble)
app.component('report-content', ReportContent)
app.component('report-overview-table', ReportOverviewTable)
app.component('report-chart', ReportChart)

// orgnisation
app.component('organisations-table', OrganisationsTable)
app.component('organisation-persons', OrganisationsPerson)
app.component('organisation-activities', OrgActivities)
app.component('organisation-opportunities', OrgOpprtunities)
app.component('organisation-contract', OrgContracts)

// quoting
app.component('quoting-gas', GasQuoting)
app.component('quoting-electricity', ElectricQuoting)
app.component('quoting-data', QuotingData)
app.component('quoting-elec-data', QuotingElecData)
app.component('refine-filter', RefineFilter)
app.component('range-slider', CustomRange)


app.component('persons-table', PersonsTable)
app.component('users-table', UsersTable)
app.component('pipelines-table', PipeLinesTable)
app.component('company-table', CompanyTable)
app.component('territory-table', TerritoryTable)
app.component('general-sidebar',General_Sidebar)
app.component('website-sidebar',Website_Sidebar)
app.component('users-list', User_Sidebar)
app.component('pipelines-list', Pipeline_Sidebar)

app.component('companies-list', CompaniesList)
app.component('settings-tabs',Settings_Tab)
app.component('meter-opportunities-table', OppLeadsTable)
app.component('quoted-prices-table', QuotedPriceTable)

// contracts table
app.component('contracts-table', ContractsTable)
app.component('manage-filter',Manage_Filter)
app.component('delete-filter',Delete_Filter)
app.component('lead-report-filter',Lead_Report_Filter)
app.component('deal-report-filter',Deal_Report_Filter)
app.component('contact-report-filter',Contact_Report_Filter)
app.component('company-report-filter',Company_Report_Filter)
app.component('project-report-filter',Project_Report_Filter)
app.component('task-report-filter',Task_Report_Filter)
app.component('meter-opportunities-board', LeadsBoard )
app.component('send-loa', sendLoa),
app.component('leads-table', LeadsTable)
app.component('contracts-filter',Contracts_Filter)
app.component('activities-table', ActivitiesTable)
app.component('deals-dashboard-table', DealsDashboardTable)

// *************** modal Component ***************
app.component('reports-modal', ReportsModal)
app.component('email-modal',Email_Modal)
app.component('lead-report-modal',Lead_Report_Modal)
app.component('company-report-modal',Company_Report_Modal)
app.component('activities-list-modal',Activities_List_Modal)
app.component('analytics-list-modal',Analytics_List_Modal)
app.component('opportunities-list-modal',Leads_List_Modal)
app.component('contact-details-modal', ContactDetailsModal)
app.component('companies-list-modal', CompaniesListModal)
app.component('deals-list-modal', DealsListModal )
app.component('deals-details-modal', DealsDetailsModal)
app.component('contracts-modal',Contracts_Modal)
app.component('look-up-modal', LookUpModal)
app.component('quoted-opportunity', QuodedOpportunity)
app.component('send-smtp-email', SmtpEmail)


app.component('vue3-autocounter', Vue3Autocounter)
app.component('date-picker', DatePicker);
app.component('date-time-picker', DateTimePicker)
app.component("star-rating", StarRating);
app.component('vue-select', VueSelect)
app.component(VueFeather.name, VueFeather);

// Make allowOnlyNumbers globally available via $utils
app.config.globalProperties.$utils = {
  allowOnlyNumbers,
};

app.use(VueApexCharts)
  .use(VueEasyLightbox)
    .use(Antd) 
    .use(BootstrapVue3)
    .use(BToastPlugin)
    .use(ThemifyIcon)
    .use(SimpleLineIcons)
.use(VueToast, {
    position: "top-right",
  });
app.use(FlagIcon)
app.use(CKEditor);
app.use(router).mount('#app');