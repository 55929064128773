<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" />
          <!-- /Page Header -->

          <!-- Settings Menu -->
          <settings-tabs></settings-tabs>
          <!-- /Settings Menu -->

          <div class="row">
            <general-sidebar></general-sidebar>

            <div class="col-xl-9 col-lg-12">
              <!-- Settings Info -->
              <div class="card">
                <div class="card-body pb-0">
                  <div class="settings-header">
                    <h4>Security Settings</h4>
                  </div>
                  <div class="settings-form">
                    <div class="row">
                      <div class="col-lg-4 col-md-6 d-flex">
                        <div class="security-grid flex-fill">
                          <div class="security-header">
                            <div class="security-heading">
                              <h5>Password</h5>
                            </div>
                            <div class="security-content">
                              <!-- <p>Last Changed 03 Jan 2023, 09:00 AM</p> -->
                              <p></p>
                            </div>
                          </div>
                          <div class="security-btn security-btn-info">
                            <a
                              href="javascript:void(0)"
                              class="btn btn-light"
                              data-bs-toggle="modal"
                              data-bs-target="#change_password"
                              >Change Password</a
                            >
                            <!-- <a href="javascript:void(0)" class="btn btn-light">
                              Change Password
                            </a> -->
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-lg-4 col-md-6 d-flex">
                        <div class="security-grid flex-fill">
                          <div class="security-header">
                            <div class="security-heading">
                              <h5>Two Factor</h5>
                              <div class="status-toggle">
                                <input
                                  id="two_factor"
                                  class="check"
                                  type="checkbox"
                                  checked=""
                                />
                                <label for="two_factor" class="checktoggle"
                                  >checkbox</label
                                >
                              </div>
                            </div>
                            <div class="security-content">
                              <p>Receive codes via SMS or email every time you login</p>
                            </div>
                          </div>
                          <div class="security-btn security-btn-info">
                            <a href="javascript:void(0)" class="btn btn-primary"
                              >Delete Account</a
                            >
                          </div>
                        </div>
                      </div> -->
                      <!-- <div class="col-lg-4 col-md-6 d-flex">
                        <div class="security-grid flex-fill">
                          <div class="security-header">
                            <div class="security-heading">
                              <h5>Google Authenticator</h5>
                              <div class="status-toggle">
                                <input
                                  id="google_authenticator"
                                  class="check"
                                  type="checkbox"
                                  checked=""
                                />
                                <label for="google_authenticator" class="checktoggle"
                                  >checkbox</label
                                >
                              </div>
                            </div>
                            <div class="security-content">
                              <p>
                                Google Authenticator adds an extra layer of security to
                                your online accounts by adding a second step of
                                verification when you sign in.
                              </p>
                            </div>
                          </div>
                          <div class="security-btn">
                            <span class="badge badge-light-success">Connected</span>
                          </div>
                        </div>
                      </div> -->
                      <!-- <div class="col-lg-4 col-md-6 d-flex">
                        <div class="security-grid flex-fill">
                          <div class="security-header">
                            <div class="security-heading">
                              <h5>
                                Phone Number Verification
                                <i
                                  class="ti ti-square-rounded-check-filled text-success"
                                ></i>
                              </h5>
                            </div>
                            <div class="security-content">
                              <p class="text-success-light">
                                Verified Mobile Number : <span>+99264710583</span>
                              </p>
                            </div>
                          </div>
                          <div class="security-btn security-btn-info">
                            <a href="javascript:void(0)" class="btn btn-light">Change</a>
                            <a href="javascript:void(0)" class="btn btn-remove">Remove</a>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6 d-flex">
                        <div class="security-grid flex-fill">
                          <div class="security-header">
                            <div class="security-heading">
                              <h5>
                                Email Verification
                                <i
                                  class="ti ti-square-rounded-check-filled text-success"
                                ></i>
                              </h5>
                            </div>
                            <div class="security-content">
                              <p class="text-success-light">
                                Verified Email : <span>info@example.com</span>
                              </p>
                            </div>
                          </div>
                          <div class="security-btn security-btn-info">
                            <a href="javascript:void(0)" class="btn btn-light">Change</a>
                            <a href="javascript:void(0)" class="btn btn-remove">Remove</a>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6 d-flex">
                        <div class="security-grid flex-fill">
                          <div class="security-header">
                            <div class="security-heading">
                              <h5>Device Management</h5>
                            </div>
                            <div class="security-content">
                              <p>Last Changed 17 Feb 2023, 11.00 AM</p>
                            </div>
                          </div>
                          <div class="security-btn security-btn-info">
                            <a href="javascript:void(0)" class="btn btn-light">Manage</a>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6 d-flex">
                        <div class="security-grid flex-fill">
                          <div class="security-header">
                            <div class="security-heading">
                              <h5>Account Activity</h5>
                            </div>
                            <div class="security-content">
                              <p>Last Changed 22 Feb 2023, 01:20 PM</p>
                            </div>
                          </div>
                          <div class="security-btn">
                            <a href="javascript:void(0)" class="btn btn-light">View</a>
                          </div>
                        </div>
                      </div> -->
                      <!-- <div class="col-lg-4 col-md-6 d-flex">
                        <div class="security-grid flex-fill">
                          <div class="security-header">
                            <div class="security-heading">
                              <h5>Deactive Account</h5>
                            </div>
                            <div class="security-content">
                              <p>Last Changed 04 Mar 2023, 08:40 AM</p>
                            </div>
                          </div>
                          <div class="security-btn">
                            <a href="javascript:void(0)" class="btn btn-light"
                              >Deactive</a
                            >
                          </div>
                        </div>
                      </div> -->
                      <!-- <div class="col-lg-4 col-md-6 d-flex">
                        <div class="security-grid flex-fill">
                          <div class="security-header">
                            <div class="security-heading">
                              <h5>Delete Account</h5>
                            </div>
                            <div class="security-content">
                              <p>Last Changed 13 Mar 2023, 02:40 PM</p>
                            </div>
                          </div>
                          <div class="security-btn">
                            <a
                              href="javascript:void(0)"
                              class="btn btn-primary"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_account"
                              >Delete Account</a
                            >
                          </div>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Settings Info -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal custom-modal fade" id="delete_account" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon">
              <i class="ti ti-trash-x"></i>
            </div>
            <h3>Delete Account</h3>
            <p class="del-info">Are you sure want to delete?</p>
            <div class="col-lg-12 text-center modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <router-link to="/settings/security" class="btn btn-danger"
                >Yes, Delete it</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal custom-modal fade" id="change_password" role="dialog" v-if="isModalVisible">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0">
          <h4>Change Password</h4>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <Form
            @submit="onSubmit"
            :validation-schema="schema"
            v-slot="{ errors }"
          >
            <div class="chnage-password-info">
              <div class="form-wrap">
                <label class="col-form-label">Current Password</label>
                <div class="pass-group">
                  <Field
                    name="currentPassword"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="currentPassword"
                    placeholder="Enter Your Current Password"
                    class="form-control pass-input mt-2"
                    :class="{ 'is-invalid': errors.currentPassword }"
                  />
                  <span @click="() => toggleShow('current')" class="toggle-password">
                    <i
                      :class="{
                        'ti ti-eye': showPassword,
                        'ti ti-eye-off': !showPassword,
                      }"
                    ></i>
                  </span>
                  <div class="invalid-feedback">{{ errors.currentPassword }}</div>
                  <div class="emailshow text-danger" id="current-password">{{ passwordError }}</div>
                </div>
              </div>
              <div class="form-wrap">
                <label class="col-form-label">New Password</label>
                <div class="pass-group">
                  <Field
                    name="newPassword"
                    :type="showNewPassword ? 'text' : 'password'"
                    v-model="newPassword"
                    placeholder="Enter Your New Password"
                    class="form-control pass-input mt-2"
                    :class="{ 'is-invalid': errors.newPassword }"
                  />
                  <span @click="() => toggleShow('new')" class="toggle-password">
                    <i
                      :class="{
                        'ti ti-eye': showNewPassword,
                        'ti ti-eye-off': !showNewPassword,
                      }"
                    ></i>
                  </span>
                  <div class="invalid-feedback">{{ errors.newPassword }}</div>
                  <div class="emailshow text-danger" id="new-password">{{ newPasswordError }}</div>
                </div>
              </div>
              <div class="form-wrap">
                <label class="col-form-label">Confirm New Password</label>
                <div class="pass-group">
                  <Field
                    name="confirmPassword"
                    :type="showConfirmPassword ? 'text' : 'password'"
                    v-model="confirmPassword"
                    placeholder="Confirm Your New Password"
                    class="form-control pass-input mt-2"
                    :class="{ 'is-invalid': errors.confirmPassword }"
                  />
                  <span @click="() => toggleShow('confirm')" class="toggle-password">
                    <i
                      :class="{
                        'ti ti-eye': showConfirmPassword,
                        'ti ti-eye-off': !showConfirmPassword,
                      }"
                    ></i>
                  </span>
                  <div class="invalid-feedback">{{ errors.confirmPassword }}</div>
                  <div class="emailshow text-danger" id="confirm-password">{{ confirmPasswordError }}</div>
                </div>
              </div>
              <div class="form-wrap text-center mb-0">
                <button type="submit" class="btn btn-primary">Change Password</button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue';
import * as Yup from 'yup';
import apiClient from '@/axios';
import { Form, Field } from "vee-validate";
import { useRouter } from 'vue-router';
import { message } from 'ant-design-vue';
export default {
  components: {
    Form,
    Field,
  },
  data() {
    return {
      title: "Settings",
    };
  },
  setup() {
    const router = useRouter();
    const showPassword = ref(false);
    const showNewPassword = ref(false);
    const showConfirmPassword = ref(false);
    const currentPassword = ref('');
    const newPassword = ref('');
    const confirmPassword = ref('');
    const passwordError = ref('');
    const newPasswordError = ref('');
    const confirmPasswordError = ref('');
    const isModalVisible = ref(true);

    const schema = Yup.object().shape({
      currentPassword: Yup.string()
        .required("Current password is required"),
      newPassword: Yup.string()
        .min(6, "New password must be at least 6 characters")
        .required("New password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], "Passwords must match")
        .required("Confirm password is required"),
    });

    const onSubmit = async (values) => {
      passwordError.value = '';
      newPasswordError.value = '';
      confirmPasswordError.value = '';

      let changePasswordData = {
        current_password: values.currentPassword,
        password: values.newPassword,
        password_confirmation: values.confirmPassword,
      };
      
      try {
        const response = await apiClient.post('/change-password', changePasswordData, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }
        });
        
        if (response.data.status) {
          // Handle success (e.g., show a success message, close modal, etc.)
          message.success('Password changed successfully!');
          isModalVisible.value = false;
          window.location.reload();
          removeBackdrop();
        } else {
          // Handle specific errors
          if (response.data.error === 'Incorrect current password') {
            passwordError.value = "Incorrect current password";
          } else {
            passwordError.value = response.data.error || "An error occurred. Please try again.";
          }
        }
      } catch (error) {
        console.error('Error during password change:', error);
        passwordError.value = "An error occurred. Please try again.";
      }
    };

    const toggleShow = (field) => {
      if (field === 'current') {
        showPassword.value = !showPassword.value;
      } else if (field === 'new') {
        showNewPassword.value = !showNewPassword.value;
      } else if (field === 'confirm') {
        showConfirmPassword.value = !showConfirmPassword.value;
      }
    };
    const closeModal = () => {
      isModalVisible.value = false;
      removeBackdrop();
    };
    const removeBackdrop = () => {
      const backdrop = document.querySelector('.modal-backdrop');
      if (backdrop) {
        backdrop.parentNode.removeChild(backdrop);
      }
      document.body.classList.remove('modal-open');
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    };
    return {
      schema,
      onSubmit,
      currentPassword,
      newPassword,
      confirmPassword,
      showPassword,
      showNewPassword,
      showConfirmPassword,
      passwordError,
      newPasswordError,
      confirmPasswordError,
      toggleShow,
      isModalVisible,
      closeModal
    };
  }

};
</script>
