<template>
  <div id="app">
    <div class="main-wrapper">
      <Loader :isVisible="isLoading" />
      <AlertPopup :callsData="calls" :importData="importData"/>
      <router-view />
    </div>
  </div>
</template>
<script>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import Loader from '../src/views/layouts/index-loader.vue';
import AlertPopup from '../src/views/layouts/AlertPopup.vue';
import { connectSocket, disconnectSocket, joinRoom, onCallAlert, onImportCompleted } from './socket';
export default {
  components: { Loader, AlertPopup },
  data() {
    return {};
  },
  setup() {
    const store = useStore();
    const isLoading = computed(() => store.state.isLoading);
    const calls = ref([]);
    const importData = ref({});
    const token = computed(() => store.getters['auth/isAuthenticated']);

    watch(token, (newToken, oldToken) => {
      if (newToken) {
        connectSocket();
        joinRoom();

        onCallAlert((callData) => {
          // importData.value = {};
          calls.value = callData;
        });

        // onImportCompleted((importDataValue) => {
        //   calls.value = [];
        //   importData.value = importDataValue;
        // });
      }
    }, { immediate: true });

    onUnmounted(() => {
      disconnectSocket();
    });

    return { isLoading, calls, importData };
  }
}
</script>
