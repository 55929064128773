import { io } from 'socket.io-client';

let socket;
export function connectSocket() {
  socket = io(process.env.VUE_APP_WEBSOCKET, {
    query: {
      token: localStorage.getItem('authToken'),
    },
    transports: ['websocket'],
    autoConnect: false,
  });
  if (!socket.connected) {
    socket.connect();
  }

  socket.on('token_error', (data) => {
    console.error('Token error:', data.message);
  });
}

export function onCallAlert(callback) {
  socket.on('call_alert', callback);
}

export function onImportCompleted(callback) {  
  socket.on('importCompleted', callback);
}

export function joinRoom() {
  socket.emit('join');
}

export function disconnectSocket() {
  if (socket.connected) {
    socket.disconnect();
  }
}

export default socket;
