<template>
<a-table class="stripped table-hover" :columns="columns" :data-source="data" :pagination="pagination" :row-selection="rowSelection" @change="handleTableChange">
  <!-- Template for table body cells -->
  <template #bodyCell="{ column, record }">
    <template v-if="column.key === 'Name'">
      <h2 class="table-avatar d-flex align-items-center">
        <router-link :to="{ path: '/crm/organisation-details', query: { ter_Id: record.ter_Id, org_Id: record.key } }" class="profile-split d-flex flex-column">
          {{ record.organisation_name }}
        </router-link>
      </h2>
    </template>
    <template v-else-if="column.key === 'Status'">
      <div>
        <span :class="record.statusclass">{{ record.status }}</span>
      </div>
    </template>
    <template v-else-if="column.key === 'Phone'">
      <div>
        {{ record.phone }}
      </div>
    </template>
    <template v-else-if="column.key === 'action'">
          <div class="icon-field">
          
          <router-link :to="{ path: '/crm/create-organisation', query: { org_Id: record.key }}" title="Edit" class="btn table_action_btn"><i class="ti ti-edit"></i>  Edit</router-link>

          <router-link :to="{ path: '/crm/create-person', query: { org_Id: record.key } }" class="btn table_action_btn ms-2"><i class="ion-person"></i>  Add Person</router-link>
          </div>
         
    </template>
  </template>
</a-table>

<!-- Delete Confirmation Modal -->
<div class="modal-backdrop fade show" v-if="showDeleteModal"></div>
<div v-if="showDeleteModal" class="modal custom-modal fade show" style="display: block;" tabindex="-1" role="dialog" aria-modal="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header border-0 m-0 justify-content-end">
        <button class="btn-close" @click="showDeleteModal = false" aria-label="Close">
          <i class="ti ti-x"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="success-message text-center">
          <div class="success-popup-icon">
            <i class="ti ti-trash-x"></i>
          </div>
          <h3>Delete Account</h3>
          <p class="del-info">Are you sure you want to delete?</p>
          <div class="col-lg-12 text-center modal-btn">
            <button class="btn btn-light" @click="showDeleteModal = false">Cancel</button>
            <button class="btn btn-danger" @click="deleteOrganisation(selectedUserId)">Yes, Delete it</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  ref,
  onMounted,
  watchEffect
} from 'vue';
import {
  useStore
} from "vuex";
import {
  useRouter
} from 'vue-router';

const columns = [{
    title: "Organisation Name",
    dataIndex: "organisation_name",
    key: "Name",
    sorter: {
      compare: (a, b) => {
        a = a.organisation_name.toLowerCase();
        b = b.organisation_name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Post Code",
    dataIndex: "postcode",
    key: "Postcode",
    sorter: {
      compare: (a, b) => {
        a = a.postcode?.toLowerCase();
        b = b.postcode?.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "Phone",
    sorter: {
      compare: (a, b) => {
        a = a.phone;
        b = b.phone;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Fax",
    dataIndex: "fax",
    sorter: {
      compare: (a, b) => {
        a = a.fax;
        b = b.fax;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Vat Group",
    dataIndex: "vatgroup",
    sorter: {
      compare: (a, b) => {
        a = a.vatgroup;
        b = b.vatgroup;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "WWW",
    dataIndex: "www",
    sorter: {
      compare: (a, b) => {
        a = a.www;
        b = b.www;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Territory",
    dataIndex: "territory",
    sorter: {
      compare: (a, b) => {
        a = a.territory;
        b = b.territory;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.status;
        b = b.status;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "No of Employees",
    dataIndex: "no_of_employees",
    sorter: {
      compare: (a, b) => {
        a = a.no_of_employees;
        b = b.no_of_employees;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    dataIndex: "Action",
    key: "action",
    sorter: false,
  },
];

export default {
  props: {
    searchQuery: String,
    filters: Object,
  },
  data() {
    return {
      columns,
      data: [],
      selectedUserId: null,
      showDeleteModal: false,
      rowSelection: {
        onChange: () => {},
        onSelect: () => {},
        onSelectAll: () => {},
      },
    };
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();
    const data = ref([]);
    const pagination = ref({
      current: 1,
      pageSize: 10,
      total: 0,
    });

    const fetchOrganisations = async () => {
      const params = {
        search: props.searchQuery,
        status: props.filters.status,
        vat_group: props.filters.vatGroup,
        www: props.filters.www,
        fax: props.filters.fax,
        telephone: props.filters.telephone,
        page: pagination.value.current,
        pageSize: pagination.value.pageSize,
      };
      const response = await store.dispatch('organisation/fetchOrgLists', params);
      if (response.data) {
        data.value = response.data.map((org) => ({
          key: org.id,
          organisation_name: org.name,
          ter_Id: org.territory_id,
          phone: formatPhoneNumber(org.telephone),
          postcode: org.postcode,
          fax: org.fax,
          vatgroup: org.vat_group,
          www: org.www,
          responsible: org.responsible,
          logo: org.logo,
          comment: org.comment,
          territory: org.territory_name,
          status: org.status,
          no_of_employees: org.no_of_employees,
        }));
        pagination.value.total = response.total;
      }
    };
    const formatPhoneNumber = (number) => {
      if (!number) {
        return ''; // Return an empty string if the input is empty or undefined
      }

      const cleaned = number.replace(/\D/g, '');

      // Check if the cleaned number is valid (e.g., 10 digits)
      if (cleaned.length === 10) {
        return `(${cleaned.substring(0, 3)}) ${cleaned.substring(3, 6)}-${cleaned.substring(6)}`;
      } else {
        // Optionally, return a placeholder or the original input if not valid
        return number; // Or return a default placeholder like 'Invalid number'
      }
    };

    const handleTableChange = (newPagination) => {
      pagination.value.current = newPagination.current;
      pagination.value.pageSize = newPagination.pageSize;
      fetchOrganisations();
    };

    watchEffect(() => {
      fetchOrganisations();
    });

    return {
      data,
      pagination,
      fetchOrganisations,
      handleTableChange,
    };
  },
};
</script>
