<template>
<div class="content">
  <div class="row">
    <div class="col-md-12 p-3">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <div class="cards p-4">
            <div class="card-body">
              <div class="settings-header">
                <h4>{{ title }} <a href="javascript:void(0);" @click="donloadErrorFile" class="text-teal ms-2 text-decoration-underline donload-btn fw-bold">
                    Download file
                  </a></h4>
              </div>
              <div class="row">
                <div class="col-12" v-for="(error, index) in errorLogs" :key="index">
                  <P>{{ error }}</P>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  ref,
  onMounted,
  computed
} from "vue";
import {
  useStore
} from "vuex";
import {
  useRoute
} from "vue-router";
import router from "@/router";

export default {
  data() {
    return {
      title: "Data Import | View details",
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const file_id = ref(route.query.fileId || null);
    const errorLogs = ref([])

    const donloadErrorFile = async () => {
      try {
        const data = await store.dispatch("dataimport/downloadErrorsFile", file_id.value);
        const downloadUrl = data.url;

        // Create a temporary link element
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = ''; // Optional: specify a filename here if needed
        document.body.appendChild(link);
        // Programmatically click the link to trigger the download
        link.click();
        // Clean up by removing the link element
        document.body.removeChild(link);

      } catch (error) {
        console.error("Error fetchWarningErrors data:", error);
      }
    };

    const fetchErrorsData = async (id) => {
      try {
        const data = await store.dispatch("dataimport/fetchWarningErrors", id);

        errorLogs.value = data;

      } catch (error) {
        console.error("Error fetchWarningErrors data:", error);
      }
    };

    onMounted(async () => {
      if (file_id.value) {
        fetchErrorsData(file_id.value);
      }
    });

    return {
      fetchErrorsData,
      errorLogs,
      file_id,
      donloadErrorFile
    };
  },
};
</script>

<style scoped>
.donload-btn {
  font-size: 16px;
}
</style>
