<template>
  <!-- Header -->
  <div class="header">
    <!-- Logo -->
    <div class="header-left active">
      <router-link to="/dashboard/deals-dashboard" class="logo logo-normal">
        <img :src="company.logo" alt="Logo" v-if="company.logo" class="img-fluid" />
        <img src="@/assets/img/anviam.png" alt="Logo" class="img-fluid" v-else />
      </router-link>
      <router-link to="/dashboard/deals-dashboard" class="logo-small">
        <img src="@/assets/img/images.jpeg" alt="Logo" />
      </router-link>
      <a id="toggle_btn" @click="toggleSidebar" href="javascript:void(0);">
        <i class="ti ti-arrow-bar-to-left"></i>
      </a>
    </div>
    <!-- /Logo -->

    <a id="mobile_btn" class="mobile_btn" href="#sidebar" @click="toggleSidebar1">
      <span class="bar-icon">
        <span></span>
        <span></span>
        <span></span>
      </span>
    </a>

    <div class="header-user">
      <ul class="nav user-menu">
        <!-- Nav List -->
        <li class="nav-item nav-list ms-auto">
          <ul class="nav">
            <li class="dark-mode-list">
              <a
                href="javascript:void(0);"
                id="dark-mode-toggle"
                class="dark-mode-toggle"
                @click="toggleDarkMode"
              >
                <i class="ti ti-sun light-mode" :class="{ active: isLightMode }"></i>
                <i class="ti ti-moon dark-mode" :class="{ active: !isLightMode }"></i>
              </a>
            </li>
            <!-- <li class="nav-item dropdown">
              <a
                href="javascript:void(0);"
                class="btn btn-header-list"
                data-bs-toggle="dropdown"
              >
                <i class="ti ti-layout-grid-add"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-end menus-info">
                <div class="row">
                  <div class="col-6">
                    <ul class="menu-list">
                      <li>
                        <router-link to="/crm/organisation-list">
                          <div class="menu-details">
                            <span class="menu-list-icon bg-tertiary">
                              <i class="ti ti-building-community"></i>
                            </span>
                            <div class="menu-details-content">
                              <p>Organisations</p>
                              <span>Add New Organisations</span>
                            </div>
                          </div>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                  <div class="col-6">
                    <ul class="menu-list">
                      <li>
                        <router-link to="/crm/meter-opportunities-list">
                          <div class="menu-details">
                            <span class="menu-list-icon bg-tertiary">
                              <i class="ti ti-building-community"></i>
                            </span>
                            <div class="menu-details-content">
                              <p>Meter Opportunities</p>
                              <span>Add New Opportunities</span>
                            </div>
                          </div>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li> -->
          </ul>
        </li>
        <!-- /Nav List -->

        <!-- Profile Dropdown -->
        <li class="nav-item dropdown has-arrow main-drop">
          <a
            href="javascript:void(0);"
            class="nav-link userset"
            data-bs-toggle="dropdown"
          >
            <span class="user-info">
              <span class="user-letter">
                <img src="@/assets/img/profiles/avatar-20.jpg" alt="Profile" />
              </span>
              <span class="badge badge-success rounded-pill"></span>
            </span>
          </a>
          <div class="dropdown-menu menu-drop-user">
            <div class="profilename">
              <router-link class="dropdown-item" to="/dashboard/">
                <i class="ti ti-layout-2"></i> Dashboard
              </router-link>
              <router-link v-if="user.permissions.includes('General Settings' || 'Data Import' || 'Users' || 'Territories')" class="dropdown-item" to="/settings/profile">
                <i class="ti ti-user-pin"></i> Configurations
              </router-link>
              <router-link class="dropdown-item" to="/" @click.prevent="handleLogout">
                <i class="ti ti-lock"></i> Logout
              </router-link>
            </div>
          </div>
        </li>
        <!-- /Profile Dropdown -->
      </ul>
    </div>

    <!-- Mobile Menu -->
    <div class="dropdown mobile-user-menu">
      <a
        href="javascript:void(0);"
        class="nav-link dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        ><i class="fa fa-ellipsis-v"></i
      ></a>
      <div class="dropdown-menu">
        <router-link class="dropdown-item" to="/dashboard/">
          <i class="ti ti-layout-2"></i> Dashboard
        </router-link>
        <router-link class="dropdown-item" to="/settings/profile">
          <i class="ti ti-user-pin"></i> My Profile
        </router-link>
        <router-link class="dropdown-item" to="/">
          <i class="ti ti-lock"></i> Logout
        </router-link>
      </div>
    </div>
    <!-- /Mobile Menu -->
  </div>
  <!-- /Header -->

  <!-- <side-settings></side-settings> -->
</template>

<script>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import apiClient from '@/axios';
import { mapGetters, mapActions } from 'vuex';
export default {
  data() {
    return {
      isLightMode: true,
    };
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    // Logout function
    const handleLogout = async () => {
      try {
        await store.dispatch('auth/logout');
        // router.push('/');
      } catch (error) {
        console.error('Logout failed:', error);
      }
    };

    return {
      handleLogout,
    };
  },
  computed: {
    ...mapGetters('auth', ['company', 'user']), // 'auth' is the namespace of your Vuex module

  },
  methods: {
    ...mapActions('auth', ['loadCompanyFromLocalStorage', 'loadUserFromLocalStorage']),
    toggleSidebar1() {
      const body = document.body;
      body.classList.toggle("slide-nav");
    },
    toggleSidebar() {
      const body = document.body;
      body.classList.toggle("mini-sidebar");
    },
    initializeDarkMode() {
      if (localStorage.getItem("darkMode") === "enabled") {
        this.enableDarkMode();
      }
    },
    enableDarkMode() {
      document.documentElement.setAttribute("data-theme", "dark");
      this.isLightMode = false;
      localStorage.setItem("darkMode", "enabled");
    },
    disableDarkMode() {
      document.documentElement.setAttribute("data-theme", "light");
      this.isLightMode = true;
      localStorage.removeItem("darkMode");
    },
    toggleDarkMode() {
      if (this.isLightMode) {
        this.enableDarkMode();
      } else {
        this.disableDarkMode();
      }
    },
    initMouseoverListener() {
            document.addEventListener('mouseover', this.handleMouseover);
        },
        handleMouseover(e) {
            e.stopPropagation();

            const body = document.body;
            const toggleBtn = document.getElementById('toggle_btn');

            if (body.classList.contains('mini-sidebar') && this.isElementVisible(toggleBtn)) {
                const target = e.target.closest('.sidebar, .header-left');

                if (target) {
                    body.classList.add('expand-menu');
                    this.slideDownSubmenu();
                } else {
                    body.classList.remove('expand-menu');
                    this.slideUpSubmenu();
                }

                e.preventDefault();
            }
        },
        isElementVisible(element) {
            return element.offsetWidth > 0 || element.offsetHeight > 0;
        },
        slideDownSubmenu() {
            const subdropPlusUl = document.getElementsByClassName('subdrop');
            for (let i = 0; i < subdropPlusUl.length; i++) {
                const submenu = subdropPlusUl[i].nextElementSibling;
                if (submenu && submenu.tagName.toLowerCase() === 'ul') {
                    submenu.style.display = 'block';
                }
            }
        },
        slideUpSubmenu() {
            const subdropPlusUl = document.getElementsByClassName('subdrop');
            for (let i = 0; i < subdropPlusUl.length; i++) {
                const submenu = subdropPlusUl[i].nextElementSibling;
                if (submenu && submenu.tagName.toLowerCase() === 'ul') {
                    submenu.style.display = 'none';
                }
            }
        },
  },
  mounted() {
    this.loadCompanyFromLocalStorage();
    this.initializeDarkMode();
    this.initMouseoverListener();
  },
  beforeUnmount() {
        document.removeEventListener('mouseover', this.handleMouseover);
    },
};
</script>
<style scoped lang="scss">
 .logo-normal{
  img{
    max-height: 70px;
    object-fit: contain;
  }
 }
</style>