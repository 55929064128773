<template>
<layouts-header></layouts-header>
<layouts-sidebar></layouts-sidebar>
<div class="page-wrapper">
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <!-- Page Header -->
        <page-header :title="title" />
        <!-- /Page Header -->

        <!-- Settings Menu -->
        <settings-tabs></settings-tabs>
        <!-- /Settings Menu -->

        <div class="row">
          <div class="col-xl-12 col-lg-12">
            <!-- Custom Fields -->
            <div class="card">
              <div class="card-body">
                <div class="settings-header">
                  <website-sidebar></website-sidebar>
                </div>
                <!-- Search -->
                <div class="search-section" v-if="nonCompletedData.length > 0">
                  <h5 class="bg-teal p-2">Pending imports</h5>
                  <!-- Table 1 (Non-completed data with action column) -->
                  <a-table class="table" :columns="columnsNonCompleted" :data-source="nonCompletedData" :pagination="false">
                    <template #bodyCell="{ column, record }">
                      <template v-if="column.key === 'delete'">
                        <a href="#" @click="deleteFile(record.key)"><i class="ion-close-round text-danger" data-bs-toggle="tooltip" title="ion-close-round"></i></a>
                      </template>
                      <template v-if="column.key === 'Errors'">
                        <div v-if="record.Warn > 0">
                          <h6>{{ record.Warn }} Warnings</h6>
                              <a :href="router.resolve({ path: '/data-import/error-logs', query: { fileId: record.key } }).href" target="_blank" class="text-teal fw-bold">
                                View details
                              </a>
                        </div>
                      </template>
                      <!-- Custom content for the "Action" column -->
                      <template v-if="column.key === 'Action'">
                        <router-link :to="{
                              path: '/data-import/validate',
                              query: { fileId: record.key },
                            }" v-if="record.Status === 'uploaded'" class="text-teal fw-bold">Validate</router-link>
                        <router-link :to="{
                              path: '/data-import/map-data',
                              query: { fileId: record.key },
                            }" v-if="record.Status === 'validated'" class="text-teal fw-bold">Map Data</router-link>
                            <router-link :to="{
                              path: '/data-import/assign-data',
                              query: { fileId: record.key },
                            }" v-if="record.Status === 'mapped'" class="text-teal fw-bold">Assign Data</router-link>
                      </template>
                    </template>
                  </a-table>
                </div>
                <div class="no-pending-file" v-else >
                  <p class="text-center mb-2 py-3"><i class="ion-information-circled"></i> No pending imports</p>
                  <router-link class="btn btn-teal" to="/data-import/upload-file">
                    Upload new file</router-link>
                </div>
                <!-- /Search -->
                <hr class="my-5" />
                <!-- Contact List -->
                <div class="table-responsive custom-table">
                  <h5 class="bg-teal p-2">Import history</h5>
                  <!-- Table 2 (Completed data) -->
                  <a-table class="table" :columns="columns" :data-source="completedData">
                    <template #bodyCell="{ column, record }">
                      <template v-if="column.key === 'delete'">
                        <a href="#" @click="deleteFile(record.key)"><i class="ion-close-round text-danger" data-bs-toggle="tooltip" title="ion-close-round"></i></a>
                      </template>
                      <template v-if="column.key === 'completed'">
                        <p class="mb-0">{{ record.Completed }}</p>  
                        <p class="mb-0">{{ record.user }}</p>
                      </template>
                       <template v-if="column.key === 'action'">
                          <router-link :to="{
                              path: '/data-import/upload-file',
                              query: { useMapping: record.key },
                            }" class="text-teal fw-bold">Use mapping</router-link>
                      </template>
                      <template v-if="column.key === 'imp_log'">
                        <div v-html="record.imp_log"></div>
                        <div v-if="record.Warn > 0">
                          <h6>{{ record.Warn }} Warnings</h6>
                              <a :href="router.resolve({ path: '/data-import/error-logs', query: { fileId: record.key } }).href" target="_blank" class="text-teal fw-bold">
                                View details
                              </a>
                        </div>
                      </template>
                    </template>
                  </a-table>
                </div>
                <div class="row align-items-center">
                  <div class="col-md-6">
                    <div class="datatable-length"></div>
                  </div>
                  <div class="col-md-6">
                    <div class="datatable-paginate"></div>
                  </div>
                </div>
                <!-- /Contact List -->
              </div>
            </div>
            <!-- /Custom Fields -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  ref,
  onMounted,
  watchEffect
} from "vue";
import {
  useStore
} from "vuex";
import {
  useRouter
} from "vue-router";
import { message } from 'ant-design-vue';

// Columns for non-completed data (with "Action" column)
const columnsNonCompleted = [
  {
    title: "",
    dataIndex: "delete",
    key: "delete"
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status"
  },
  {
    title: "Action",
    key: "Action",
    dataIndex: "Action"
  },
  {
    title: "Updated",
    dataIndex: "updated"
  },
  {
    title: "Errors",
    dataIndex: "errors",
    key: "Errors"
  },
  {
    title: "Description",
    dataIndex: "Description",
    key: "Description"
  },
];

// Columns for completed data
const columns = [
  {
    title: "",
    dataIndex: "delete",
    key: "delete"
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status"
  },
   {
    title: "Action",
    dataIndex: "action",
    key: "action"
  },
  {
    title: "Completed",
    dataIndex: "Completed",
    key: 'completed',
  },
  {
    title: "Description",
    dataIndex: "Description",
    key: "Description"
  },
  {
    title: "Import log",
    dataIndex: "imp_log",
    key: "imp_log"
  },
];

const rowSelection = {
  onChange: () => {},
  onSelect: () => {},
  onSelectAll: () => {},
};

export default {
  data() {
    return {
      title: "Settings",
      columns,
      columnsNonCompleted, // Use different columns for non-completed data
      rowSelection,
    };
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const data = ref([]);
    const completedData = ref([]); // For completed data in Table 2
    const nonCompletedData = ref([]); // For non-completed data in Table 1
    const pagination = ref({
      current: 1,
      pageSize: 10,
      total: 0,
    });

    const fetchImportLists = async () => {
      const params = {
        page: pagination.value.current,
        pageSize: pagination.value.pageSize,
      };

      try {
        const response = await store.dispatch(
          "dataimport/fetchImportLists",
          params
        );
        if (response) {
          const formattedData = response.map((org) => {
            // Initialize formattedLogMessage
            let formattedLogMessage = "";

            // Process the logs if they exist
            if (org.logs && org.logs.length > 0) {
              try {
                // Parse and format the log_message
                const data = JSON.parse(org.logs[0].log_message);
                formattedLogMessage = Object.entries(data)
                  .map(
                    ([key, item]) =>
                    `${key}: ${item.Created} Created ${item.Updated} Updated`
                  )
                  .join("<br>"); // Join entries with HTML line break
              } catch (error) {
                console.error("Error parsing log_message:", error);
                formattedLogMessage = "Error formatting log message";
              }
            }

            return {
              key: org.id,
              Status: org.status,
              updated: org.updated_date ? formatDate(org.updated_date) : null,
              Completed: org.completed_date,
              user: org.completed_by_user,
              Description: org.description,
              Warn: org.warnings_count,
              imp_log: formattedLogMessage, // Use formatted log message
            };
          }); 

          // Filter for completed data (for Table 2)
          completedData.value = formattedData.filter(
            (org) => org.Status === "completed"
          );

          // Filter for non-completed data (for Table 1)
          nonCompletedData.value = formattedData.filter(
            (org) => org.Status !== "completed"
          );

          saveToLocalStorage();
        }
      } catch (error) {
        console.error("Error fetching import lists:", error);
      }
    };

    // Method to save file ID to local storage
    const saveToLocalStorage = () => {
      const nonCompletedIds = nonCompletedData.value[0]?.key;
      if (nonCompletedIds) {
        localStorage.setItem("fileId", nonCompletedIds);
      } else {
        localStorage.removeItem("fileId");
      }
    };

    const beginImport = async (id) => {
      const data = await store.dispatch("dataimport/beginImportData", id);
      if (data.status === 200) {
        fetchImportLists();
      }
    };

    const deleteFile = async (id) => {
      const response = await store.dispatch("dataimport/deleteImportFile", id);
      if(response.status){
        fetchImportLists();
        message.success(response.message)
      }
    };
   // Function to convert date format
   const formatDate = (date) => {
      const [day, month, year] = date.split('-');
      return `${day}/${month}/${year}`;
    };

    const handleTableChange = (newPagination) => {
      pagination.value.current = newPagination.current;
      pagination.value.pageSize = newPagination.pageSize;
      fetchImportLists();
    };

    watchEffect(() => {
      fetchImportLists();
    });

    return {
      data,
      completedData, // Completed data for Table 2
      nonCompletedData, // Non-completed data for Table 1
      formatDate,
      pagination,
      fetchImportLists,
      handleTableChange,
      beginImport,
      saveToLocalStorage,
      deleteFile,
      router
    };
  },
};
</script>
<style scoped lang="scss">
  .no-pending-file{
    p {
      background-color: #F6F6F6;
    }
  }
</style>
