import apiClient from '@/axios';
import { message } from 'ant-design-vue';
import router from '@/router';

const state = {
  authToken: localStorage.getItem('authToken') || '',
  user: JSON.parse(localStorage.getItem('user')) || {},
  company: {}
};
const mutations = {
  // setAuthToken(state, token) {
  //   state.authToken = token;
  // },
};
const getters = {
  user: state => state.user,
};

const actions = {
  async fetchLeads({ commit }, params) {
    try {
      const response = await apiClient.get('get-leads', {params});

      return response.data;
    } catch (error) {
      console.error('Error fetching leads data:', error);
    }
  },
  async assignLeads({ commit }, payload) {
    try {
      const response = await apiClient.post('/assign-leads', payload);
      commit('SET_BEING_DATA', response);
      return response;
    } catch (error) {
      console.error('Error fetching contract data:', error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
