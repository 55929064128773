<template>
<div>
  <div class="view-header">
    <h4>{{ orgContracts.length }} Contracts</h4>
    <ul>
      <li>
        <router-link class="btn btn-primary p-1" :to="{ path: '/crm/create-contract', query: { add: 'org-details', org_Id: orgId, territory_id: ter_Id}}">
          <i class="ti ti-plus"></i>
        </router-link>
      </li>
    </ul>
  </div>

  <div class="notes-activity" v-if="orgContracts.length > 0">
    <div class="calls-box" v-for="item in orgContracts" :key="item.id">
      <div class="caller-info">
        <div class="calls-user">
          <div class="opp-icon me-2">
            <i class="ti ti-file-check"></i>
          </div>
          <div>
            <p title="Date of sale">
              <router-link class="text-teal" :to="{ path: '/crm/edit-contract', query: { add: 'org-details', territory_id: ter_Id, org_Id: orgId, contract_Id: item.id }}">
                {{ formatDate(item.sale_date) }}
              </router-link>
            </p>
            <h6 title="Contract number">{{ item.contract_number }}</h6>
            <p title="Contract Responsible" class="org-title">{{ item.contract_responsible }}</p>
          </div>
        </div>
        <div class="calls-action">
          <router-link class="text-teal" :to="{ path: '/crm/edit-contract', query: { add: 'org-details', territory_id: ter_Id, org_Id: orgId, contract_Id: item.id }}">
            <i class="ti ti-edit text-blue"></i>
          </router-link>
        </div>
      </div>
      <h5>{{ item.contact_person }}</h5>
      <div class="row">
        <div class="col-md-4">
          <p><b>Pipeline level : </b> {{ item.pipeline_level }}</p>
        </div>
        <div class="col-md-4">
          <p><b>SC Forecast Total Commission : </b>{{ item.sc_forecast_total_commission }}</p>
        </div>
        <div class="col-md-4">
          <p><b>*Main product/service :</b> {{ item.main_production_service }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h6>Description :</h6>
          <p>
            {{ item.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="row notes-activity" v-else>
    <div class="col-12 bg-light">
      <p class="text-center">
        <i class="ion-information-circled"></i> No data found
      </p>
    </div>
  </div>
</div>
</template>

<script>
import {
  ref,
  onMounted,
  computed
} from "vue";
import {
  useStore
} from "vuex";
import {
  useRoute
} from "vue-router";
import apiClient from '@/axios';

export default {
  props: {
    terId: {
      type: String,
    }
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const orgId = ref(null);
    const ter_Id = ref(route.query.territory_id);
    const orgContracts = ref([]);
    const isContractTabActive = ref(false); // Flag to track tab visibility

    const fetchContracts = async (id) => {
      if (!isContractTabActive.value) {
        try {
          const response = await apiClient.get(`/contracts?organization_id=${id}`);
          orgContracts.value = response.data.data;
          isContractTabActive.value = true;
        } catch (error) {
          console.error('Error fetching opportunity data:', error);
        }
      }
    }
     // Function to convert date format
   const formatDate = (date) => {
      const [day, month, year] = date.split('-');
      return `${day}/${month}/${year}`;
    };

    onMounted(() => {
      orgId.value = route.query.org_Id;
      const personsTab = document.querySelector('[data-bs-target="#contracts"]');

      personsTab.addEventListener('shown.bs.tab', () => {
        fetchContracts(orgId.value); // Call the API when the Persons tab is active
      });
    });

    return {
      fetchContracts,
      orgContracts,
      formatDate,
      orgId,
      ter_Id,
      isContractTabActive
    };
  },
};
</script>

<style lang="scss" scoped>
.org-title{
  text-transform: capitalize;
}
.opp-icon {
  background-color: #E41F07;
  color: #FFF;
  border: 1px solid #E41F07;
  text-align: center;
  width: 25px;
  height: 25px;
  border-radius: 10px;
}
</style>
