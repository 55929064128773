<template>
  <div class="card settings-tab">
    <div class="card-body pb-0">
      <div class="settings-menu">
        <ul class="nav">
          <li v-if="user.permissions.includes('General Settings')">
            <router-link
              v-bind:class="{
                active:
                  currentPath.includes('security') ||
                  currentPath.includes('comapny') ||
                  currentPath.includes('plan-types')
              }"
              to="/settings/profile"
            >
                   
              <i class="ti ti-settings-cog"></i> General Settings
            </router-link>
          </li>
          <li v-if="user.permissions.includes('pipelines') || user.roles[0] === 'company_admin'">
          <!-- v-if="!['super_admin','sales_representative'].includes(user.roles?.[0])" -->
            <router-link
              v-bind:class="{
                active:
                  currentPath.includes('pipelines-list') ||
                  currentPath.includes('create-pipeline'),
              }"
              to="/pipelines/pipelines-list"
            >
              <i class="ti ti-timeline-event-exclamation"></i> Pipelines
            </router-link>
          </li>
          <li v-if="user.permissions.includes('Data Import')">
          <!-- v-if="user.roles?.[0] === 'company_admin'" -->
            <router-link
              v-bind:class="{
                active:
                  currentPath.includes('import-data-status') ||
                  currentPath.includes('create-mode') ||
                  currentPath.includes('map-data') ||
                  currentPath.includes('assign-data') ||
                  currentPath.includes('upload-file') ||
                  currentPath.includes('validate') ||
                  currentPath.includes('error-logs'),
              }"
              to="/data-import/import-data-status"
            >
              <i class="ti ti-world-cog"></i> Data Import
            </router-link>
          </li>
          <li v-if="user.permissions.includes('Users')">
          <!-- v-if="!['super_admin','sales_representative'].includes(user.roles?.[0])" -->
            <router-link
              v-bind:class="{
                active:
                  currentPath.includes('users-list') ||
                  currentPath.includes('create-user'),
              }"
              to="/users/users-list"
            >
              <i class="ion-person"></i> Users
            </router-link>
          </li>
          <!-- v-if="!['super_admin','sales_representative', 'territory_manager', 'manager'].includes(user.roles?.[0])" -->
           <li v-if="user.permissions.includes('Territories')"
           >
            <router-link
              v-bind:class="{
                active:
                  currentPath.includes('create-territory') ||
                  currentPath.includes('custom-fields'),
              }"
              to="/territory/territory-list"
            >
              <i class="ti ti-building"></i> Territories
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      currentPath: this.$route.path,
    };
  },
  computed: {
    ...mapGetters('auth', ['user']), // 'auth' is the namespace of your Vuex module
    
  },
};
</script>
