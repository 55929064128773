<template>
  <div class="modal fade" :class="{ show: isVisible }" tabindex="-1" aria-labelledby="exampleModalLabel"
    style="display: block" aria-modal="true" role="dialog" v-if="isVisible">
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <div class="row w-100">
            <div class="col-6">
              <h3>Create Opportunity</h3>
            </div>
            <div class="col-6 text-end">
              <button class="btn-close" @click="closeModal">
                <i class="ti ti-x"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="container">
            <Form @submit="saveOpprtunityData" :validation-schema="schema">
              <div class="row">
              <h4 class="text-indigo mb-2 text-decoration-underline">Contact Email</h4>
              <div class="col-md-4">
                <div class="form-wrap">
                  <label class="col-form-label">Email <span class="text-danger">*</span></label>
                  <Field id= "email" name="email" as="input" type="email" class="form-control"
                    v-model="contact_email" />
                    <ErrorMessage name="email" class="text-danger" />
                    <span class="text-danger" v-if="apiErrors && apiErrors['formData.email'] && apiErrors['formData.email'].length">
                            {{ apiErrors['formData.email'][0]}}
                          </span>
                </div>
              </div>
            </div>
            <div class="row">
              <h4 class="text-indigo mb-2 text-decoration-underline">Organisation Details</h4>
              <div class="col-md-4">
                <div class="form-wrap">
                  <label class="col-form-label">Name <span class="text-danger">*</span></label>
                  <div class="icon-form">
                    <Field name="organisation_id" type="text" class="form-control"
                      placeholder="Search organisation name / postcode" v-model="searchQuery"
                      @keydown.enter="searchOrganisations" @keyup="onInput" @focus="showDropdown"
                      @blur="hideDropdown" />
                    <span class="form-icon">
                      <i class="ti ti-building-community"></i>
                    </span>
                  </div>
                  <ul v-if="filteredOrganisations.length && isDropdownVisible" class="dropdown-menu shadow show pe-0" style="width: 97%;">
                    <li class="dropdown-listing">
                      <ul>
                        <li v-for="organisation in filteredOrganisations" :key="organisation.id"
                          @click="selectOrganisation(organisation)" class="dropdown-item" style="cursor: pointer;">{{
                            (organisation.name) + ' ' + ( organisation.postcode || '' ) }}
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ErrorMessage name="organisation_id" class="text-danger" />
                  <span class="text-danger" v-if="apiErrors && apiErrors['quoteData.organisation.name'] && apiErrors['quoteData.organisation.name'].length">
                            {{ apiErrors['quoteData.organisation.name'][0]}}
                          </span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-wrap">
                  <label class="col-form-label">Postcode</label>
                  <Field name="org_postcode" as="input" type="text" class="form-control"
                    v-model="formData.QuoteData.organisation.postcode" />
                </div>
              </div>
            </div>
            <div class="row">
              <h4 class="text-indigo mb-2 text-decoration-underline">Person Details</h4>
              <div class="col-md-4">
                <div class="form-wrap">
                  <label class="col-form-label">Name <span class="text-danger">*</span></label>
                  <div class="icon-form">
                    <Field name="person_id" type="text" class="form-control" placeholder="Search person name"
                      v-model="searchPerson" @keydown.enter="searchPersons" @keyup="onPersonInput" @focus="showPersonDropdown"
                      @blur="hidePersonDropdown" />
                    <span class="form-icon">
                      <i class="ti ti-building-community"></i>
                    </span>
                  </div>
                  <ul v-if="filteredPersons.length && isDropdownPersonVisible" class="dropdown-menu shadow show pe-0" style="width: 97%;">
                    <li class="dropdown-listing">
                      <ul>
                        <li v-for="person in filteredPersons" :key="person.id" @click="selectPerson(person)"
                          class="dropdown-item" style="cursor: pointer;">{{ person.name }}
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ErrorMessage name="person_id" class="text-danger" />
                </div>
              </div>
            </div>
            <div class="row">
              <h4 class="text-indigo mb-2 text-decoration-underline">Supply Address</h4>
              <div class="col-md-4">
                <div class="form-wrap">
                  <label class="col-form-label">Building Name <span class="text-danger">*</span></label>
                  <Field name="building_name" as="input" type="text" class="form-control"
                    v-model="formData.QuoteData.opportunity.building_name" />
                    <ErrorMessage name="building_name" class="text-danger" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-wrap">
                  <label class="col-form-label">Street <span class="text-danger">*</span></label>
                  <Field name="street" as="input" type="text" class="form-control"
                    v-model="formData.QuoteData.opportunity.street" />
                    <ErrorMessage name="street" class="text-danger" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-wrap">
                  <label class="col-form-label">City <span class="text-danger">*</span></label>
                  <Field name="city" as="input" type="text" class="form-control"
                    v-model="formData.QuoteData.opportunity.city" />
                    <ErrorMessage name="city" class="text-danger" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-wrap">
                  <label class="col-form-label">County <span class="text-danger">*</span></label>
                  <Field name="county" as="input" type="text" class="form-control"
                    v-model="formData.QuoteData.opportunity.county" />
                    <ErrorMessage name="county" class="text-danger" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-wrap">
                  <label class="col-form-label">Postcode <span class="text-danger">*</span></label>
                  <Field name="postcode" as="input" type="text" class="form-control"
                    v-model="formData.QuoteData.opportunity.postcode" />
                    <ErrorMessage name="postcode" class="text-danger" />
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6"></div>
              <div class="col-md-6 text-end">
                <button type="submit" class="btn btn-primary">
                  Create and Send
                </button>
              </div>
            </div>
          </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref,
  watch,
  onMounted,
  computed,
} from "vue";
import {
  useStore
} from "vuex";
import {
  useRoute,
  useRouter
} from 'vue-router';
import {
  Form,
  Field,
  ErrorMessage
} from "vee-validate";
import * as Yup from "yup";

export default {
  props: {
    isVisible: {
      type: Boolean,
    },
    supplierData: {
      type: Object
    },
    currentSupplierData: {
      type: Object
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  setup(props, {
    emit
  }) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const filteredOrganisations = ref([]);
    const opportunity_id = ref(route.query.opp_Id || null)
    const filteredPersons =ref([]);
    const searchQuery = ref('');
    const searchPerson = ref('');
    const apiErrors = ref(null);
    const isDropdownVisible = ref(false)
    const isDropdownPersonVisible = ref(false)
    const supplierPostCode = ref(props.currentSupplierData?.quoteDetails?.PostCode || '')
    const orgPersons = computed(() => store.getters['opportunity/getPersonsData']);
    const orgOptions = computed(() => store.getters['opportunity/getorgOtionsData']);
    const onInput = () => {
      searchOrganisations();
    }
    const onPersonInput = () => {
      searchPersons();
    }
    const searchPersons = () => {
      const query = searchPerson.value.toLowerCase();
      formData.value.QuoteData.organisationPerson.first_name = query;
      formData.value.QuoteData.organisationPerson.person_id = null;

      if (query) {
        filteredPersons.value = orgPersons.value.filter((per) => {
          const nameMatch = per.name.toLowerCase().includes(query);
          return nameMatch;
        });
      } else {
        filteredPersons.value = [];
      }
    };
    const selectPerson = (person) => {
      formData.value.QuoteData.organisationPerson.person_id = person.id;
      formData.value.QuoteData.organisationPerson.first_name = '';
      searchPerson.value = person.name;
      filteredPersons.value = [];
    };
    const searchOrganisations = () => {
      const query = searchQuery.value.toLowerCase();
      formData.value.QuoteData.organisation.name = query;
      formData.value.QuoteData.organisation.org_id = null;
      if (query) {
        filteredOrganisations.value = orgOptions.value.filter((org) => {
          const nameMatch = org.name.toLowerCase().includes(query);
          const postcodeMatch = org.postcode ?
            org.postcode.toString().toLowerCase().includes(query) :
            false; // If postcode is null or undefined, this will prevent the error
          return nameMatch || postcodeMatch;
        });
      } else {
        filteredOrganisations.value = [];
      }
    };
    const selectOrganisation = (organisation) => {
      formData.value.QuoteData.organisation.org_id = organisation.id;
      formData.value.QuoteData.organisation.name = '';
      if (organisation.postcode) {
        formData.value.QuoteData.organisation.postcode = organisation.postcode
      }
      searchQuery.value = organisation.name;
      store.dispatch('opportunity/fetchPersons', organisation.id)
      store.dispatch('organisation/fetchTerritoryCompanyUsers', organisation.territory_id)
      filteredOrganisations.value = [];
    };

    const contact_email = ref('');
    const formData = ref({
      QuoteData: {
        organisation: {
          org_id: null,
          name: "",
          postcode: "",
          // email:props.currentSupplierData.quoteDetails.Email || ""
        },
        organisationPerson: {
          person_id: null,
          first_name: ""
        },
        opportunity: {
          postcode: supplierPostCode.value,
          building_name: "",
          street: "",
          city: "",
          county: ""
        }
      }
    })
    const schema = Yup.object().shape({
      organisation_id: Yup.string()
        .required("Organisation name is required"),
      person_id: Yup.string()
        .required("Person name is required"),
        building_name: Yup.string()
        .required("Building name is required"),
        postcode: Yup.string()
        .required("Postcode is required"),
        email: Yup.string()
        .required("Email is required")
        .email("The email field must be a valid email address."),
        street: Yup.string()
        .required("Street is required"),
        city: Yup.string()
        .required("City is required"),
        county: Yup.string()
        .required("County is required"),
    });
    const changeFormate = (date) => {
      const contractRenewalDate = new Date(date);
      // Convert to ISO format (like "2024-10-18T00:00:00.000Z")
      let isoString = contractRenewalDate.toISOString();
      // Remove the milliseconds and "Z" (timezone info) to get "2024-10-18T00:00:00"
      let formattedDate = isoString.split('.')[0];
      return formattedDate;
    };
    const saveOpprtunityData = async (supplier) => {
      const pdfParams = {
        service_type: props.currentSupplierData.mainProductService,
        organization_id: formData.value.QuoteData.organisation.org_id,
        person_id: formData.value.QuoteData.organisationPerson.person_id,
        opportunity_id: opportunity_id.value,
        quoteData: formData.value.QuoteData,
        formData: {
          email: contact_email.value || props.currentSupplierData.quoteDetails.Email,
          mpr: props.currentSupplierData.quoteDetails?.GasSupply?.MPR || props.currentSupplierData.quoteDetails?.ElectricSupply?.MPANBottom,
          contract_length: props.supplierData?.Term || supplier?.Term,
          credit_score: props.currentSupplierData.quoteDetails.Settings[1].value,
          current_supplier: props.currentSupplierData.quoteDetails.CurrentSupplier,
          renew_date: changeFormate(props.currentSupplierData.quoteDetails?.GasSupply?.ContractRenewalDate | props.currentSupplierData.quoteDetails?.ElectricSupply?.ContractRenewalDate),
          new_contract_end_date: props.supplierData?.ContractEndDate + 'T00:00:00' || supplier?.ContractEndDate + 'T00:00:00',
          payment_type: props.supplierData?.PaymentMethod?.DisplayName || supplier?.PaymentMethod?.DisplayName,
          annual_price: props.supplierData?.RawAnnualPrice || supplier?.RawAnnualPrice || null,
          annual_price_inclusive: props.supplierData?.RawAnnualPriceInclusive || supplier?.RawAnnualPriceInclusive || null,
          unit_rate_uplift: props.supplierData?.Uplift || supplier?.Uplift,
          annual_commission: props.supplierData?.RawCommission || supplier?.RawCommission,
          new_supplier: props.supplierData?.Supplier || supplier?.Supplier,
          standing_charge_uplift: props.supplierData?.StandingChargeUplift || supplier?.StandingChargeUplift || '',
          mpan_top: props.currentSupplierData.quoteDetails?.ElectricSupply?.MPANTop || '',
          mpan_bottom: props.currentSupplierData.quoteDetails?.ElectricSupply?.MPANBottom || '',
          kva: props.currentSupplierData.quoteDetails?.ElectricSupply?.KvaConsumption?.Amount || null,

        },
        docusignDetails: {
          UsageRatesData: [
            {
              Key: "standing charge",
              Value: props.supplierData?.StandingCharge || supplier?.StandingCharge || '',
            },
            {
              Key: "dayusage",
              Value: props.currentSupplierData.quoteDetails?.GasSupply?.Consumption.Amount || props.currentSupplierData.quoteDetails?.ElectricSupply?.DayConsumption.Amount || '',
            },
            {
              Key: "daycharge",
              Value: props.supplierData?.DayUnitrate || supplier?.DayUnitrate || '',
            },
            {
              Key: "nightusage",
              Value: props.currentSupplierData.quoteDetails?.ElectricSupply?.NightConsumption.Amount || '',
            },
            {
              Key: "nightcharge",
              Value: props.supplierData?.NightUnitrate || supplier?.NightUnitrate || '',
            },
            {
              Key: "eveandwendusage",
              Value: props.currentSupplierData.quoteDetails?.ElectricSupply?.WendConsumption.Amount || '',
            },
            {
              Key: "eveandwendcharge",
              Value: props.supplierData?.WendUnitrate || supplier?.WendUnitrate || '',
            }
          ],
          MeterDetailsData: [
            {
              Key: "ref",
              Value: props.supplierData?.Ref || supplier?.Ref || '',
            },
            {
              Key: "period",
              Value: props.supplierData?.Period || supplier?.Period || '',
            },
            {
              Key: "sc",
              Value: props.supplierData?.SC || supplier?.SC || '',
            },
            {
              Key: "fits",
              Value: props.supplierData?.Fits || supplier?.Fits || '',
            },
            {
              Key: "creditscore",
              Value: props.currentSupplierData.quoteDetails.Settings[1].value || '',
            }
          ],
          TemplateOptions: {
            PlanType: null,
            HalfHourly: false,
            ChangeOfTenancy: false
          }
        }
      }
        const response = await store.dispatch("price/sendSupplierPdf", pdfParams);
        if(response.success){
          contact_email.value = null;
          searchQuery.value = null;
          searchPerson.value=null;
          formData.value.QuoteData= {
          organisation: {
          org_id: null,
          name: "",
          postcode: "",
        },
        organisationPerson: {
          person_id: null,
          first_name: ""
        },
        opportunity: {
          postcode: supplierPostCode.value,
          building_name: "",
          street: "",
          city: "",
          county: ""
        }
      }
          
          emit("close");
          emit('sendingFail', response)
        } else{
          apiErrors.value = response;
          emit('sendingFail', apiErrors.value)
          // message.error('Organisation name and post code already exists');
        }
    };
    const closeModal = () => {
          contact_email.value = null;
          searchQuery.value = null;
          searchPerson.value=null;
          formData.value.QuoteData= {
          organisation: {
          org_id: null,
          name: "",
          postcode: "",
        },
        organisationPerson: {
          person_id: null,
          first_name: ""
        },
        opportunity: {
          postcode: supplierPostCode.value,
          building_name: "",
          street: "",
          city: "",
          county: ""
        }
      }
      emit("close"); // Now correctly using emit
    };

    const showDropdown=()=> {
      isDropdownVisible.value = true;
    }
    const hideDropdown = ()=> {
      setTimeout(() => {
        isDropdownVisible.value = false;
      }, 100);
    }

    const showPersonDropdown=()=> {
      isDropdownPersonVisible.value = true;
    }
    const hidePersonDropdown = ()=> {
      setTimeout(() => {
        isDropdownPersonVisible.value = false;
      }, 100);
    }

    watch(
      () => props.isVisible,
      (newValue) => {
        if (newValue) {
          document.body.classList.add("modal-open");
          const backdrop = document.createElement("div");
          backdrop.className = "modal-backdrop fade show";
          document.body.appendChild(backdrop);
        } else {
          document.body.classList.remove("modal-open");
          const backdrop = document.querySelector(".modal-backdrop");
          if (backdrop) {
            backdrop.remove();
          }
        }
      }
    );
    watch(
            () => props.isVisible, // Watching the `isVisible` prop
            (newValue) => {
                if (newValue) {
                  contact_email.value =
                    props.currentSupplierData.quoteDetails.Email;
                }
            }
        );
    // Initialize search options on component mount
    onMounted(async () => {
      await store.dispatch('opportunity/getorgOptions');
    });

    return {
      schema,
      contact_email,
      apiErrors,
      searchPerson,
      opportunity_id,
      selectPerson,
      orgPersons,
      searchPersons,
      supplierPostCode,
      onPersonInput,
      closeModal,
      changeFormate,
      formData,
      saveOpprtunityData,
      onInput,
      searchOrganisations,
      searchQuery,
      selectOrganisation,
      filteredOrganisations,
      filteredPersons,
      showDropdown,
      hideDropdown,
      isDropdownVisible,
      isDropdownPersonVisible,
      showPersonDropdown,
      hidePersonDropdown
    };
  },
};
</script>

<style scoped>
.modal {
  display: block;
  /* Ensure modal is displayed correctly */
}

.table_height {
  max-height: 428px;
}
</style>
