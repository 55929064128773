<template>
  <a-table
    class="table dataTable no-footer"
    :columns="columns"
    :data-source="data"
    :row-selection="rowSelection"
    :pagination="pagination"
     @change="handleTableChange"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'Star'">
        <div class="set-star rating-select"><i class="fa fa-star"></i></div>
      </template>
      <template v-else-if="column.key === 'number'">
        <div>
          <router-link :to="{ path: '/crm/meter-opportunities-edit', query: { territory_id: record.territory_id, org_Id: record.orgId, opp_Id: record.key }}" class="title-name">{{
            record.number
          }}</router-link>
        </div>
      </template>
      <template v-if="column.key === 'org_name'">
        <router-link :to="{ path: '/crm/organisation-details', query: { org_Id: record.orgId }}" class="title-name">{{
            record.org_name
          }}</router-link>
      </template>
    
      <template v-else-if="column.key === 'action'">
            <div class="icon-field">
            
            <!-- <router-link :to="{ path: '/crm/meter-opportunities-edit', query: { territory_id: record.territory_id, org_Id: record.orgId, opp_Id: record.key }}" title="Edit" class="btn table_action_btn"><i class="ti ti-edit"></i>  Edit</router-link> -->
            <a
  :href="$router.resolve({
    path: '/crm/meter-opportunities-edit',
    query: {
      kanban: true,
      territory_id: record.territory_id,
      org_Id: record.orgId,
      opp_Id: record.key,
    },
  }).href"
  target="_blank"
  title="Edit"
  class="btn table_action_btn"
>
<i class="ti ti-edit"></i>  Edit
</a>


            <router-link :to="{ path: '/quoting', query: { opp_Id: record.key, for: record.product }}" v-if="record.is_validate !== 0" class="btn table_action_btn ms-2"> <i
                        class="ti-bar-chart"
                      ></i>  Get Quote</router-link>
                      <router-link :to="{ path: '/crm/quoted-price-list', query: { territory_id: record.territory_id, org_Id: record.orgId, opp_Id: record.key }}" v-if="record.quotes_count !== 0" class="btn table_action_btn ms-2"> <i
                        class="ion-pricetags"
                      ></i> Quoted Prices</router-link>
            </div>
      </template>
    </template>
  </a-table>
</template>

<script>
import {
  ref,
  watchEffect
} from 'vue';
import {
  useRouter
} from 'vue-router';
import apiClient from '@/axios';

const columns = [{
    title: "Number",
    dataIndex: "number",
    key: "number",
    sorter: {
      compare: (a, b) => {
        a = a.number;
        b = b.number;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Org. Name",
    dataIndex: "org_name",
    key: "org_name",
    sorter: {
      compare: (a, b) => {
        a = a.org_name;
        b = b.org_name;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "Description",
    sorter: {
      compare: (a, b) => {
        a = a.description;
        b = b.description;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Pipeline text",
    dataIndex: "pipe_line",
    sorter: {
      compare: (a, b) => {
        a = a.pipe_line;
        b = b.pipe_line;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date of Sale",
    dataIndex: "date_of_sale",
    sorter: {
      compare: (a, b) => {
        a = a.date_of_sale;
        b = b.date_of_sale;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "SC Forecast Annual Consumption",
    dataIndex: "annual_consumption",
    sorter: {
      compare: (a, b) => {
        a = a.annual_consumption;
        b = b.annual_consumption;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    dataIndex: "Action",
    key: "action",
  },
];

export default {
  props: {
    searchOppQuery: String,
    filters: Object,
  },
  data() {
    return {
      columns,
      data: [],
      selectedUserId: null,
      showDeleteModal: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      rowSelection: {
        onChange: () => {},
        onSelect: () => {},
        onSelectAll: () => {},
      },
    };
  },

  setup(props) {
    const router = useRouter();
    const data = ref([]);
    const pagination = ref({
      current: 1,
      pageSize: 10,
      total: 0,
    });

    const fetchOpportunities = async () => {
      try {
        const response = await apiClient.get('/opportunities', {
          params: {
            search: props.searchOppQuery,
            page: pagination.value.current,
            pageSize: pagination.value.pageSize,
          },
        });
        if (response.data.status) {
          data.value = response.data.data.data.map((opp) => ({
            key: opp.id,
            number: opp.meter_opportunity_nr_auto,
            pipe_line: opp.pipeline.name,
            description: opp.description,
            territory_id: opp.territory_id,
            orgId: opp.organization.id,
            org_name: opp.organization.name,
            date_of_sale: opp.date_of_sale ? formatDate(opp.date_of_sale) : null,
            annual_consumption: opp.forecast_commission_data?.sc_forecast_or_amended_annual_consumption || '0.00',
            is_validate: opp.is_valid,
            product: opp.main_production_service,
            quotes_count: opp.quotes_count,
          }));
          pagination.value.total = response.data.data.total;
        } else {
          console.error("Failed to fetch opportunities:", response);
        }
      } catch (error) {
        console.error("Error fetching opportunities:", error);
      }
    };
  // Function to convert date format
    const formatDate = (date) => {
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    };
    const handleTableChange = (newPagination) => {
      pagination.value.current = newPagination.current;
      pagination.value.pageSize = newPagination.pageSize;
      fetchOpportunities();
    };

    const openEditModal = (id) => {
      router.push({
        path: "/crm/create-organisation",
        query: {
          org_Id: id
        },
      });
    };

    watchEffect(() => {
      fetchOpportunities();
    });

    return {
      data,
      pagination,
      formatDate,
      fetchOpportunities,
      handleTableChange,
      openEditModal,
    };
  },
};
</script>
