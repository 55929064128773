<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" />
          <!-- /Page Header -->

          <div class="card main-card">
            <div class="card-body">
              <!-- Search -->
              <div class="search-section">
                <div class="row">
                  <div class="col-md-5 col-sm-4">
                    <div class="form-wrap icon-form">
                      <span class="form-icon"><i class="ti ti-search"></i></span>
                      <input type="text" class="form-control" placeholder="Search Reports" v-model="searchQuery"
                        @input="searchReports" />
                    </div>
                  </div>
                  <div class="col-md-7 col-sm-8">
                    <div class="export-list text-sm-end">
                      <ul>
                        <li>
                      <div class="form-sorts dropdown">
                        <a href="javascript:void(0);" data-bs-toggle="dropdown" data-bs-auto-close="true">
                          <i class="ti ti-filter-share"></i>Filter
                        </a>
                        <div class="filter-dropdown-menu dropdown-menu dropdown-menu-xl-end">
                          <div class="filter-set-view">
                            <div class="filter-set-head">
                              <h4><i class="ti ti-filter-share"></i>Filter</h4>
                            </div>
                            <div class="filter-content-list">
                              <label for="search-status" class="mb-2">Reports: Data Type</label>
                              <div class="form-wrap icon-form">
                                <span class="form-icon">
                                  <i class="ti ti-search"></i>
                                </span>
                                <select v-model="filters.type" id="search-status" class="form-control">
                                  <option value="" disabled>Select data type</option>
                                  <option v-for="mod in module_ids?.modules" :key="mod.id" :value="mod.id">
                                    {{ mod.name }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="filter-content-list">
                              <label for="search-vat-group" class="mb-2">Reports: Name</label>
                              <div class="form-wrap icon-form">
                                <span class="form-icon"><i class="ti ti-search"></i></span>
                                <input v-model="filters.name" type="text" id="search-vat-group" class="form-control"
                                  placeholder="Enter Value" />
                              </div>
                            </div>
                            <div class="filter-reset-btns">
                              <div class="row">
                                <div class="col-6">
                                  <a href="javascript:void(0);" class="btn btn-light" @click="resetFilters">Reset</a>
                                </div>
                                <div class="col-6">
                                  <a href="javascript:void(0);" class="btn btn-primary" @click="applyFilters">Filter</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                        <li>
                          <a class="btn btn-primary" href="javascript:;" data-bs-toggle="modal"
                            data-bs-target="#add_reports" @click="openModal">Add Reports</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Search -->

              <!-- Contact List -->
              <div class="table-responsive custom-table">
                <report-table :search-query="searchQuery" :key="tableKey" :filters="appliedFilters"
                  ref="reportsTable"></report-table>
              </div>
              <div class="row align-items-center">
                <div class="col-md-6">
                  <div class="datatable-length"></div>
                </div>
                <div class="col-md-6">
                  <div class="datatable-paginate"></div>
                </div>
              </div>
              <!-- /Contact List -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->

  <!-- Add Reports -->
  <div ref="addReportsModal" class="modal fade" id="add_reports" tabindex="-1" role="dialog"
    aria-labelledby="addReportsLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <h3>Create</h3>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <Form @submit="handleSubmit" class="toggle-height">
            <div class="mb-3">
              <label for="report-name" class="form-label">Name</label>
              <Field type="text" name="name" class="form-control" id="report-name" placeholder="Enter report name"
                v-model="formData.name" :class="{ 'is-invalid': !formData.name && formSubmitted }" />
              <!-- <ErrorMessage name="name" class="text-danger" /> -->
              <div v-if="!formData.name && formSubmitted" class="invalid-feedback">
                Field is required.
              </div>
            </div>
            <div class="mb-3">
              <label for="data-type" class="form-label">Data Type</label>
              <select class="form-select" name="module_id" id="data-type" v-model="formData.module_id"
                :class="{ 'is-invalid': !formData.module_id && formSubmitted }">
                <option value="" disabled selected>Select data type</option>
                <option v-for="mod in module_ids?.modules" :key="mod.id" :value="mod.id">
                  {{ mod.name }}
                </option>
              </select>
              <div v-if="!formData.module_id && formSubmitted" class="invalid-feedback">
                Field is required.
              </div>
            </div>
            <div class="col-lg-12 modal-btn text-end">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal" id="close-btn">Cancel</a>
              <button type="submit" class="btn btn-primary" id="add-report-btn">
                Add Report
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>

  <!-- /Add Reports -->
</template>

<script>
// import "bootstrap";
import "daterangepicker/daterangepicker.css";
import "daterangepicker/daterangepicker.js";
import { ref, onMounted, watchEffect, nextTick } from "vue";
import moment from "moment";
import DateRangePicker from "daterangepicker";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import Multiselect from "vue-multiselect";

export default {
  components: {
    ErrorMessage,
    Form,
    Field,
    Multiselect,
  },
  data() {
    return {
      title: "Reports",
    };
  },
  setup() {
    const dateRangeInput = ref(null);
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const tableKey = ref(0);
    const searchQuery = ref("");
    const appliedFilters = ref({});
    const reportsTable = ref(null);

    const filters = ref({
      type: "",
      name: "",
    });
    const formData = ref({
      id: null,
      name: "",
      module_id: "",
    });
    const formSubmitted = ref(false);

    const module_ids = ref({});


    const openModal = async () => {
      formSubmitted.value = false;
      formData.value = {
        id: null,
        name: "",
        module_id: "",
      };
    };

    // Date range formatting function
    function booking_range(start, end) {
      return start.format("M/D/YYYY") + " - " + end.format("M/D/YYYY");
    }


    const handleSubmit = async () => {
      formSubmitted.value = true;

      if (formData.value.name === '' || formData.value.module_id === '') {
        return
      }
      const response = await store.dispatch("report/saveReportData", formData.value);
      if (response.status) {

        formData.value = {
          id: null,
          name: "",
          module_id: "",
        };

        document.getElementById("close-btn").click();

        tableKey.value += 1;

        router.push("/crm/reports-list");
      }
    };

    const schema = Yup.object().shape({
      name: Yup.string().required("Report name is required"),
    });

    const searchReports = () => {
      if (reportsTable.value) {
        reportsTable.value.fetchReports();
      }
    };


    const applyFilters = () => {
      appliedFilters.value = { ...filters.value };
      // Wait for the filters to be applied, then fetch reports
      nextTick(() => {
        searchReports();
      });
    };

    const resetFilters = () => {
      filters.value = {
        type: "",
        name: "",
      };
      applyFilters();
    };


    onMounted(async () => {
      module_ids.value = await store.dispatch("report/fetchDropdownOptions");
      if (dateRangeInput.value) {
        const start = moment().subtract(6, "days");
        const end = moment();

        new DateRangePicker(
          dateRangeInput.value,
          {
            startDate: start,
            endDate: end,
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
              "Last 7 Days": [moment().subtract(6, "days"), moment()],
              "Last 30 Days": [moment().subtract(29, "days"), moment()],
              "This Month": [moment().startOf("month"), moment().endOf("month")],
              "Last Month": [
                moment().subtract(1, "month").startOf("month"),
                moment().subtract(1, "month").endOf("month"),
              ],
            },
          },
          booking_range
        );
        // Set initial date range value
        booking_range(start, end);
      }

      // Sidebar toggle logic moved here
      if (document.getElementsByClassName("add-popup").length > 0) {
        const addPopups = document.getElementsByClassName("add-popup");
        for (let i = 0; i < addPopups.length; i++) {
          addPopups[i].addEventListener("click", () => {
            const togglePopup = document.getElementsByClassName("toggle-popup")[0];
            if (togglePopup) {
              togglePopup.classList.add("sidebar-popup");
            }
          });
        }

        const sidebarCloses = document.getElementsByClassName("sidebar-close");
        for (let j = 0; j < sidebarCloses.length; j++) {
          sidebarCloses[j].addEventListener("click", () => {
            const togglePopup = document.getElementsByClassName("toggle-popup")[0];
            if (togglePopup) {
              togglePopup.classList.remove("sidebar-popup");
            }
          });
        }
      }
    });

    return {
      dateRangeInput,
      handleSubmit,
      tableKey,
      formData,
      store,
      route,
      router,
      booking_range,
      schema,
      module_ids,
      openModal,
      formSubmitted,
      searchReports,
      searchQuery,
      appliedFilters,
      filters,
      applyFilters,
      resetFilters,
      reportsTable,
    };
  },
};
</script>

<style>
.filter-section.filter-flex {
  justify-content: end !important;
}
</style>