<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" />
          <!-- /Page Header -->
          <div class="contact-head">
            <div class="row align-items-center">
              <div class="col-sm-6">
                <ul class="contact-breadcrumb">
                  <li>
                    <router-link
                       :to="{ path: '/crm/meter-opportunities-edit', query: { territory_id: territoryId, org_Id: orgId, opp_Id: oppId }}"
                    >
                      <i class="ti ti-arrow-narrow-left"></i>
                      Meter Opportunity
                    </router-link>
                  </li>
                  <li>Quoted price list</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card main-card">
            <div class="card-body">
              <!-- Search -->
              <!-- <div class="search-section">
                <div class="row">
                  <div class="col-md-5 col-sm-4">
                    <div class="form-wrap icon-form">
                      <span class="form-icon"><i class="ti ti-search"></i></span>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search opportunities" v-model="searchOppQuery" @input="searchOpportunities"
                      />
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- /Search -->

              <!-- Contact List -->
              <div class="table-responsive custom-table">
                <quoted-prices-table :search-opp-query="searchOppQuery" ref="oppTable"></quoted-prices-table>
              </div>
              <div class="row align-items-center">
                <div class="col-md-6">
                  <div class="datatable-length"></div>
                </div>
                <div class="col-md-6">
                  <div class="datatable-paginate"></div>
                </div>
              </div>
              <!-- /Contact List -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <opportunities-list-modal @list-updated="handleListUpdated"></opportunities-list-modal> -->
  <!-- /Page Wrapper -->
</template>

<script>
import { ref } from "vue";
import {
  useRouter,
  useRoute
} from "vue-router";

export default {
  data() {
    return {
      title: "Quoted Price",
      searchOppQuery: "",
    };
  },
  methods: {
    // async searchOpportunities() {
    //   this.$refs.oppTable.fetchOpportunities();
    // },
    async handleListUpdated() {
      await this.searchOpportunities(); // Update the list
      // this.$router.push({ name: 'listing-page' }); // Redirect to the listing page
    }
  },
  setup() {
    const route = useRoute();
    const territoryId = ref(route.query.territory_id)
    const orgId = ref(route.query.org_Id)
    const oppId = ref(route.query.opp_Id)

    return {
      territoryId,
      oppId,
      orgId
    };
  },
};
</script>
