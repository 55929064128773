<template>
  <!-- Sidebar -->
  <div class="sidebar" id="sidebar">
    <perfect-scrollbar
      class="scroll-area"
      :settings="settings"
      @ps-scroll-y="scrollHanle"
    >
      <div class="sidebar-inner slimscroll">
        <div id="sidebar-menu" class="sidebar-menu">
          <ul>
            <li class="clinicdropdown">
              <router-link :to=" user.roles?.[0] === 'super_admin' ? '/companies' : '/settings/profile'">
                <img
                v-if="user.image"
                  :src="user.image"
                  class="img-fluid"
                  alt="Profile"
                />
                <img
                v-else
                  src='@/assets/img/profiles/avatar.svg'
                  class="img-fluid"
                  alt="Profile"
                />
                <div class="user-names">
                  <h5>{{ user.first_name }} {{ user.last_name }}</h5>
                  <h6>{{ user.company_name }}</h6>
                </div>
              </router-link>
            </li>
          </ul>
          <vertical-sidebar></vertical-sidebar>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
  <!-- /Sidebar -->
</template>

<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    PerfectScrollbar,
  },
  data() {
    return {
      settings: {
        suppressScrollX: true,
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['user']), // 'auth' is the namespace of your Vuex module
    
  },
  mounted() {
    // Load user data from localStorage when the component mounts
    this.loadUserFromLocalStorage();
  },
  methods: {
    ...mapActions('auth', ['loadUserFromLocalStorage']),
    scrollHanle() {},
  },
};
</script>
