<template>
<layouts-header></layouts-header>
<layouts-sidebar></layouts-sidebar>
<AlertPopup :importData="importData"/>
<div class="page-wrapper">
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <!-- Page Header -->
        <page-header />
        <!-- /Page Header -->

        <!-- Settings Menu -->
        <settings-tabs></settings-tabs>
        <!-- /Settings Menu -->

        <div class="row">
          <div class="col-xl-12 col-lg-12">
            <!-- Prefixes -->
            <div class="card">
              <div class="card-body settings-form">
                <div class="settings-header">
                  <website-sidebar :file_id="file_id"></website-sidebar>
                </div>
                <Form @submit="handleSubmit" :validation-schema="schema" v-if="Object.keys(assignedData).length > 0">
                  <div class="row align-items-center py-2">
                    <div class="col"></div>
                    <div class="col-md-7">
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <h5 class="text-teal"><span>Total Rows:</span> <span>{{ assignedData.total_rows }}</span></h5>
                        </div>
                        <div class="col-md-4 text-center">
                          <h5 class="text-danger"><span>Unassigned Rows: </span> <span>{{ assignedData.total_rows - assignedData.assgined_rows_count }}</span></h5>
                        </div>
                        <div class="col-md-4 text-end">
                          <h5 class="text-success"><span>Assigned Rows: </span> <span>{{ assignedData.assgined_rows_count }}</span></h5>
                        </div>
                      </div>
                      <div v-for="(row, index) in rows" :key="index" class="row position-relative">
                        <div class="col-md-3">
                          <label class="col-form-label">Start</label>
                          <Field :name="'rows[' + index + '].start'" as="input" type="number" class="form-control" v-model="row.start" />
                          <ErrorMessage :name="'rows[' + index + '].start'" class="text-danger" />
                        </div>
                        <div class="col-md-3">
                          <label class="col-form-label">End</label>
                          <Field :name="'rows[' + index + '].end'" as="input" type="number" class="form-control" v-model="row.end" />
                          <ErrorMessage :name="'rows[' + index + '].end'" class="text-danger" />
                        </div>
                        <div class="col-md-3">
                          <div class="form-wrap">
                            <label class="col-form-label">Territory</label>
                            <Field as="select" :name="'rows[' + index + '].territory_id'" class="form-select" v-model="row.territory_id" @change="handleTerritoryChange(index)">
                              <option value="">-----Select-----</option>
                              <option v-for="ter in Territories" :key="ter.id" :value="ter.id">{{ ter.name }}</option>
                            </Field>
                            <ErrorMessage :name="'rows[' + index + '].territory_id'" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-wrap">
                            <label class="col-form-label">Responsible</label>
                            <Field as="select" :name="'rows[' + index + '].responsible'" class="form-select" v-model="row.responsible">
                              <option value="">-----None-----</option>
                              <option v-for="res in row.responsibles" :key="res.id" :value="res.id">{{ res.name }}</option>
                            </Field>
                            <ErrorMessage :name="'rows[' + index + '].responsible'" class="text-danger" />
                          </div>
                        </div>

                        <!-- Conditional Delete Button for all rows except the first -->
                        <div class="mt-2 position-absolute delete_row" v-if="index > 0">
                          <a href="#" @click="deleteRow(index)"><i class="ion-close-round text-danger" data-bs-toggle="tooltip" title="Delete Row"></i></a>
                          <!-- <button type="button" class="btn btn-danger" @click="deleteRow(index)">Delete Row</button> -->
                        </div>
                      </div>

                      <!-- Button to Add New Row -->
                      <div class="row align-items-center">
                        <div class="col-md-8">
                          <div v-if="fileWarn > 0">
                            <p class="mb-0">Some records contain errors. Please review the following issues. <a :href="getErrorLogLink(file_id)" target="_blank" class="text-teal fw-bold">
                              View details
                            </a></p>
                            <!-- <h6>{{ fileWarn }} Warnings</h6> -->
                          </div>
                        </div>
                        <div class="col-md-4 text-end">
                          <button @click="addNewRow" type="button" class="btn btn-primary">Add Row</button>
                        </div>
                      </div>
                    </div>
                    <div class="col"></div>
                  </div>

                  <!-- Submit Button -->
                  <div class="submit-button">
                    <div class="row">
                      <div class="col-md-6">
                        <!-- <button type="submit" class="btn btn-primary">Save Changes</button> -->
                      </div>
                      <div class="col-md-6 text-end">
                        <button type="submit" class="btn btn-teal">Begin Import</button>
                      </div>
                    </div>
                  </div>
                </Form>
                <div class="row" v-else>
                  <div class="col"></div>
                  <div class="col-md-7 py-3 bg-light">
                    <p class="text-center "><i class="ion-information-circled"></i> Please upload and map a file</p>
                  </div>
                  <div class="col"></div>
                </div>
              </div>
              <div class="row align-items-center py-4 border-top" v-if="Object.keys(assignedData).length > 0">
                <div class="col"></div>
                <div class="col-md-7">
                  <h4>Assigned Data</h4>
                  <table class="table">
                    <thead>
                      <tr>
                        <!-- <th>Entry</th> -->
                        <th class="text-center">Start</th>
                        <th class="text-center">End</th>
                        <th class="text-center">Territory</th>
                        <th class="text-center">Responsible</th>
                        <!-- <th>Batch Number</th> -->
                      </tr>
                    </thead>
                    <tbody v-if="(Object.keys(assignedData.assigned_data).length > 0) || (assignedData.assigned_data.length > 0) ">
                      <tr v-for="(data, index) in assignedData.assigned_data" :key="index">
                        <!-- <td>{{ index }}</td> -->
                        <td class="text-center">{{ data.start }}</td>
                        <td class="text-center">{{ data.end }}</td>
                        <td class="text-center">{{ data.territory !== "" ? data.territory : 'Not Assigned' }}</td>
                        <td class="text-center">{{ data.responsible !== "" ? data.responsible : 'Not Assigned' }}</td>
                        <!-- <td>{{ data.batch_number }}</td> -->
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="5" class="text-center"><i class="ion-information-circled"></i> No data found</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col"></div>
              </div>
            </div>
            <!-- /Prefixes -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  ref,
  onMounted,
  watch,
  computed
} from "vue";
import {
  useStore
} from "vuex";
import {
  useRoute,
  useRouter
} from "vue-router";
import {
  Form,
  ErrorMessage,
  Field
} from "vee-validate";
import * as Yup from "yup";
import { onImportCompleted } from '@/socket';
import AlertPopup from '@/views/layouts/AlertPopup.vue';

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    AlertPopup
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isMapped = ref(false);
    const fileWarn = ref(null);
    const importData = ref({})

    const file_id = ref(route.query.fileId || localStorage.getItem('fileId') || null);
    // Reactive state
    const rows = ref([{
      territory_id: '',
      responsible: '',
      start: '',
      end: '',
      responsibles: []
    }]);

    const Territories = computed(() => store.getters['organisation/getTerritories']);
    // Validation schema using yup
    const schema = Yup.object().shape({
      rows: Yup.array().of(
        Yup.object().shape({
          start: Yup.string().required('Start is required'),
          end: Yup.string().required('End is required'),
          territory_id: Yup.string().required('Territory is required'),
          responsible: Yup.string().required('Responsible is required'),
        })
      ),
    });

    const assignedData = ref({});
    
    const fetchAssignedData = async (id) => {
      const data = await store.dispatch("dataimport/getAssignedData", id);
      assignedData.value = data;
      if ((data.total_rows - data.assgined_rows_count === 0) && (fileWarn.value === 0)) {
        router.push('/data-import/import-data-status');
        isMapped.value = false;
      }
    };

    const getErrorLogLink = (file_id) => {
      return router.resolve({ path: '/data-import/error-logs', query: { fileId: file_id } }).href;
    };
    // Fetch territories on mount
    onMounted(() => {
      onImportCompleted((importDataValue) => {
          importData.value = importDataValue;
        });

      if (file_id.value) {
        fetchAssignedData(file_id.value)
        // store.dispatch('dataimport/getAssignedData', file_id.value);
      }
      store.dispatch('organisation/fetchTerritories');
    });

    // Handle the territory change
    const handleTerritoryChange = async (index) => {
      const selectedTerritoryId = rows.value[index].territory_id;
      if (selectedTerritoryId) {
        try {
          // Fetch the responsible users for the selected territory
          const response = await store.dispatch('organisation/fetchTerritoryCompanyUsers', [selectedTerritoryId]);
          // Only update the responsibles for the current row
          rows.value[index].responsibles = response || [];
          rows.value[index].responsible = ''; // Reset responsible selection
        } catch (error) {
          console.error("Error fetching responsibles:", error);
        }
      }
    };

    // Method to add a new row
    const addNewRow = () => {
      rows.value.push({
        territory_id: '',
        responsible: '',
        start: '',
        end: '',
        responsibles: []
      });
    };

    const handleSubmit = async () => {
      // Build the payload
      const assignData = rows.value.map((row) => ({
        start: row.start,
        end: row.end,
        territory: row.territory_id,
        responsible: row.responsible,
      }));
      // Dispatch API call with payload
      const data = await store.dispatch('dataimport/beginImportData', {
        file_id: file_id.value,
        assign_data: assignData,
      });
      fileWarn.value = data.data.data.warning_count;
      //   fetchAssignedData(file_id.value);
    };

    // Method to delete a row (except the first row)
    const deleteRow = (index) => {
      rows.value.splice(index, 1); // Removes the row at the given index
    };

    watch(importData, () => {
    if (importData.value && importData.value.file_id) {
      fetchAssignedData(file_id.value);
    }
  },
  { immediate: true }
);

    return {
      getErrorLogLink,
      isMapped,
      handleSubmit,
      schema,
      handleTerritoryChange,
      fetchAssignedData,
      file_id,
      Territories,
      rows,
      fileWarn,
      importData,
      addNewRow,
      deleteRow,
      // beginImport,
      assignedData
    }
  }
}
</script>

<style lang="scss" scoped>
.delete_row {
  right: -30px;
  top: 25px;
  width: 25px;
  height: 25px;
  border: 1px solid red;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    margin-left: 1px;
  }
}
</style>
