<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <div class="contact-head">
            <div class="row align-items-center">
              <div class="col-sm-6">
                <ul class="contact-breadcrumb">
                  <li>
                    <router-link to="/crm/reports-list">
                      <i class="ti ti-arrow-narrow-left"></i> Reports
                    </router-link>
                  </li>
                  <li>Edit Reports</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12 col-lg-12">
              <div class="card">
                <div class="card-body settings-form">
                  <!-- Iterate over formGroups if present -->
                  <div class="row align-items-center mb-4" v-if="formData.filters.length > 0">
                    <div class="col-md-6">
                      <h3
                        v-if="!isEditing1"
                        class="col-md-12"
                        @click="toggleEdit"
                      >
                        {{ formData.name }}
                        <small v-if="filterData.name">({{ filterData.name }})</small>
                      </h3>
                      <div v-else class="col-md-6">
                        <select
                          v-model="filterData.name"
                          class="select form-select"
                          @change="handleChange"
                        >
                          <option
                            v-for="(label, value) in settingData.modules"
                            :key="label.id"
                            :id="label.id"
                          >
                            {{ label.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6 text-end ">
                      <router-link
                        :to="{
                          path: '/crm/report-chart',
                          query: {
                            modN: filterData?.name,
                            repN: formData?.name,
                            reportId: route.query?.reportId,
                            moduleId: route.query?.moduleId,
                            type: chart?.type,
                            field_id: chart?.field_id,
                          },
                        }"
                        class="btn btn-light me-3"
                        >Chart</router-link
                      >
                      <button
                        class="btn btn-light report-btn"
                        @click="handleReport"
                      >
                        Report
                      </button>
                      <button
                        type="submit"
                        class="btn btn-primary"
                        @click="handleSubmit"
                        :disabled="toggle"
                      >
                        Update
                      </button>
                    </div>
                    <div class="col-md-6 my-3">
                      <button
                        :class="['btn me-3', { active: !toggle }]"
                        @click="handleToggleFilter"
                      >
                        Data Filter
                      </button>
                      <button
                        :class="['btn', { active: toggle }]"
                        @click="handleToggleChart"
                      >
                        Chart
                      </button>
                    </div>
                  </div>
                  <Form class="toggle-height" v-if="!toggle">
                    <div class="pro-create">
                      <div
                        v-for="(group, groupIndex) in formData.filters"
                        :key="groupIndex"
                        class="mb-3"
                      >
                        <div v-if="groupIndex > 0">
                          <hr />
                          <div class="pb-3"><strong>And Condition</strong></div>
                        </div>
                        <div v-for="(row, rowIndex) in group" :key="rowIndex">
                          <div class="form-row">
                            <div>
                              <div
                                :class="[
                                  'form-wrap',
                                  {
                                    'is-invalid':
                                      !row.field_id && formSubmitted,
                                  },
                                ]"
                              >
                                <treeselect
                                  v-model="row.field_id"
                                  :options="module_data"
                                  :disable-branch-nodes="true"
                                  :show-count="true"
                                  @select="(selectedNode) => handleSelectChange(groupIndex, rowIndex, selectedNode)"
                                  placeholder="Choose field...." />
                              </div>

                              <div
                                v-if="!row.field_id && formSubmitted"
                                class="invalid-feedback"
                              >
                                Field is required.
                              </div>
                            </div>

                            <!-- Condition Dropdown -->
                            <div class="col-md-3">
                              <div class="form-wrap" :class="{
                                'is-invalid': !row.condition && formSubmitted,
                              }">
                                <select v-model="row.condition" class="form-select custom_form_select py-0"
                                  :disabled="!row.field_id">
                                  <option value="">Choose condition...</option>
                                  <option v-for="(
                                      label, value
                                    ) in settingField.critera_options" :key="label" :value="value">
                                    {{ label }}
                                  </option>
                                </select>
                              </div>
                              <!-- Error message for condition -->
                              <div v-if="!row.condition && formSubmitted" class="invalid-feedback">
                                Condition is required.
                              </div>
                            </div>

                            <!-- Value Input Field -->
                            <!-- <div class="col-md-3">
                              <div
                                class="form-wrap"
                                :class="{
                                  'is-invalid': !row.value && formSubmitted,
                                }"
                              >
                                <input
                                  type="text"
                                  class="form-control custom_form_select py-0"
                                  :disabled="!row.field_id"
                                  placeholder="Enter value"
                                  v-model="row.value"
                                />
                              </div>
                              <div
                                v-if="!row.value && formSubmitted"
                                class="invalid-feedback"
                              >
                                Value is required.
                              </div>
                            </div> -->

                            <div class="col-md-3">
                              <div class="form-wrap" :class="{
                                  'is-invalid': !row.value && formSubmitted,
                                }">
                                <div v-if="
                                  row.fieldType === 'dropdown' &&
                                  row.dropdown_options
                                ">
                                  <select class="form-select form-control custom_form_select py-0" v-model="row.value">
                                    <option value="">-----Select-----</option>
                                    <option v-for="option in row.dropdown_options" :key="option.value || option.id"
                                      :value="option.value || option.id">
                                      {{ option.text || option.name || option.country_name }}
                                    </option>
                                  </select>
                                </div>
                                <div v-else-if="row.fieldType === 'text' || row.fieldType === 'none'">
                                  <input type="text" placeholder="Enter value"
                                    class="form-control custom_form_select py-0" v-model="row.value
                                      " />
                                </div>

                                <div v-else-if="row.fieldType === 'checkbox'" class="py-3-5">
                                  <input type="checkbox" class="form-check custom_form_select" :true-value="1"
                                    :false-value="0" v-model="row.value
                                      " />
                                </div>

                                <div v-else-if="row.fieldType === 'textarea'">
                                  <textarea class="form-control custom_form_select py-0" v-model="row.value
                                    "></textarea>
                                </div>
                                <div v-else-if="row.fieldType === 'datepicker'">
                                  <div class="icon-form">
                                    <date-picker textInput placeholder="" v-model="row.value
                                      " class="datetimepicker form-control custom_form_select py-0" :editable="true"
                                      :clearable="true" :input-format="dateFormat" />
                                    <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                                  </div>
                                </div>
                              </div>
                              <div
                                v-if="!row.value && formSubmitted"
                                class="invalid-feedback"
                              >
                               Value is required.
                              </div>
                            </div>


                            <!-- Prompt User Checkbox & Remove Row Button -->
                            <div class="col-md-3">
                              <div class="d-flex justify-content-around">
                                <div class="check-input">
                                  <input type="checkbox" class="form-check-input" :checked="row.prompt_user === 1"
                                    :disabled="!row.field_id" @change="togglePromptUser(row)" />
                                  <label for="checkbox">Prompt user</label>
                                </div>

                                <!-- Remove Row Button -->
                                <button v-if="
                                  formData.filters.length > 1 ||
                                  formData.filters.some((g) => g.length > 1)
                                " @click.prevent="
                                  removeRow(groupIndex, rowIndex)
                                  " class="cross-btn">
                                  <i data-v-505522fa="" class="ion-close-round text-danger" data-bs-toggle="tooltip"
                                    title="Delete Row"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mt-3">
                          <button class="btn btn-light me-3" @click.prevent="addRowToGroup(groupIndex)">
                            ➕ Or
                          </button>
                          <button class="btn btn-light" v-if="groupIndex === formData.filters.length - 1"
                            @click.prevent="addGroup">
                            ➕ And
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>

                  <Form class="toggle-height" v-if="toggle">
                    <div class="pro-create">
                      <div class="mb-3">
                        <div class="row">
                          <div class="col-md-6 d-flex align-items-center">
                            <label class="col-md-3 mb-0">Split by data field:</label>
                            <div class="col-md-9">
                              <treeselect v-model="chart.field_id" :options="module_data" :disable-branch-nodes="true"
                                :show-count="true" placeholder="Choose field...." />
                            </div>
                          </div>

                          <div class="col-md-6 d-flex align-items-center">
                            <label class="col-md-3 mb-0">Chart type:</label>
                            <div class="col-md-9">
                              <select v-model="chart.type" class="form-select">
                                <option value="column" selected>Columns</option>
                                <option value="line">Line</option>
                                <option value="pie">Pie Chart</option>
                                <option value="bar">Funnel</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div ref="addReportsModal" class="modal fade" id="confirm_modal" tabindex="-1" role="dialog"
    aria-labelledby="addReportsLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" id="close-btn" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body confirm">
          <h3>Confirm Change Overview Type ?</h3>
          <p>Can remove data filters and displayed data</p>
          <button class="btn btn-primary confirm-btn" @click="confirm_change">
            Confirm
          </button>
          <button class="btn btn-outline-secondary cancel-btn" @click="cancel_change">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import "bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Modal } from "bootstrap";
import { ref, onMounted, onBeforeUnmount, watch, nextTick } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    Treeselect,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const report_id = ref(null);
    const isEditing = ref(false);
    const filterData = ref({});
    const formData = ref({
      filters: [],
    });
    const settingData = ref([]);
    const selectedData = ref([]);

    const toggle = ref(false);
    const chart = ref({
      field_id: null,
      type: "column",
    });
    const module_data = ref([]);

    const value = ref(null);

    const isEditing1 = ref(false);
    const selectedOption = ref("User");

    const toggleEdit = async () => {
      isEditing1.value = !isEditing1.value;
      const settingsData1 = await store.dispatch("report/fetchDropdownOptions");
      settingData.value = settingsData1;
    };

    const filters = ref({
      field_id: null,
      condition: "",
      value: "",
      prompt_user: false,
      fieldType: "none"
    });

    const settingField = ref({});
    const selectedField = ref("");
    const selectedCondition = ref("");
    const formSubmitted = ref(false);
    const selectedModule = ref(null);

    const initialModuleValue = ref("");

    const handleSubmit = async (flag) => {
      // event.preventDefault();
      formSubmitted.value = true;
      if (isFormValid()) {

        const updatedData = {
          ...formData.value,
          filters: formData.value.filters.map(group =>
            group.map(({ fieldType, dropdown_options, ...rest }) => rest)
          ),
        };

        const response = await store.dispatch(
          "report/saveReportData",
          updatedData
        );
        if (response.status) {
          // formData.value = { filters: [] };
          const reportId = route.query.reportId;
          const moduleId = route.query.moduleId;
          router.push({
            path: "/crm/report-edit",
            query: {
              reportId: reportId,
              moduleId: moduleId,
            },
          });
          if (flag) {
            await fetchReportData(reportId);
          }
        }
      }
    };

    const isFormValid = () => {
      return formData.value.filters.every((group) =>
        group.every((row) => !!row.value && !!row.condition)
      );
    };

    const togglePromptUser = (row) => {
      row.prompt_user = row.prompt_user === 1 ? 0 : 1;
    };

    const fetchModuleData = async (id) => {
      try {
        const data = await store.dispatch("report/fetchModuleData", id);
        module_data.value = data;
        chart.value = {
          field_id: module_data.value?.[0]?.children?.[0]?.id,
          type: "column",
        };
      } catch (error) {
        console.error("Error fetching report data:", error);
      }
    };

    const getModuleFields = (moduleId) => {
      const module = module_data.value.find((mod) => mod.id === moduleId);
      return module ? module.module_fields : [];
    };

    const fetchFieldType = (id) => {
      let result = null;

      module_data.value.forEach((module) => {
        const found = module.children.find((item) => item.id === id);
        if (found) {
          result = { fieldType: found.field_type, dropdown_options: JSON.parse(JSON.stringify(found.dropdown_options)) };
        }
      });

      return result;
    };

    const fetchReportData = async (id) => {
      try {
        formSubmitted.value = false;
        const data = await store.dispatch("report/fetchReportData", id);
        const updatedFilters = data.filters.map((group) => {
          return group.map((item) => {
            const fields = fetchFieldType(item.field_id);
            return {
              ...item,
              value: item.value && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(item.value) ? new Date(item.value) : item.value || null,
              fieldType: fields.fieldType,
              dropdown_options: fields.dropdown_options,
            };
          });
        });

        const updatedData = {
          ...data,
          filters: updatedFilters,
        };

        formData.value = updatedData || { filters: [] };
        if (formData.value.filters.length === 0) {
          formData.value.filters.push([
            {
              field_id: null,
              condition: "",
              value: "",
              prompt_user: false,
              fieldType: "none"
            },
          ]);
        }

        const settingsData = await store.dispatch(
          "report/fetchDropdownOptions"
        );
        settingField.value = settingsData;
        const value = settingsData.modules.filter((item) => {
          return item.id === formData.value.module_id;
        })[0];
        filterData.value = { ...value };

        initialModuleValue.value = { ...value };
        await store.dispatch("report/saveReportDetails", {
          name: formData.value.name,
          module_name: initialModuleValue.value.name,
        });
        await nextTick();
      } catch (error) {
        console.error("Error fetching report data:", error);
      }
    };

    const addRowToGroup = (groupIndex) => {
      formSubmitted.value = false;
      formData.value.filters[groupIndex].push({
        field_id: null,
        condition: "",
        value: "",
        prompt_user: false,
        fieldType: "none"
      });
    };

    const addGroup = () => {
      formSubmitted.value = false;
      formData.value.filters.push([
        {
          field_id: null,
          condition: "",
          value: "",
          prompt_user: false,
          fieldType: "none"
        },
      ]);
    };

    const removeRow = (groupIndex, rowIndex) => {
      const row = formData.value.filters[groupIndex][rowIndex];
      if (formData.value.filters[groupIndex].length > 1) {
        formData.value.filters[groupIndex].splice(rowIndex, 1);
      } else if (
        formData.value.filters[groupIndex].length === 1 &&
        formData.value.filters.length > 1
      ) {
        formData.value.filters.splice(groupIndex, 1);
      } else {
        const fieldsAreEmpty = !row.field_id && !row.condition && !row.value;
        if (!fieldsAreEmpty) {
          row.field_id = null;
          row.condition = "";
          row.value = "";
          row.prompt_user = 0;
          row.fieldType = "none"
        }
      }
      formSubmitted.value = false;
    };

    const handleSelectChange = (groupIndex, rowIndex, selectedNode) => {
      formData.value.filters[groupIndex][rowIndex].fieldType = selectedNode.field_type;
      formData.value.filters[groupIndex][rowIndex].dropdown_options = selectedNode.dropdown_options;
      const row = formData.value.filters[groupIndex][rowIndex];
      if (row) {
        const criteriaOptions = settingField.value.critera_options;
        const key = Object.keys(criteriaOptions).find(
          (optionKey) => criteriaOptions[optionKey] === "Equal ( = )"
        );

        if (key) {
          row.condition = key;
        }
      }
    };

    const handleChange = async (event) => {
      event.preventDefault();
      const selectedOption = event.target.options[event.target.selectedIndex];
      const selectedId = selectedOption.id;
      const selectedValue = selectedOption.value;
      const reportId = route.query.reportId;
      selectedData.value = {
        id: reportId,
        name: formData.value.name,
        module_id: selectedId,
        filters: [],
      };

      const modal = new Modal(document.getElementById("confirm_modal"));
      modal.show();
    };

    const confirm_change = async () => {
      const response = await store.dispatch(
        "report/saveReportData",
        selectedData.value
      );
      if (response.status) {
        document.getElementById("close-btn").click();
        isEditing1.value = !isEditing1.value;
        const reportId = route.query.reportId;
        router.push({
          path: "/crm/report-edit",
          query: {
            reportId: reportId,
            moduleId: selectedData.value.module_id,
          },
        });
      const field = document.getElementsByClassName("vue-treeselect__single-value");
      field[0].innerHTML="Choose field....";
      }
    };

    const cancel_change = async () => {
      document.getElementById("close-btn").click();

      filterData.value = { ...initialModuleValue.value };

      isEditing1.value = !isEditing1.value;
      await store.dispatch("report/saveReportDetails", {
        name: formData.value.name,
        module_name: filterData.value.name,
      });
    };

    const handleReport = async () => {
      await handleSubmit(false);
      const reportId = route.query.reportId;
      const moduleId = route.query.moduleId;
      router.push({
        path: "/crm/overview/report",
        query: {
          modN: filterData.value.name,
          repN: formData.value.name,
          reportId: reportId,
          moduleId: moduleId,
        },
      });
    };

    const handleToggleFilter = () => {
      toggle.value = false;
    };

    const handleToggleChart = () => {
      toggle.value = true;
    };

    watch(async () => {
      const reportId = route.query.reportId;
      const moduleId = route.query.moduleId;
      await fetchModuleData(moduleId);
      await fetchReportData(reportId);
    });

    return {
      handleSubmit,
      report_id,
      isEditing,
      formData,
      store,
      route,
      router,
      addRowToGroup,
      addGroup,
      removeRow,
      handleSelectChange,
      settingField,
      selectedField,
      selectedCondition,
      filters,
      formSubmitted,
      isFormValid,
      togglePromptUser,
      toggleEdit,
      selectedOption,
      isEditing1,
      settingData,
      filterData,
      handleChange,
      confirm_change,
      cancel_change,
      selectedData,
      handleReport,
      module_data,
      fetchModuleData,
      selectedModule,
      getModuleFields,
      value,
      handleToggleFilter,
      handleToggleChart,
      toggle,
      chart,
      fetchFieldType
    };
  },
};
</script>

<style scoped="true" lang="scss">
.is-invalid {
  margin-bottom: 5px;

  input,
  select {
    border: 1px solid #dc3545;
  }
}

.form-row {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

select {
  padding: 5px;
  width: 100%;
}

.close-btn {
  border: 1px solid red;
  background: #fff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}

.check-input {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

label {
  margin-left: 5px;
}

hr {
  border-top: 1px solid #ccc;
  margin-top: 10px;
  margin-bottom: 10px;
}

.select {
  text-transform: capitalize;
}

.confirm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.confirm-btn,
.cancel-btn {
  width: 70%;
  margin-bottom: 20px;
}

.custom_form_select {
  min-height: 37px;
  max-height: 37px;
  box-shadow: none;
  border: 1px solid #ddd;
}

.report-btn {
  margin-right: 20px;
  padding: 9px 19px;
}

.cross-btn {
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #fc0027;
}
.icon-form .v3dp__datepicker .v3dp__input_wrapper {
    height: 37px;
}

.py-3-5{
 padding-top: 0.75rem;
 padding-bottom: 0.75rem
}

</style>
