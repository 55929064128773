<template>
  <div>
    <div class="view-header">
      <h4>{{ orgActivities.length }} Activities</h4>
      <ul>
        <li>
          <router-link
            class="btn btn-primary p-1"
            :to="{
                    path: '/crm/create-activity',
                    query: { add: 'org-details',
                      org_Id: orgId, territory_id: ter_Id
                    },
                  }"
          >
            <i class="ti ti-plus"></i>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="notes-activity" v-if="orgActivities.length > 0">
      <div class="calls-box" v-for="item in orgActivities" :key="item.id">
        <div class="caller-info">
          <div class="calls-user">
            <div class="opp-icon me-2">
              <i class="ti ti-file-check"></i>
            </div>
            <div>
              <p title="Activity date and time">
                <router-link
                  class="text-teal"
                  :to="{
                    path: '/crm/edit-activity',
                    query: {
                      add: 'org-details',
                      territory_id: ter_Id,
                      org_Id: orgId,
                      actId: item.id,
                    },
                  }"
                >
                  {{ formatDate(item.date) }} {{ item.time }}
                </router-link>
              </p>
              <h6 title="Contract number">{{ item.status }}</h6>
              <p title="Contract Responsible" class="org-title">
                {{ item.activity_responsible }}
              </p>
            </div>
          </div>
          <div class="calls-action">
            <router-link
              class="text-teal"
              :to="{
                path: '/crm/edit-activity',
                query: {
                  add: 'org-details',
                  territory_id: ter_Id,
                  org_Id: orgId,
                  actId: item.id,
                },
              }"
            >
              <i class="ti ti-edit text-blue"></i>
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <p><b>Action : </b> {{ item.action }}</p>
          </div>
          <div class="col-md-4">
            <p>
              <b>Status : </b
              >{{ item.status }}
            </p>
          </div>
          <div class="col-md-4">
            <p class="org-title">
              <b>Activity Contact :</b> {{ item.activity_contact }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h6>Description :</h6>
            <p>
              {{ item.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row notes-activity" v-else>
      <div class="col-12 bg-light">
        <p class="text-center">
          <i class="ion-information-circled"></i> No data found
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import apiClient from "@/axios";

export default {
  props: {
    terId: {
      type: String,
    },
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const orgId = ref(null);
    const ter_Id = ref(route.query.territory_id);
    const orgActivities = ref([]);
    const isActivitiesTabActive = ref(false); // Flag to track tab visibility

    const fetchActivities = async (id) => {
      if (!isActivitiesTabActive.value) {
        try {
          const response = await apiClient.get(
            `/activities?organization_id=${id}`
          );
          orgActivities.value = response.data.data;

          isActivitiesTabActive.value = true;
        } catch (error) {
          console.error("Error fetching opportunity data:", error);
        }
      }
    };
     // Function to convert date format
   const formatDate = (date) => {
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    };
    onMounted(() => {
      orgId.value = route.query.org_Id;
      const personsTab = document.querySelector(
        '[data-bs-target="#activities"]'
      );

      personsTab.addEventListener("shown.bs.tab", () => {
        fetchActivities(orgId.value); // Call the API when the Persons tab is active
      });
    });

    return {
      fetchActivities,
      orgActivities,
      formatDate,
      orgId,
      ter_Id,
      isActivitiesTabActive,
    };
  },
};
</script>

<style lang="scss" scoped>
.opp-icon {
  background-color: #e41f07;
  color: #fff;
  border: 1px solid #e41f07;
  text-align: center;
  width: 25px;
  height: 25px;
  border-radius: 10px;
}
.org-title{
  text-transform: capitalize;
}
</style>
