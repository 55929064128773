<template>
<a-table class="stripped table-hover" :columns="columns" :data-source="data" :pagination="pagination" :row-selection="rowSelection" @change="handleTableChange">
  <!-- Template for table body cells -->
  <template #bodyCell="{ column, record }">
    <template v-if="column.key === 'Name'">
      <h2 class="table-avatar d-flex align-items-center">
        <router-link :to="{ path: '/crm/organisation-details', query: { ter_Id: record.ter_Id, org_Id: record.key } }" class="profile-split d-flex flex-column">
          {{ record.organisation_name }}
        </router-link>
      </h2>
    </template>
    <template v-else-if="column.key === 'Status'">
      <div>
        <span :class="record.statusclass">{{ record.status }}</span>
      </div>
    </template>
    <template v-else-if="column.key === 'Phone'">
      <div>
        {{ record.phone }}
      </div>
    </template>
    <template v-else-if="column.key === 'action'">
      <div class="icon-field">

        <router-link :to="{ path: '/crm/create-organisation', query: { org_Id: record.key }}" title="Edit" class="btn table_action_btn"><i class="ti ti-edit"></i> Edit</router-link>

        <router-link :to="{ path: '/crm/create-person', query: { org_Id: record.key } }" class="btn table_action_btn ms-2"><i class="ion-person"></i> Add Person</router-link>
      </div>

    </template>
  </template>
</a-table>

</template>

<script>
import {
  ref,
  onMounted,
  watchEffect
} from 'vue';
import {
  useStore
} from "vuex";
import {
  useRouter
} from 'vue-router';

const columns = [{
    title: "Organisation Name",
    dataIndex: "organisation_name",
    key: "OrgName",
    sorter: {
      compare: (a, b) => {
        a = a.organisation_name.toLowerCase();
        b = b.organisation_name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Postcode",
    dataIndex: "postcode",
    sorter: {
      compare: (a, b) => {
        a = a.postcode;
        b = b.postcode;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Contact Name",
    dataIndex: "contact_name",
    key: "contact_name",
    sorter: {
      compare: (a, b) => {
        a = a.contact_name.toLowerCase();
        b = b.contact_name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Contact Email",
    dataIndex: "contact_email",
    sorter: {
      compare: (a, b) => {
        a = a.contact_email.toLowerCase();
        b = b.contact_email.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Contact Number",
    dataIndex: "contact_number",
    sorter: {
      compare: (a, b) => {
        a = a.contact_number;
        b = b.contact_number;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.status;
        b = b.status;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  }
];

export default {
  props: {
    searchQuery: String,
    filters: Object,
  },
  data() {
    return {
      columns,
      data: [],
      rowSelection: {
        onChange: () => {},
        onSelect: () => {},
        onSelectAll: () => {},
      },
    };
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();
    const data = ref([]);
    const pagination = ref({
      current: 1,
      pageSize: 10,
      total: 0,
    });

    const fetchLeadsData = async () => {
      const params = {
        page: pagination.value.current,
        pageSize: pagination.value.pageSize,
      };
      const response = await store.dispatch('leads/fetchLeads', params);

      if (response?.data) {
        data.value = response.data.data.map((lead) => ({
          key: lead.id,
          organisation_name: lead.company,
          contact_number: lead.phone,
          contact_email: lead.email,
          contact_name: lead.name,
          postcode: lead.postcode,
          phone: formatPhoneNumber(lead.phone),
          status: lead.status,
        }));
        pagination.value.total = response.data.total;
      }
    };
    const formatPhoneNumber = (number) => {
      if (!number) {
        return ''; // Return an empty string if the input is empty or undefined
      }

      const cleaned = number.replace(/\D/g, '');

      // Check if the cleaned number is valid (e.g., 10 digits)
      if (cleaned.length === 10) {
        return `(${cleaned.substring(0, 3)}) ${cleaned.substring(3, 6)}-${cleaned.substring(6)}`;
      } else {
        // Optionally, return a placeholder or the original input if not valid
        return number; // Or return a default placeholder like 'Invalid number'
      }
    };

    const handleTableChange = (newPagination) => {
      pagination.value.current = newPagination.current;
      pagination.value.pageSize = newPagination.pageSize;
      fetchLeadsData();
    };

    watchEffect(() => {
      fetchLeadsData();
    });

    return {
      data,
      pagination,
      fetchLeadsData,
      handleTableChange,
    };
  },
};
</script>
