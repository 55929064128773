// src/store/modules/org.js
import apiClient from '@/axios';
import router from '@/router';
import { message } from 'ant-design-vue';

const state = {
  postCreate: [],
  createMode: [],
  validateData: [],
  mappingData: [],
  mappedData: [],
  resUsers: [],
  importList: [],
  postImportList: [],
  assignedData: {},
  beginData: [],
};

const getters = {
  getcreateMode: (state) => state.createMode,
  getresUsers: (state) => state.resUsers,
  getmappingData: (state) => state.mappingData,
  getvalidateData: (state) => state.validateData,
  getpostCreate: (state) => state.postCreate,
  getimportList: (state) => state.importList,
  getpostImportList: (state) => state.postImportList,
  getbeginData: (state) => state.beginData,
  getassignedData: (state) => state.assignedData,
  getmappedData: (state) => state.mappedData,
};

const mutations = {
  SET_CREATE_MODE(state, data) {
    state.createMode = data;
  },
  SET_RES_USERS(state, data) {
    state.resUsers = data;
  },
  SET_MAPPING_DATA(state, data) {
    state.mappingData = data;
  },
  SET_MAPPED_DATA(state, data) {
    state.mappedData = data;
  },
  SET_VALIDATE_DATA(state, data) {
    state.validateData = data;
  },
  SET_POST_CREATE_MODE(state, data) {
    state.postCreate = data;
  },
  SET_IMPORT_LIST(state, data) {
    state.importList = data;
  },
  SET_POST_MAPPING_DATA(state, data) {
    state.postImportList = data;
  },
  SET_ASSIGNED_DATA(state, data) {
    state.assignedData = data;
  },
  SET_BEING_DATA(state, data) {
    state.beginData = data;
  }
};

const actions = {
  async fetchImportLists({ commit }) {
    try {
      const response = await apiClient.get('/import/list');
      if (response.status) {
        commit('SET_IMPORT_LIST', response.data.data);
        return response.data.data;
      }
    } catch (error) {
      console.error('Error fetching import:', error);
    }
  },
  async fetchValidateData({ commit }, id) {
    try {
      const response = await apiClient.get(`/import/validate/${id}`);
      commit('SET_VALIDATE_DATA', response.data.data);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching contract data:', error);
    }
  },
  async fetchMappingData({ commit }, id) {
    try {
      const response = await apiClient.get(`/import/${id}/mappings`);
      commit('SET_MAPPING_DATA', response.data.data);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching contract data:', error);
    }
  },
  async fetchMappedData({ commit }, id) {
    try {
      const response = await apiClient.get(`/import/${id}/use-mapping`);
      commit('SET_MAPPED_DATA', response.data.data);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching mapped data:', error);
    }
  },
  async postMappingData({ commit }, payload) {
    try {
      const response = await apiClient.post(`/import/${payload.file_id}/mappings`, payload);
      commit('SET_POST_MAPPING_DATA', response.data);
      return response.status;
    } catch (error) {
      console.error('Error SET_POST_MAPPING_DATA:', error);
    }
  },
  async beginImportData({ commit }, payload) {
    try {
      const response = await apiClient.post(`/import/${payload.file_id}/begin-import`, payload);
      commit('SET_BEING_DATA', response);
      return response;
    } catch (error) {
      console.error('Error fetching contract data:', error);
    }
  },
  async getAssignedData({ commit }, id) {
    try {
      const response = await apiClient.get(`/import/${id}/assign-data`);
      commit('SET_ASSIGNED_DATA', response.data.data);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching contract data:', error);
    }
  },
  async fetchResponsibleUsers({ commit }) {
    try {
      const response = await apiClient.get('/import/responsible-users');
      commit('SET_RES_USERS', response.data.data);
    } catch (error) {
      console.error('Error fetching responsible-users:', error);
    }
  },
  async fetchCreateMode({ commit }, id) {
    try {
      const response = await apiClient.get(`/import/${id}/create-mode`);
      commit('SET_CREATE_MODE', response.data.data);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching responsible-users:', error);
    }
  },
  async createMode({ commit }, form) {
    try {
      const response = await apiClient.post(`/import/${form.id}/create-mode`, form);
      commit('SET_POST_CREATE_MODE', response);
      return response;
    } catch (error) {
      console.error('Error fetching responsible-users:', error);
    }
  },
  async uploadImportFile({ dispatch }, form) {
    try {
      const response = await apiClient.post(
        '/import/upload',
        form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      );
      return response.data;
    } catch (error) {
      console.error('Error saving import data:', error);
    }
  },
  async validateFile({ commit }, form) {
    try {
      const response = await apiClient.post(
        `/import/validate/${form.id}`,
        form,
      );
      return response.data;
    } catch (error) {
      console.error('Error updating contract data:', error);
    }
  },
  async fetchWarningErrors({ commit }, id) {
    try {
      const response = await apiClient.get(`/import/${id}/error-list`);
      // commit('SET_BEING_DATA', response);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching error-list data:', error);
    }
  },
  async downloadErrorsFile({ commit }, id) {
    try {
      const response = await apiClient.get(`/import/download/error-file/${id}`);
      // commit('SET_BEING_DATA', response);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching error-file data:', error);
    }
  },
  async deleteImportFile({ commit }, id) {
    try {
      const response = await apiClient.delete(`/import/${id}/delete`);
      // commit('SET_BEING_DATA', response);
      return response.data;
    } catch (error) {
      console.error('Error delete data:', error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
