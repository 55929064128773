<template>
<layouts-header></layouts-header>
<layouts-sidebar></layouts-sidebar>
<div class="page-wrapper">
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <!-- Page Header -->
        <page-header :title="title" />
        <!-- /Page Header -->

        <!-- Settings Menu -->
        <settings-tabs></settings-tabs>
        <!-- /Settings Menu -->

        <div class="row">
          <general-sidebar></general-sidebar>

          <div class="col-xl-9 col-lg-12">
            <!-- Settings Info -->
            <div class="card">
              <div class="card-body">
                <div class="settings-header">
                  <h4>Plan Types</h4>
                </div>
                <div class="settings-form">
                  <Form @submit="saveProfile" :validation-schema="schema">
                    <div class="profile-details">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">
                              Gas Minimum <span class="text-danger">*</span>
                            </label>
                            <Field name="gas_minimum" as="input" type="text" class="form-control" v-model="form.gas_minimum" />
                            <ErrorMessage name="gas_minimum" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label"> Gas Maximum </label>
                            <Field name="gas_maximum" as="input" type="text" class="form-control" v-model="form.gas_maximum" />
                            <ErrorMessage name="gas_maximum" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label"> Electric Minimum </label>
                            <Field name="elec_minimum" as="input" type="text" class="form-control" v-model="form.elec_minimum" />
                            <ErrorMessage name="elec_minimum" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <label class="col-form-label">Electric Maximum</label>
                            <Field name="elec_maximum" as="input" v-model="form.elec_maximum" type="elec_maximum" class="form-control" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="submit-button text-end">
                      <button type="submit" class="btn btn-primary">
                        Save Changes
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            <!-- /Settings Info -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  ref,
  onMounted,
} from "vue";
import {
  useStore
} from "vuex";
import {
  useRoute,
  useRouter
} from "vue-router";

import {
  Form,
  Field,
  ErrorMessage,
} from "vee-validate";
export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      title: "Settings",
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const form = ref({
      gas_minimum: [],
      gas_maximum: [],
      elec_minimum: [],
      elec_maximum: []
    });

    const saveProfile = async () => {
      try {
        await store.dispatch("auth/updatePlanTypes", form.value);
      } catch (error) {
        console.error(error);
      }
    };

    onMounted(async () => {});

    return {
      form,
      saveProfile,
    };
  },
};
</script>
