<template>
<layouts-header></layouts-header>
<layouts-sidebar></layouts-sidebar>
<div class="page-wrapper">
    <div class="content">
        <div class="row">
            <div class="col-md-12">
                <!-- Page Header -->
                <page-header :title="title" />
                <!-- /Page Header -->

                <!-- Settings Menu -->
                <settings-tabs></settings-tabs>
                <!-- /Settings Menu -->

                <div class="row">
                    <div class="col-xl-12 col-lg-12">
                        <div class="contact-head">
                            <div class="row align-items-center">
                                <div class="col-sm-6">
                                    <ul class="contact-breadcrumb">
                                        <li>
                                            <router-link to="/pipelines/pipelines-list"><i class="ti ti-arrow-narrow-left"></i>Pipelines</router-link>
                                        </li>
                                        <li>{{ isEditing ? 'Edit Pipeline' : 'Create New Pipeline' }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!-- Company Settings -->
                        <div class="card">
                            <div class="card-body settings-form">
                                <div class="row">
                                    <div class="col-md-5 col-sm-4">
                                        <h4>{{ isEditing ? 'Edit Pipeline' : 'Create Pipeline' }}</h4>
                                    </div>
                                    <div class="col-md-7 col-sm-8">
                                    </div>
                                </div>
                                <Form @submit="registerPipeline" :validation-schema="schema">
                                    <div class="settings-sub-header">
                                    </div>
                                    <div class="profile-details">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <div class="form-wrap">
                                                    <label class="col-form-label">Pipeline Name <span class="text-danger">*</span></label>
                                                    <Field name="name" as="input" type="text" class="form-control" v-model="form.name" />
                                                    <ErrorMessage name="name" class="text-danger" />
                                                    <span class="text-danger" v-if="
                                                            apiErrors &&
                                                            apiErrors['name'] &&
                                                            apiErrors['name'].length
                                                        ">
                                                            {{ apiErrors["name"][0] }}
                                                        </span>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-wrap">
                                                    <label class="col-form-label">Win probability % <span class="text-danger">*</span></label>
                                                    <Field as="select" name="win_probability" class="form-select" v-model="form.win_probability">
                                                        <option value="">-----Select-----</option>
                                                        <option v-for="res in options.op_win_probability" :key="res.value" :value="res.value">
                                                            {{ res.text }}
                                                        </option>
                                                    </Field>
                                                    <ErrorMessage name="win_probability" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-wrap">
                                                    <label class="col-form-label">Day <span class="text-danger">*</span></label>
                                                    <Field name="follow_up_days" min="1"  as="input" v-model="form.follow_up_days" type="number" class="form-control" />
                                                    <ErrorMessage name="follow_up_days" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="submit-button text-end">
                                        <router-link class="btn btn-light" to="/pipelines/pipelines-list">
                                            Back</router-link>
                                        <button type="submit" class="btn btn-primary">{{ isEditing ? 'Update' : 'Create' }}</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <!-- /Company Settings -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ref,
    watch,
    onMounted,
    computed,
} from "vue";
import apiClient from "@/axios";
import {
    useStore
} from "vuex";
import {
    useRoute,
    useRouter
} from 'vue-router';
import Multiselect from 'vue-multiselect';
import $ from 'jquery';

import {
    Form,
    Field,
    ErrorMessage,
    useForm
} from "vee-validate";
import * as Yup from "yup";
import {
    message
} from "ant-design-vue";
export default {
    components: {
        Form,
        Field,
        ErrorMessage,
        Multiselect
    },
    data() {
        return {
            title: "Settings",
            availStatus: [
                'No', 'Yes'
            ],
            loginSmsCode: ['always']
        };
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        const isEditing = ref(false);
        const options = computed(
            () => store.getters["opportunity/getoppDropdowns"]
        );
        if (route.query.userId) {
            isEditing.value = true;
        }
        const apiErrors = ref(null);

        const schema = Yup.object().shape({
            name: Yup.string()
                .required("Pipeline name is required"),
            win_probability: Yup.string()
                .required("Win Probability is required"),
            follow_up_days: Yup.number().positive('Follow-up days must be greater than zero').required('This is required.'),
        });

        const form = ref({
            id: null,
            name: '',
            win_probability: '',
            follow_up_days: ''

        });

        const fetchUserData = async (id) => {
            try {
                const response = await apiClient.get(`/pipeline-level/view/${id}`);
                Object.assign(form.value, response.data.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
        const registerPipeline = async () => {
            const data = await store.dispatch("users/createPipeline", form.value);
            console.log(data);

            if (data.status === 200) {
                message.success(data.data.message)
                router.push('/pipelines/pipelines-list');
            } else if (!data.status)
                apiErrors.value = data.errors;
        };

        onMounted(async () => {
            await store.dispatch('opportunity/fetchOpportunityOptions');
            const userId = route.query.userId;
            if (userId) {
                fetchUserData(userId);
            }
            // fetchResponsibles();
        });

        return {
            schema,
            isEditing,
            options,
            form,
            registerPipeline,
            apiErrors
        };
    },
};
</script>

<style>
.profile-upload-img {
    width: 150px;
    height: 150px;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
}

.color-selectors {
    display: flex;
    gap: 10px;
}

.color-field {
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 4px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid transparent;
    transition: border-color 0.3s;
}

.color-field:hover {
    border-color: #ccc;
}

.color-field .checkmark {
    font-size: 20px;
    color: white;
}

.color-field.selected {
    border-color: #000;
}
</style>
