<template>
    <layouts-header></layouts-header>
    <layouts-sidebar></layouts-sidebar>
    <div class="page-wrapper">
        <div class="content">
            <div class="row">
                <div class="col-md-12">
                    <div class="contact-head">
                        <div class="row align-items-center">
                            <div class="col-sm-6">
                                <ul class="contact-breadcrumb">
                                    <li>
                                        <router-link to="/crm/organisation-list"><i
                                                class="ti ti-arrow-narrow-left"></i>Organisations</router-link>
                                    </li>
                                    <li v-if="isEditing">
                                        <router-link :to="{
                                            path: '/crm/organisation-details',
                                            query: { ter_Id: formData.territory_id, org_Id: org_id },
                                        }">Main view</router-link>
                                    </li>
                                    <li>
                                        {{ isEditing ? "Edit Organisation" : "Create New Organisation" }}
                                    </li>
                                </ul>
                            </div>
                            <div class="col-sm-6 text-end">
                                <ul class="contact-breadcrumb">
                                    <li>
                                        <router-link :to="{
                                            path: '/crm/person-list',
                                            query: { org_Id: org_id },
                                        }"><span style="font-weight: 600; font-size: 20px;">Persons<i
                                                    class="ti ti-arrow-narrow-right ms-1"></i></span></router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12 col-lg-12">
                            <!-- Company Settings -->
                            <div class="card">
                                <div class="card-body settings-form">
                                    <!-- <form @submit.prevent="handleSubmit" class="toggle-height"> -->
                                    <Form @submit="handleSubmit" :validation-schema="schema" class="toggle-height">
                                        <div class="submit-button text-end mb-3">
                                            <button type="button" class="btn btn-light"
                                                @click="handleCancel">Cancel</button>
                                            <button type="submit" class="btn btn-primary">
                                                {{ isEditing ? "Update" : "Create" }}
                                            </button>
                                        </div>
                                        <div class="settings-sub-header">
                                            <div class="row align-items-center">
                                                <div class="col-6">
                                                    <h4>
                                                        {{ isEditing ? "Edit Organisation" : "Create Organisation" }}
                                                    </h4>
                                                </div>
                                                <div class="col-6 text-end" v-if="isEditing">
                                                    <!-- <router-link class="btn btn-primary me-2" :to="{
                                                        path: '/crm/organisation-details',
                                                        query: {ter_Id:formData.territory_id, org_Id: org_id }
                                                    }">
                                                        Main View
                                                    </router-link> -->
                                                    <router-link class="btn btn-primary" :to="{
                                                        path: '/crm/create-person',
                                                        query: { org_Id: org_id },
                                                    }">
                                                        <i class="ti ti-square-rounded-plus me-1"></i>Add Person
                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="pro-create">
                                            <div class="row">
                                                <div class="col-md-12" v-if="isEditing">
                                                    <div class="form-wrap">
                                                        <div class="profile-upload">
                                                            <div class="profile-upload-img">
                                                                <span v-if="!imagePreview"><i
                                                                        class="ti ti-photo"></i></span>
                                                                <img v-if="imagePreview" :src="imagePreview"
                                                                    class="img-fluid" />
                                                                <button v-if="imagePreview" type="button"
                                                                    class="profile-remove" @click="removeImage">
                                                                    <i class="feather-x"></i>
                                                                </button>
                                                            </div>
                                                            <div class="profile-upload-content">
                                                                <label class="profile-upload-btn">
                                                                    <i class="ti ti-file-broken"></i> Upload File
                                                                    <input type="file" class="input-img"
                                                                        @change="handleImageUpload" />
                                                                </label>
                                                                <p>
                                                                    Upload Logo of your organisation (In jpeg, png,
                                                                    jpg).
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <span class="text-danger" v-if="
                                                            apiErrors && apiErrors['logo'] && apiErrors['logo'].length
                                                        ">
                                                            {{ apiErrors["logo"][0] }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="organisation col-md-12">
                                                    <div class="row organisation-detail">
                                                        <h4 class="pb-4 text-center">ORGANISATION DETAILS</h4>
                                                        <div class="col-md-12 p-0">
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Organisation Name
                                                                            <span class="text-danger">*</span></label>
                                                                        <Field name="name" as="input" type="text"
                                                                            class="form-control"
                                                                            v-model="formData.name" />
                                                                        <ErrorMessage name="name" class="text-danger" />
                                                                        <span class="text-danger"
                                                                            v-if="apiErrors && apiErrors['name'] && apiErrors['name'].length">
                                                                            {{ apiErrors["name"][0] }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Company
                                                                            Type</label>
                                                                        <Field as="select" name="type" id="type"
                                                                            v-model="formData.type"
                                                                            @change="onTypeChange" class="form-select">
                                                                            <option value="">-----Select-----</option>
                                                                            <option v-for="res in options.org_type"
                                                                                :key="res.value" :value="res.value">{{
                                                                                    res.value
                                                                                }}</option>
                                                                        </Field>
                                                                        <ErrorMessage name="type" class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div v-if="formData.type === 'Charity'"
                                                                    class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Charity
                                                                            No:<span
                                                                                class="text-danger">*</span></label>
                                                                        <Field name="charity_reg_no" type="text"
                                                                            class="form-control"
                                                                            v-model="formData.charity_reg_no" />
                                                                        <ErrorMessage name="charity_reg_no"
                                                                            class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Charity Reg
                                                                            No:</label>
                                                                        <input type="text" class="form-control"
                                                                            v-model="formData.charity_reg_no" />
                                                                    </div>
                                                                </div> -->
                                                                <!-- <div v-if="formData.type !== 'Soletrader' || formData.type !== 'Partnership' || formData.type !== 'Church\/community organisation' || formData.type !== 'Charity'" class="col-md-6 company-field">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Company Reg
                                                                            No:</label>
                                                                        <Field name="company_reg_no" type="text"
                                                                            class="form-control"
                                                                            v-model="formData.company_reg_no" />
                                                                        <ErrorMessage name="company_reg_no"
                                                                            class="text-danger" />
                                                                    </div>
                                                                </div> -->
                                                                <div v-if="formData.type !== 'Soletrader' && formData.type !== 'Partnership' && formData.type !== 'Church/community organisation' && formData.type !== 'Charity'"
                                                                    class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Company Reg
                                                                            No:</label>
                                                                        <Field name="company_reg_no" type="text"
                                                                            class="form-control"
                                                                            v-model="formData.company_reg_no" />
                                                                        <ErrorMessage name="company_reg_no"
                                                                            class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-wrap mb-wrap">
                                                                        <label
                                                                            class="col-form-label truncate-text">Company
                                                                            Incorporated Date</label>
                                                                        <div class="icon-form">
                                                                            <!-- <Field name="company_incorporated_date" -->
                                                                            <!-- v-model="date_of_birth"> -->
                                                                            <date-picker text-input placeholder=""
                                                                                class="datetimepicker form-control"
                                                                                v-model="formData.company_incorporated_date"
                                                                                :editable="true" :clearable="true"
                                                                                :input-format="dateFormat"
                                                                                @update:modelValue="clearDate" />
                                                                            <span class="form-icon"><i
                                                                                    class="ti ti-calendar-check"></i></span>
                                                                            <!-- </Field> -->
                                                                        </div>
                                                                        <!-- <ErrorMessage name="company_incorporated_date" class="text-danger" /> -->
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Customer Status
                                                                        </label>
                                                                        <select id="status" v-model="formData.status"
                                                                            class="form-select">
                                                                            <option value="">-----Select-----</option>
                                                                            <option
                                                                                v-for="res in options.org_account_status"
                                                                                :key="res" :value="res.value">
                                                                                {{ res.text }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Customer Value
                                                                        </label>
                                                                        <Field name="customer_value" type="text"
                                                                            class="form-control"
                                                                            v-model="formData.customer_value" />
                                                                        <!-- <ErrorMessage name="customer_value"
                                                                            class="text-danger" /> -->
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Credit Score
                                                                        </label>
                                                                        <Field name="credit_score" type="text"
                                                                            class="form-control"
                                                                            v-model="formData.credit_score" />
                                                                        <!-- <ErrorMessage name="credit_score"
                                                                            class="text-danger" /> -->
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Company Sector
                                                                        </label>

                                                                        <select id="company_sector"
                                                                            v-model="formData.company_sector"
                                                                            class="form-select">
                                                                            <option value="">-----Select-----</option>
                                                                            <option
                                                                                v-for="source in options.org_customer_sector"
                                                                                :key="source" :value="source.value">
                                                                                {{ source.text }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Turnover
                                                                        </label>

                                                                        <select id="turnover"
                                                                            v-model="formData.turnover"
                                                                            class="form-select">
                                                                            <option value="">-----Select-----</option>
                                                                            <option
                                                                                v-for="source in options.org_turnover"
                                                                                :key="source" :value="source.value">
                                                                                {{ source.text }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Net Worth
                                                                        </label>

                                                                        <select id="net_worth"
                                                                            v-model="formData.net_worth"
                                                                            class="form-select">
                                                                            <option value="">-----Select-----</option>
                                                                            <option
                                                                                v-for="source in options.org_net_worth"
                                                                                :key="source" :value="source.value">
                                                                                {{ source.text }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Building Use
                                                                        </label>

                                                                        <select id="building_use"
                                                                            v-model="formData.building_use"
                                                                            class="form-select">
                                                                            <option value="">-----Select-----</option>
                                                                            <option
                                                                                v-for="source in options.org_building_use"
                                                                                :key="source" :value="source.value">
                                                                                {{ source.text }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">No of
                                                                            Employees</label>
                                                                        <select id="no_of_employees"
                                                                            v-model="formData.no_of_employees"
                                                                            class="form-select">
                                                                            <option value="">-----Select-----</option>
                                                                            <option v-for="emp in options.org_no_of_emp"
                                                                                :key="emp" :value="emp.value">
                                                                                {{ emp.text }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Website Url
                                                                        </label>
                                                                        <Field name="website_url" type="text"
                                                                            class="form-control"
                                                                            v-model="formData.website_url" />
                                                                        <!-- <ErrorMessage name="website_url"
                                                                            class="text-danger" /> -->
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">VAT Declaration
                                                                        </label>

                                                                        <select id="vat_declaration"
                                                                            v-model="formData.vat_declaration"
                                                                            class="form-select">
                                                                            <option value="">-----Select-----</option>
                                                                            <!-- <option
                                                                                v-for="source in options.org_lead_source"
                                                                                :key="source" :value="source.value">
                                                                                {{ source.text }}
                                                                            </option> -->
                                                                            <option value="Y">Yes</option>
                                                                            <option value="N">NO</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">VAT %
                                                                        </label>
                                                                        <Field name="vat_percent" type="text"
                                                                            class="form-control"
                                                                            v-model="formData.vat_percent" />
                                                                        <!-- <ErrorMessage name="vat_percent"
                                                                            class="text-danger" /> -->
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">VAT no.</label>
                                                                        <input type="text" class="form-control"
                                                                            v-model="formData.vat_no" />


                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">

                                                                        <!-- <input type="checkbox"
                                                                            v-model="formData.micro_buisness" checked
                                                                            data-toggle="toggle" data-style="ios"> -->

                                                                        <input type="checkbox"
                                                                            class="form-check-input me-2"
                                                                            id="micro_buisness" :true-value="1"
                                                                            :false-value="0"
                                                                            v-model="formData.micro_buisness" />
                                                                        <label class="form-check-label"
                                                                            for="email_bounces">Micro
                                                                            Business</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <input type="checkbox"
                                                                            class="form-check-input me-2"
                                                                            id="micro_buisness" :true-value="1"
                                                                            :false-value="0"
                                                                            v-model="formData.main_address" />
                                                                        <label class="form-check-label"
                                                                            for="email_bounces">Main
                                                                            Address</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Postcode</label>
                                                                        <input type="text" class="form-control"
                                                                            v-model="formData.postcode" />
                                                                        <span class="text-danger"
                                                                            v-if="apiErrors && apiErrors['postcode'] && apiErrors['postcode'].length">
                                                                            {{ apiErrors["postcode"][0] }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Email
                                                                            Domain</label>
                                                                        <input type="text" class="form-control"
                                                                            v-model="formData.email_domain" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Telephone</label>
                                                                        <Field name="telephone" as="input" type="text"
                                                                            v-model="formData.telephone"
                                                                            class="form-control" />
                                                                        <ErrorMessage name="telephone"
                                                                            class="text-danger" />
                                                                    </div>
                                                                </div>


                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Alert text</label>
                                                                        <!-- <input type="text" class="form-control" v-model="formData.alert_text" /> -->
                                                                        <Field name="alert_text" as="input" type="text"
                                                                            class="form-control"
                                                                            v-model="formData.alert_text" />
                                                                        <ErrorMessage name="alert_text"
                                                                            class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">www</label>
                                                                        <input type="text" class="form-control"
                                                                            v-model="formData.www" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Fax</label>
                                                                        <input type="text" class="form-control"
                                                                            v-model="formData.fax" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap mb-0">
                                                                        <label class="col-form-label">Comment </label>
                                                                        <textarea class="form-control" rows="3"
                                                                            v-model="formData.comment"></textarea>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">ERP ID </label>
                                                                        <input type="text" class="form-control"
                                                                            v-model="formData.erp_id" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Org URN</label>
                                                                        <div class="icon-form-end">
                                                                            <input type="text" class="form-control"
                                                                                placeholder=""
                                                                                v-model="formData.org_urn" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Original Lead
                                                                            Source</label>
                                                                        <!-- {{ leadSourceOptions }} {{ options }} -->
                                                                        <select id="leadSource"
                                                                            v-model="formData.original_lead_source"
                                                                            class="form-select">
                                                                            <option value="">-----Select-----</option>
                                                                            <option
                                                                                v-for="source in options.org_lead_source"
                                                                                :key="source" :value="source.value">
                                                                                {{ source.text }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Territory <span
                                                                                class="text-danger">*</span></label>
                                                                        <Field id="territory" name="territory"
                                                                            v-model="formData.territory_id"
                                                                            class="form-select" as="select">
                                                                            <option value="">-----Select-----</option>
                                                                            <option v-for="ter in Territories"
                                                                                :key="ter.id" :value="ter.id">
                                                                                {{ ter.name }}
                                                                            </option>
                                                                        </Field>
                                                                        <ErrorMessage name="territory"
                                                                            class="text-danger" />
                                                                        <span class="text-danger" v-if="
                                                                            apiErrors &&
                                                                            apiErrors['territory'] &&
                                                                            apiErrors['territory'].length
                                                                        ">
                                                                            {{ apiErrors["territory"][0] }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Responsible
                                                                        </label>
                                                                        <select id="responsible"
                                                                            v-model="formData.responsible"
                                                                            class="form-select">
                                                                            <option value="">-----None-----</option>
                                                                            <option v-for="res in Responsibles"
                                                                                :key="res" :value="res.id">
                                                                                {{ res.name }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">SIC code </label>
                                                                        <input type="text" class="form-control"
                                                                            v-model="formData.sic_code" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">SIC
                                                                            Description</label>
                                                                        <input type="text" class="form-control"
                                                                            v-model="formData.sic_description" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">VAT group </label>
                                                                        <select id="vatgroup"
                                                                            v-model="formData.vat_group"
                                                                            class="form-select">
                                                                            <option value="">-----Select-----</option>
                                                                            <option v-for="res in VatGroup" :key="res"
                                                                                :value="res">
                                                                                {{ res }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Status </label>
                                                                        <select id="status" v-model="formData.status"
                                                                            class="form-select">
                                                                            <option value="">-----Select-----</option>
                                                                            <option
                                                                                v-for="res in options.org_account_status"
                                                                                :key="res" :value="res.value">
                                                                                {{ res.text }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div> -->
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Industry </label>
                                                                        <select id="industry"
                                                                            v-model="formData.industry"
                                                                            class="form-select">
                                                                            <option value="">-----Select-----</option>
                                                                            <option v-for="res in options.org_industry"
                                                                                :key="res" :value="res.value">
                                                                                {{ res.text }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Import ID </label>
                                                                        <input type="text" class="form-control"
                                                                            v-model="formData.charity_import_id" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Lead Source
                                                                        </label>
                                                                        <input type="text" class="form-control"
                                                                            v-model="formData.charity_lead_source" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Duplicate Batch
                                                                        </label>
                                                                        <select id="duplicateBatch"
                                                                            v-model="formData.charity_duplicate_batch"
                                                                            class="form-select">
                                                                            <option value="">--</option>
                                                                            <option v-for="res in DuplicateBatch"
                                                                                :key="res" :value="res">
                                                                                {{ res }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap mb-wrap">
                                                                        <label class="col-form-label">Flag No Meter
                                                                            Opp</label>
                                                                        <input type="text" class="form-control"
                                                                            v-model="formData.flag_no_meter_opp" />
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap mb-wrap">
                                                                        <label class="col-form-label truncate-text">Org
                                                                            Reallocation
                                                                            Marker</label>
                                                                        <input type="text" class="form-control"
                                                                            v-model="formData.org_rellocation_marker" />
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap mb-wrap">
                                                                        <label class="col-form-label">Purchased Data
                                                                            Id</label>
                                                                        <input type="text" class="form-control"
                                                                            v-model="formData.purchased_data_id" />
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Purchased Data
                                                                            Source
                                                                        </label>
                                                                        <select id="purchased_data_source"
                                                                            v-model="formData.purchased_data_source"
                                                                            class="form-select">
                                                                            <option value="">-----Select-----</option>
                                                                            <option
                                                                                v-for="res in options.purchased_data_source"
                                                                                :key="res" :value="res.value">
                                                                                {{ res.text }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap mb-wrap">
                                                                        <label class="col-form-label">Allocation Batch
                                                                            ID</label>
                                                                        <input type="text" class="form-control"
                                                                            v-model="formData.allocation_batch_id" />
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-6 ">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Outlook
                                                                            Sync</label>
                                                                        <select id="outlook_sync"
                                                                            v-model="formData.outlook_sync"
                                                                            class="form-select">
                                                                            <option value="">-----Select-----</option>
                                                                            <option v-for="res in options.outlook_sync"
                                                                                :key="res" :value="res.value">
                                                                                {{ res.text }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="loa-section">
                                                        <div class="row organisation-detail">
                                                            <div class="col-md-12">
                                                                <div class="row">
                                                                    <h4 class="pb-4 text-center">ORGANISATION ADDRESS
                                                                    </h4>
                                                                    <div class="col-md-12 company-field">
                                                                        <div class="form-wrap mb-0">
                                                                            <label class="col-form-label">Address Line
                                                                                1</label>
                                                                            <Field as="textarea" name="address"
                                                                                class="form-control" rows="3"
                                                                                v-model="formData.address"></Field>
                                                                            <ErrorMessage name="address"
                                                                                class="text-danger" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-12 company-field">
                                                                        <div class="form-wrap mb-0">
                                                                            <label class="col-form-label">Address Line
                                                                                2</label>
                                                                            <Field as="textarea" name="address_line_2"
                                                                                class="form-control" rows="3"
                                                                                v-model="formData.city"></Field>
                                                                            <!-- <ErrorMessage name="address_line_2"
                                                                            class="text-danger" /> -->
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-12 company-field">
                                                                        <div class="form-wrap mb-0">
                                                                            <label class="col-form-label">Address Line
                                                                                3</label>
                                                                            <Field as="textarea" name="address_line_3"
                                                                                class="form-control" rows="3"
                                                                                v-model="formData.state"></Field>
                                                                            <!-- <ErrorMessage name="address_line_3"
                                                                            class="text-danger" /> -->
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-12 company-field">
                                                                        <div class="form-wrap mb-0">
                                                                            <label class="col-form-label">Address Line
                                                                                4</label>
                                                                            <Field as="textarea" name="address_line_4"
                                                                                class="form-control" rows="3"
                                                                                v-model="formData.address_line_4">
                                                                            </Field>
                                                                            <!-- <ErrorMessage name="address_line_4"
                                                                            class="text-danger" /> -->
                                                                        </div>
                                                                    </div>
                                                                    <!-- <div class="col-md-6 company-field">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">City</label>
                                                                        <input type="text" class="form-control"
                                                                            v-model="formData.city" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 company-field">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">County / State
                                                                        </label>
                                                                        <input type="text" class="form-control"
                                                                            v-model="formData.state" />
                                                                    </div>
                                                                </div> -->
                                                                    <div class="col-md-12 company-field">
                                                                        <div class="form-wrap mb-wrap">
                                                                            <label class="col-form-label">Post
                                                                                code/ZIP</label>
                                                                            <input type="text" class="form-control"
                                                                                v-model="formData.zip" />
                                                                        </div>
                                                                    </div>


                                                                    <div class="col-md-12 company-field">
                                                                        <div class="form-wrap mb-wrap">
                                                                            <label
                                                                                class="col-form-label">Country</label>
                                                                            <!-- {{ options.org_type }} -->
                                                                            <select id="country_id"
                                                                                v-model="formData.country_id"
                                                                                class="form-select">
                                                                                <option value="">-----Select-----
                                                                                </option>
                                                                                <option v-for="res in Countries"
                                                                                    :key="res" :value="res.id">
                                                                                    {{ res.country_name }}
                                                                                </option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div v-if="isTps" class="col-md-12 company-field">
                                                                        <div class="form-wrap mb-wrap">
                                                                            <label class="col-form-label">TPS</label>
                                                                            <input type="text" disabled
                                                                                class="form-control"
                                                                                v-model="formData.tps" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row organisation-detail">
                                                            <div class="col-md-12">
                                                                <div class="row">
                                                                    <h4 class="pb-4 text-center">PAYMENT DETAILS</h4>
                                                                    <div class="col-md-12 company-field">
                                                                        <div class="form-wrap mb-wrap">
                                                                            <label class="col-form-label">Payment
                                                                                Type</label>
                                                                            <!-- {{ options.org_type }} -->
                                                                            <select id="country_id"
                                                                                v-model="formData.payment_details.payment_type"
                                                                                class="form-select">
                                                                                <option value="">-----Select-----
                                                                                </option>
                                                                                <option
                                                                                    v-for="res in options.org_payment_type"
                                                                                    :key="res" :value="res.value">
                                                                                    {{ res.text }}
                                                                                </option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-12 company-field">
                                                                        <div class="form-wrap mb-wrap">
                                                                            <label class="col-form-label">Direct
                                                                                debit
                                                                                Start
                                                                                Date

                                                                            </label>
                                                                            <div class="icon-form">
                                                                                <!-- <Field name="company_incorporated_date" -->
                                                                                <!-- v-model="direct_debit_start_date"> -->
                                                                                <date-picker text-input placeholder=""
                                                                                    class="datetimepicker form-control"
                                                                                    v-model="formData.payment_details.direct_debit_start_date"
                                                                                    :editable="true" :clearable="true"
                                                                                    :input-format="dateFormat"
                                                                                    @update:modelValue="clearDate" />

                                                                                <span class="form-icon"><i
                                                                                        class="ti ti-calendar-check"></i></span>
                                                                                <!-- </Field> -->
                                                                            </div>
                                                                            <!-- <ErrorMessage name="company_incorporated_date"
                                                                            class="text-danger" /> -->
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 company-field">
                                                                        <div class="form-wrap mb-0">
                                                                            <label class="col-form-label">Account
                                                                                holder</label>

                                                                            <Field type="text" name="account_holder"
                                                                                class="form-control"
                                                                                v-model="formData.payment_details.account_holder">
                                                                            </Field>
                                                                            <!-- <ErrorMessage name="account_holder"
                                                                            class="text-danger" /> -->
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 company-field">
                                                                        <div class="form-wrap">
                                                                            <label class="col-form-label">Account
                                                                                Number</label>

                                                                            <Field type="text" name="account_number"
                                                                                class="form-control"
                                                                                v-model="formData.payment_details.account_number">
                                                                            </Field>
                                                                            <!-- <ErrorMessage name="account_number"
                                                                            class="text-danger" /> -->
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 company-field">
                                                                        <div class="form-wrap">
                                                                            <label class="col-form-label">Sort Code
                                                                            </label>
                                                                            <Field type="text" name="sort_code"
                                                                                class="form-control"
                                                                                v-model="formData.payment_details.sort_code">
                                                                            </Field>
                                                                            <!-- <ErrorMessage name="sort_code"
                                                                            class="text-danger" /> -->
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 company-field">
                                                                        <div class="form-wrap mb-wrap">
                                                                            <label class="col-form-label">Bank Name
                                                                            </label>
                                                                            <Field type="text" name="bank_name"
                                                                                class="form-control"
                                                                                v-model="formData.payment_details.bank_name">
                                                                            </Field>
                                                                            <!-- <ErrorMessage name="bank_name"
                                                                            class="text-danger" /> -->
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 company-field">
                                                                        <div class="form-wrap mb-wrap">
                                                                            <label class="col-form-label">Bank
                                                                                Postcode</label>
                                                                            <Field type="text" name="bank_postcode"
                                                                                class="form-control"
                                                                                v-model="formData.payment_details.bank_postcode">
                                                                            </Field>
                                                                            <!-- <ErrorMessage name="bank_postcode"
                                                                            class="text-danger" /> -->
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div class="loa-section">
                                                        <div v-if="isEditing" class="row organisation-detail">
                                                            <div class="col-md-12 p-0">
                                                                <send-loa
                                                                    v-if="organisationData && Object.keys(organisationData).length"
                                                                    :orgPersons="orgPersons" :sendFromOrg="true"
                                                                    :opportunityData="organisationData"></send-loa>
                                                            </div>
                                                        </div>

                                                        <div class="row organisation-detail">

                                                            <div class="col-md-12">
                                                                <h4 class="pb-4 text-center">PERSON DETAILS</h4>
                                                                <div class="row">
                                                                    <div v-if="isEditing && personData.length > 0"
                                                                        class="col-md-12 company-field">
                                                                        <div class="form-wrap">
                                                                            <label class="col-form-label">Person
                                                                                List</label>
                                                                            <Field as="select" name="person_list"
                                                                                id="person_list"
                                                                                v-model="formData.person.id"
                                                                                class="form-select"
                                                                                @change="onPersonChange">
                                                                                <!-- <option value="">-----Select-----</option> -->
                                                                                <option v-for="res in personData"
                                                                                    :key="res.id" :value="res.id">
                                                                                    {{ res.first_name }}
                                                                                </option>
                                                                            </Field>
                                                                            <ErrorMessage name="person_list"
                                                                                class="text-danger" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 company-field">
                                                                        <div class="form-wrap">
                                                                            <label class="col-form-label">Title</label>
                                                                            <Field as="select" name="salutation"
                                                                                id="salutation"
                                                                                v-model="formData.person.salutation"
                                                                                class="form-select">
                                                                                <option value="">-----Select-----
                                                                                </option>
                                                                                <option
                                                                                    v-for="res in settingOptions.org_p_salutation"
                                                                                    :key="res" :value="res.value">
                                                                                    {{ res.text }}
                                                                                </option>
                                                                            </Field>
                                                                            <ErrorMessage name="salutation"
                                                                                class="text-danger" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 company-field">
                                                                        <div class="form-wrap mb-wrap">
                                                                            <label class="col-form-label">First
                                                                                Name</label>
                                                                            <Field type="text" name="first_name"
                                                                                class="form-control"
                                                                                v-model="formData.person.first_name">
                                                                            </Field>
                                                                            <ErrorMessage name="first_name"
                                                                                class="text-danger" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 company-field">
                                                                        <div class="form-wrap mb-wrap">
                                                                            <label class="col-form-label">Last
                                                                                Name</label>
                                                                            <Field type="text" name="last_name"
                                                                                class="form-control"
                                                                                v-model="formData.person.last_name">
                                                                            </Field>
                                                                            <ErrorMessage name="last_name"
                                                                                class="text-danger" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 company-field">
                                                                        <div class="form-wrap mb-wrap">
                                                                            <label class="col-form-label">Job
                                                                                Title</label>
                                                                            <Field type="text" name="job_title"
                                                                                class="form-control"
                                                                                v-model="formData.person.job_title">
                                                                            </Field>
                                                                            <ErrorMessage name="job_title"
                                                                                class="text-danger" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-12 company-field">
                                                                        <div class="form-wrap mb-wrap">
                                                                            <label class="col-form-label">Email</label>
                                                                            <Field type="text" name="email"
                                                                                class="form-control"
                                                                                v-model="formData.person.email"></Field>
                                                                            <ErrorMessage name="email"
                                                                                class="text-danger" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 company-field">
                                                                        <div class="form-wrap mb-wrap">
                                                                            <label class="col-form-label">Direct
                                                                                Line</label>
                                                                            <Field type="text" name="direct_line"
                                                                                class="form-control"
                                                                                v-model="formData.person.direct_line">
                                                                            </Field>
                                                                            <!-- <ErrorMessage name="direct_line"
                                                                            class="text-danger" /> -->
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 company-field">
                                                                        <div class="form-wrap mb-wrap">
                                                                            <label
                                                                                class="col-form-label">Landline</label>
                                                                            <Field type="text" name="landline"
                                                                                class="form-control"
                                                                                v-model="formData.person.landline">
                                                                            </Field>
                                                                            <!-- <ErrorMessage name="landline"
                                                                            class="text-danger" /> -->
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-md-6 company-field">
                                                                        <div class="form-wrap mb-wrap">
                                                                            <label class="col-form-label">Date Of
                                                                                Birth</label>
                                                                            <div class="icon-form">
                                                                                <Field name="date_of_birth"
                                                                                    v-model="date_of_birth">
                                                                                    <date-picker text-input
                                                                                        placeholder=""
                                                                                        class="datetimepicker form-control"
                                                                                        v-model="formData.person.date_of_birth"
                                                                                        :editable="true"
                                                                                        :clearable="true"
                                                                                        :input-format="dateFormat"
                                                                                        @update:modelValue="clearDate" />

                                                                                    <span class="form-icon"><i
                                                                                            class="ti ti-calendar-check"></i></span>
                                                                                </Field>
                                                                            </div>
                                                                            <ErrorMessage name="date_of_birth"
                                                                                class="text-danger" />
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-6 company-field">
                                                                        <div class="form-wrap mb-wrap">
                                                                            <label class="col-form-label">Mobile
                                                                                Telephone</label>
                                                                            <Field type="text" name="mobile_telephone"
                                                                                class="form-control"
                                                                                v-model="formData.person.mobile_telephone">
                                                                            </Field>
                                                                            <ErrorMessage name="mobile_telephone"
                                                                                class="text-danger" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 company-field">
                                                                        <div class="form-wrap">
                                                                            <label class="col-form-label">Direct Debit
                                                                                Day</label>
                                                                            <Field as="select"
                                                                                name="customer_direct_debit_day"
                                                                                id="customer_direct_debit_day"
                                                                                v-model="formData.person.customer_direct_debit_day"
                                                                                class="form-select"
                                                                                v-if="settingOptions && settingOptions.org_p_direct_debit_day">
                                                                                <option value="">-----Select-----
                                                                                </option>
                                                                                <option
                                                                                    v-for="source in settingOptions.org_p_direct_debit_day"
                                                                                    :key="source.value"
                                                                                    :value="source.value">
                                                                                    {{ source.text }}
                                                                                </option>
                                                                            </Field>
                                                                            <ErrorMessage
                                                                                name="customer_direct_debit_day"
                                                                                class="text-danger" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 company-field">
                                                                        <div class="form-wrap">
                                                                            <label class="col-form-label">Payment
                                                                                Method</label>
                                                                            <Field as="select"
                                                                                name="customer_payment_method"
                                                                                id="customer_payment_method"
                                                                                v-model="formData.person.customer_payment_method"
                                                                                class="form-select"
                                                                                v-if="settingOptions && settingOptions.org_p_payment_method">
                                                                                <option value="">-----Select-----
                                                                                </option>
                                                                                <option
                                                                                    v-for="source in settingOptions.org_p_payment_method"
                                                                                    :key="source.value"
                                                                                    :value="source.value">
                                                                                    {{ source.text }}
                                                                                </option>
                                                                            </Field>
                                                                            <ErrorMessage name="customer_payment_method"
                                                                                class="text-danger" />
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-md-12 company-field">
                                                                        <div class="form-wrap mb-wrap">
                                                                            <label class="col-form-label">Marketing
                                                                                Preference (optional)</label>
                                                                            <div>
                                                                                <!-- Email -->
                                                                                <div
                                                                                    class="form-check form-check-inline">
                                                                                    <input class="form-check-input"
                                                                                        type="checkbox" :value="'email'"
                                                                                        v-model="formData.person.marketing_prefrences"
                                                                                        id="checkboxEmail" />
                                                                                    <label class="form-check-label"
                                                                                        for="checkboxEmail">Email</label>
                                                                                </div>
                                                                                <!-- Phone -->
                                                                                <div
                                                                                    class="form-check form-check-inline">
                                                                                    <input class="form-check-input"
                                                                                        type="checkbox" :value="'phone'"
                                                                                        v-model="formData.person.marketing_prefrences"
                                                                                        id="checkboxPhone" />
                                                                                    <label class="form-check-label"
                                                                                        for="checkboxPhone">Phone</label>
                                                                                </div>
                                                                                <!-- SMS -->
                                                                                <div
                                                                                    class="form-check form-check-inline">
                                                                                    <input class="form-check-input"
                                                                                        type="checkbox" :value="'sms'"
                                                                                        v-model="formData.person.marketing_prefrences"
                                                                                        id="checkboxSMS" />
                                                                                    <label class="form-check-label"
                                                                                        for="checkboxSMS">SMS</label>
                                                                                </div>
                                                                                <!-- Post -->
                                                                                <div
                                                                                    class="form-check form-check-inline">
                                                                                    <input class="form-check-input"
                                                                                        type="checkbox" :value="'post'"
                                                                                        v-model="formData.person.marketing_prefrences"
                                                                                        id="checkboxPost" />
                                                                                    <label class="form-check-label"
                                                                                        for="checkboxPost">Post</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>



                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row organisation-detail"
                                                        v-if="formData.type === 'LLP' || formData.type === 'Soletrader' || formData.type === 'Partnership'">
                                                        <div class="col-md-12 ">
                                                            <h4 class="pb-4 text-center">OWNER DETAILS</h4>
                                                            <div class="row">
                                                                <div v-if="isEditing && ownerData.length > 0"
                                                                    class="col-md-12 company-field">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">Partner
                                                                            List</label>
                                                                        <Field as="select" name="partner_list"
                                                                            id="partner_list"
                                                                            v-model="formData.owner_details.id"
                                                                            class="form-select" @change="onOwnerChange">
                                                                            <!-- <option value="">-----Select-----</option> -->
                                                                            <option v-for="res in ownerData"
                                                                                :key="res.id" :value="res.id">
                                                                                {{ res.first_name }}
                                                                            </option>
                                                                        </Field>
                                                                        <ErrorMessage name="partner_list"
                                                                            class="text-danger" />
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-12 company-field">
                                                                    <div
                                                                        class="form-wrap mt-4 pt-2 d-flex align-items-center">
                                                                        <Field as="input" name="primary_partner"
                                                                            type="checkbox"
                                                                            class="form-check-input me-2"
                                                                            id="primary_partner" :value="1"
                                                                            :unchecked-value="0"
                                                                            v-model="formData.owner_details.primary_partner" />
                                                                        <label class="form-check-label mb-0"
                                                                            for="primary_partner">Primary
                                                                            Partner</label>
                                                                    </div>
                                                                    <ErrorMessage name="primary_partner"
                                                                        class="text-danger mt-1" />
                                                                </div>



                                                                <div class="col-md-6 company-field">
                                                                    <div class="form-wrap mb-wrap">
                                                                        <label class="col-form-label">Name</label>
                                                                        <Field type="text" name="owner_name"
                                                                            class="form-control"
                                                                            v-model="formData.owner_details.first_name">
                                                                        </Field>
                                                                        <ErrorMessage name="owner_name"
                                                                            class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 company-field">
                                                                    <div class="form-wrap mb-wrap">
                                                                        <label class="col-form-label">DOB</label>
                                                                        <div class="icon-form">
                                                                            <Field name="dob" v-model="dob">
                                                                                <date-picker text-input placeholder=""
                                                                                    class="datetimepicker form-control"
                                                                                    v-model="formData.owner_details.dob"
                                                                                    :editable="true" :clearable="true"
                                                                                    :input-format="dateFormat"
                                                                                    @update:modelValue="clearDate" />

                                                                                <span class="form-icon"><i
                                                                                        class="ti ti-calendar-check"></i></span>
                                                                            </Field>
                                                                            <ErrorMessage name="dob"
                                                                                class="text-danger" />
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div class="col-md-12 company-field">
                                                                    <div class="form-wrap">
                                                                        <label class="col-form-label">View Details
                                                                            For</label>
                                                                        <Field as="select" name="default_address"
                                                                            id="default_address"
                                                                            v-model="formData.owner_details.default_address"
                                                                            @change="clearField" class="form-select">
                                                                            <option v-if="!isEditing" value="">
                                                                                -----Select-----
                                                                            </option>
                                                                            <option key="current_address"
                                                                                value="current">
                                                                                {{ "Current Address" }}
                                                                            </option>
                                                                            <option key="previous_address"
                                                                                value="previous">
                                                                                {{ "Previous Address" }}
                                                                            </option>
                                                                        </Field>
                                                                        <ErrorMessage name="default_address"
                                                                            class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div v-if="formData.owner_details.default_address === 'previous'"
                                                                    class="col-md-12 company-field ">
                                                                    <div class="form-wrap mb-wrap">
                                                                        <label class="col-form-label">Year At Previous
                                                                            Address</label>
                                                                        <Field type="text"
                                                                            name="years_at_current_address"
                                                                            class="form-control"
                                                                            v-model="formData.owner_details.years_at_previous_address">
                                                                        </Field>
                                                                        <ErrorMessage name="years_at_current_address"
                                                                            class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div v-else class="col-md-12 company-field">
                                                                    <div class="form-wrap mb-wrap">
                                                                        <label class="col-form-label">Year At Current
                                                                            Address</label>
                                                                        <Field type="text"
                                                                            name="years_at_current_address"
                                                                            class="form-control"
                                                                            v-model="formData.owner_details.years_at_current_address">
                                                                        </Field>
                                                                        <ErrorMessage name="years_at_current_address"
                                                                            class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div v-if="formData.owner_details.default_address === 'previous'"
                                                                    class="col-md-12 company-field">
                                                                    <div class="form-wrap mb-wrap">
                                                                        <label class="col-form-label">Previous Address
                                                                            Line
                                                                            1</label>
                                                                        <Field type="text" name="current_address_line_1"
                                                                            class="form-control"
                                                                            v-model="formData.owner_details.previous_address_line_1">
                                                                        </Field>
                                                                        <ErrorMessage name="current_address_line_1"
                                                                            class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div v-else class="col-md-12 company-field">
                                                                    <div class="form-wrap mb-wrap">
                                                                        <label class="col-form-label">Current Address
                                                                            Line
                                                                            1</label>
                                                                        <Field type="text" name="current_address_line_1"
                                                                            class="form-control"
                                                                            v-model="formData.owner_details.current_address_line_1">
                                                                        </Field>
                                                                        <ErrorMessage name="current_address_line_1"
                                                                            class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div v-if="formData.owner_details.default_address === 'previous'"
                                                                    class="col-md-12 company-field">
                                                                    <div class="form-wrap mb-wrap">
                                                                        <label class="col-form-label">Previous Address
                                                                            Line
                                                                            2</label>
                                                                        <Field type="text" name="current_address_line_2"
                                                                            class="form-control"
                                                                            v-model="formData.owner_details.previous_address_line_2">
                                                                        </Field>
                                                                        <ErrorMessage name="current_address_line_2"
                                                                            class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div v-else class="col-md-12 company-field">
                                                                    <div class="form-wrap mb-wrap">
                                                                        <label class="col-form-label">Current Address
                                                                            Line
                                                                            2</label>
                                                                        <Field type="text" name="current_address_line_2"
                                                                            class="form-control"
                                                                            v-model="formData.owner_details.current_address_line_2">
                                                                        </Field>
                                                                        <ErrorMessage name="current_address_line_2"
                                                                            class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div v-if="formData.owner_details.default_address === 'previous'"
                                                                    class="col-md-12 company-field">
                                                                    <div class="form-wrap mb-wrap">
                                                                        <label class="col-form-label">Previous Address
                                                                            Line
                                                                            3</label>
                                                                        <Field type="text" name="current_address_line_3"
                                                                            class="form-control"
                                                                            v-model="formData.owner_details.previous_address_line_3">
                                                                        </Field>
                                                                        <ErrorMessage name="current_address_line_3"
                                                                            class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div v-else class="col-md-12 company-field">
                                                                    <div class="form-wrap mb-wrap">
                                                                        <label class="col-form-label">Current Address
                                                                            Line
                                                                            3</label>
                                                                        <Field type="text" name="current_address_line_3"
                                                                            class="form-control"
                                                                            v-model="formData.owner_details.current_address_line_3">
                                                                        </Field>
                                                                        <ErrorMessage name="current_address_line_3"
                                                                            class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div v-if="formData.owner_details.default_address === 'previous'"
                                                                    class="col-md-12 company-field">
                                                                    <div class="form-wrap mb-wrap">
                                                                        <label class="col-form-label">Previous Address
                                                                            Line
                                                                            4</label>
                                                                        <Field type="text" name="current_address_line_4"
                                                                            class="form-control"
                                                                            v-model="formData.owner_details.previous_address_line_4">
                                                                        </Field>
                                                                        <ErrorMessage name="current_address_line_4"
                                                                            class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div v-else class="col-md-12 company-field">
                                                                    <div class="form-wrap mb-wrap">
                                                                        <label class="col-form-label">Current Address
                                                                            Line
                                                                            4</label>
                                                                        <Field type="text" name="current_address_line_4"
                                                                            class="form-control"
                                                                            v-model="formData.owner_details.current_address_line_4">
                                                                        </Field>
                                                                        <ErrorMessage name="current_address_line_4"
                                                                            class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div v-if="formData.owner_details.default_address === 'previous'"
                                                                    class="col-md-12 company-field">
                                                                    <div class="form-wrap mb-wrap">
                                                                        <label class="col-form-label">Previous
                                                                            Postcode</label>
                                                                        <Field type="text" name="current_postcode"
                                                                            class="form-control"
                                                                            v-model="formData.owner_details.previous_postcode">
                                                                        </Field>
                                                                        <ErrorMessage name="current_postcode"
                                                                            class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div v-else class="col-md-12 company-field">
                                                                    <div class="form-wrap mb-wrap">
                                                                        <label class="col-form-label">Current
                                                                            Postcode</label>
                                                                        <Field type="text" name="current_postcode"
                                                                            class="form-control"
                                                                            v-model="formData.owner_details.current_postcode">
                                                                        </Field>
                                                                        <ErrorMessage name="current_postcode"
                                                                            class="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row my-3">
                                            <div class="col-md-6">
                                                <div class="form-wrap mb-0 mt-4" style="border: 1px solid #e1e1e1">
                                                    <h4 class="p-2">Logs</h4>
                                                    <p class="col-form-label p-2" style="background-color: #eff1f0">
                                                        Add log entry
                                                    </p>
                                                    <textarea class="form-control" rows="3"
                                                        v-model="formData.content"></textarea>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="row my-3" v-if="isEditing">
                                            <div class="col-md-6">
                                                <div class="form-wrap mb-0" style="border: 1px solid #e1e1e1">
                                                    <p class="col-form-label p-2" style="background-color: #eff1f0">
                                                        Logs
                                                    </p>
                                                    <div class="logs_Section">
                                                        <div class="row" v-for="log in logs">
                                                            <div class="col-12">
                                                                <p class="p-2">{{ log.content }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="submit-button text-end">
                                            <button type="button" class="btn btn-light"
                                                @click="handleCancel">Cancel</button>
                                            <button type="submit" class="btn btn-primary">
                                                {{ isEditing ? "Update" : "Create" }}
                                            </button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    ref,
    onMounted,
    watch,
    computed
} from "vue";
import {
    mapActions,
    mapGetters
} from "vuex";
import {
    useStore
} from "vuex";
import {
    useRouter,
    useRoute
} from "vue-router";
import {
    Form,
    Field,
    ErrorMessage
} from "vee-validate";
import * as Yup from "yup";
import {
    message
} from "ant-design-vue";
import apiClient from "@/axios";
import { changeFormate } from '../../../../util'

const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
const currentDateTwo = ref(new Date());

export default {
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        return {
            startdate: currentDate,
            startdateOne: currentDateOne,
            startdateTwo: currentDateTwo,
            dateFormat: "dd/MM/yyyy",
            DuplicateBatch: ["Yes"],
            VatGroup: ["A", "B"],
        };
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        const isEditing = ref(false);
        const org_id = ref(null);
        const imagePreview = ref("");
        const apiErrors = ref(null);
        const personData = ref([])
        const ownerData = ref([])
        const settingOptions = ref([])
        const date_of_birth = ref(null)
        const dob = ref(null)
        const isTps = ref(false)
        const organisationData = ref({});
        const orgPersons = ref([]);
        const options = computed(() => store.getters["organisation/getorgDropdowns"]);
        const Territories = computed(() => store.getters["organisation/getTerritories"]);
        const Responsibles = computed(() => store.getters["organisation/getresUsersOptions"]);
        const assignedToOptions = computed(
            () => store.getters["organisation/getfetchAssignedTo"]
        );
        const Countries = computed(() => store.getters["organisation/getCountries"]);

        const schema = Yup.object().shape({
            name: Yup.string().required("Organisation name is required"),
            territory: Yup.string().required("Territory is required"),
            telephone: Yup.string()
                .nullable()
                .max(10, "Telephone must not be greater than 10 digits."),
            company_reg_no: Yup.string().when("type", {
                is: "Ltd",
                then: (schema) =>
                    schema.required("Please enter Company Reg No. for Ltd companies"),
                otherwise: (schema) => schema.notRequired(),
            }),
            date_of_birth: Yup.string().when("type", {
                is: (type) => type === "Soletrader" || type === "Partnership",
                then: (schema) =>
                    schema.required("Please enter Date of Birth for Soletrader or Partnership"),
                otherwise: (schema) => schema.notRequired(),
            }),
            address: Yup.string().when("type", {
                is: (type) => type === "Soletrader" || type === "Partnership",
                then: (schema) =>
                    schema.required("Please enter Address for Soletrader or Partnership"),
                otherwise: (schema) => schema.notRequired(),
            }),
            first_name: Yup.string().when(["type", "person_list"], {
                is: (type, person_list) =>
                    type === "Soletrader" || type === "Partnership" || Boolean(person_list),
                then: (schema) =>
                    schema.required("Please enter First name for Soletrader, Partnership, or if Person List is selected"),
                otherwise: (schema) => schema.notRequired(),
            }),
            email: Yup.string().when(["type", "person_list"], {
                is: (type, person_list) =>
                    type === "Soletrader" || type === "Partnership" || Boolean(person_list),
                then: (schema) =>
                    schema.required("Please enter email for Soletrader, Partnership, or if Person List is selected"),
                otherwise: (schema) => schema.notRequired(),
            }),
            first_name: Yup.string().when(
                ["last_name", "mobile_telephone", "date_of_birth", "customer_direct_debit_day", "customer_payment_method", "salutation", "job_title"],
                {
                    is: (...fields) => fields.some((field) => Boolean(field && field.trim())), // Check if any field has a value
                    then: (schema) =>
                        schema.required("First name is required because other person details are provided"),
                    otherwise: (schema) => schema.notRequired(),
                }
            ),
            email: Yup.string().when(
                ["last_name", "mobile_telephone", "date_of_birth", "customer_direct_debit_day", "customer_payment_method", "salutation", "job_title"],
                {
                    is: (...fields) => fields.some((field) => Boolean(field && field.trim())), // Check if any field has a value
                    then: (schema) =>
                        schema.required("Email is required because other person details are provided"),
                    otherwise: (schema) => schema.notRequired(),
                }
            ),

            charity_reg_no: Yup.string().when("type", {
                is: "Charity",
                then: (schema) => schema.required("Charity No. is required"),
                otherwise: (schema) => schema.notRequired(),
            }),

            owner_name: Yup.string().test(
                "owner-name-required",
                "Owner name is required because other owner details are provided",
                function (value) {
                    const {
                        default_address,
                        years_at_current_address,
                        current_address_line_1,
                        current_address_line_2,
                        current_address_line_3,
                        current_address_line_4,
                        current_postcode,
                        primary_partner,
                        dob,
                    } = this.parent;

                    // Check conditions for requiring `owner_name`
                    const isRequired =
                        primary_partner === 1 ||
                        ["current", "previous"].includes(default_address) ||
                        Boolean(dob) ||
                        [
                            years_at_current_address,
                            current_address_line_1,
                            current_address_line_2,
                            current_address_line_3,
                            current_address_line_4,
                            current_postcode,
                        ].some((field) => Boolean(field && field.trim()));

                    // If required, ensure `owner_name` is not empty
                    if (isRequired) {
                        return Boolean(value && value.trim());
                    }

                    return true; // Not required, so validation passes
                }
            ),

            default_address: Yup.string().test(
                "view-details-for",
                "View Details For is required because other details are provided",
                function (value) {
                    const {
                        years_at_current_address,
                        current_address_line_1,
                        current_address_line_2,
                        current_address_line_3,
                        current_address_line_4,
                        current_postcode,
                    } = this.parent;

                    // Check conditions for requiring `owner_name`
                    const isRequired =
                        [
                            years_at_current_address,
                            current_address_line_1,
                            current_address_line_2,
                            current_address_line_3,
                            current_address_line_4,
                            current_postcode,
                        ].some((field) => Boolean(field && field.trim()));

                    // If required, ensure `owner_name` is not empty
                    if (isRequired) {
                        return Boolean(value && value.trim());
                    }

                    return true; // Not required, so validation passes
                }
            ),



        });


        if (route.query.org_Id) {
            org_id.value = route.query.org_Id;
            isEditing.value = true;
        }

        const formData = ref({
            id: null,
            name: "",
            postcode: "",
            telephone: "",
            fax: "",
            responsible: null,
            assign_to: null,
            logo: null,
            comment: "",
            territory_id: "",
            vat_group: "",
            type: "",
            status: "",
            industry: "",
            address: "",
            address_line_4: "",
            city: "",
            state: "",
            zip: "",
            country_id: null,
            alert_text: "",
            www: "",
            vat_no: "",
            erp_id: "",
            org_urn: "",
            original_lead_source: "",
            no_of_employees: "",
            sic_code: "",
            sic_description: "",
            company_reg_no: "",
            charity_reg_no: "",
            charity_import_id: "",
            charity_lead_source: "",
            charity_duplicate_batch: "",
            content: "",
            purchased_data_source: "",
            purchased_data_id: "",
            allocation_batch_id: "",
            email_domain: "",
            flag_no_meter_opp: "",
            org_rellocation_marker: "",
            outlook_sync: "",
            tps: "",
            parent_company_name: "",
            company_incorporated_date: null,
            customer_value: "",
            credit_score: "",
            company_sector: "",
            turnover: "",
            net_worth: "",
            building_use: "",
            website_url: "",
            vat_declaration: "",
            vat_percent: "",
            micro_buisness: 0,
            main_address: 0,
            person: {
                first_name: "",
                last_name: "",
                email: "",
                salutation: "",
                mobile_telephone: "",
                job_title: "",
                customer_direct_debit_day: "",
                customer_payment_method: "",
                id: "",
                date_of_birth: null,
                direct_line: "",
                landline: "",
                marketing_prefrences: []
            },
            owner_details: {
                id: "",
                first_name: "",
                dob: null,
                primary_partner: 0,
                default_address: "",
                years_at_current_address: "",
                current_address_line_1: "",
                current_address_line_2: "",
                current_address_line_3: "",
                current_address_line_4: "",
                current_postcode: "",
                years_at_previous_address: "",
                previous_address_line_1: "",
                previous_address_line_2: "",
                previous_address_line_3: "",
                previous_address_line_4: "",
                previous_postcode: "",
            },
            payment_details: {
                payment_type: "",
                direct_debit_start_date: null,
                account_holder: "",
                account_number: "",
                sort_code: "",
                bank_name: "",
                bank_postcode: ""
            }
        });
        const logs = ref([]);



        const loaData = ref({
            // AgentEmail: props.opportunityData?.sales_responsible_data?.email || "", // sales responsible email
            AgentEmail: formData.value.person.email || "", // sales responsible email
            // service_type: props.opportunityData?.main_production_service || "",
            loa_template: "power",
            MainDetailsData: {
                premises_name: formData.value.name || "", // org name
                email: "", // person email
                contact_name: "", // person name
                company_number:
                    formData.value.company_reg_no || "", // org Company Reg No:
                post_code: formData.value.postcode || "", // opp supply addres post code
                address_1: formData.value.address || "", // opp supply building name
                company_landline: formData.value.telephone || "", // org landline / phone number / mobile
                landline: "", // person landline
                expiry_date: null, // TBD
                reg_no: formData.value.company_reg_no || "", // org Company Reg No:
                mobile: "", // person mobile
                loa_start_date: null, // loa start date
                loa_end_date: null, // loa end date
                business_type: "LTD", //
                address_2: formData.value.city || "", // opp street
                address_3: formData.value.state || "", // city
                job_title: "", // person job title
                title: "Mr", // person title
                fax: "", // person fax
                direct_line: "",
            },
            AuxiliaryDetailsData: {
                // BrokerAgentFullName:
                //     props.opportunityData?.sales_responsible_data?.first_name + props.opportunityData?.sales_responsible_data?.last_name || "", // sales responsible name
                // BrokerAgentEmail:
                //     props.opportunityData?.sales_responsible_data?.email || "", // sales responsible email
                // BrokerAgentPhoneNumber:
                //     props.opportunityData?.sales_responsible_data?.phone_number || "", // sales phone
                // PremisesName: props.opportunityData?.organization?.name || "", // org name
                // PremisesAddress1:
                //     props.opportunityData?.supply_address?.building_name || "", // opp supply add building name
                // PremisesAddress2: props.opportunityData?.supply_address?.street || "", // opp street
                // PremisesAddress3: props.opportunityData?.supply_address?.street || "", // opp street
                // PremisesAddress4: props.opportunityData?.supply_address?.city || "", // opp city
                // PremisesPostcode: props.opportunityData?.supply_address?.postcode || "", // opp post code
                CompanyName: formData.value.name || "", // org name
                // CompanyAddress1:
                //     props.opportunityData?.supply_address?.building_name || "", // opp supply add building name
                // CompanyAddress2: props.opportunityData?.supply_address?.street || "", // opp street
                // CompanyAddress3: props.opportunityData?.supply_address?.street || "", // opp street
                // CompanyAddress4: props.opportunityData?.supply_address?.city || "", // opp city
                // CompanyPostcode: props.opportunityData?.supply_address?.postcode || "", // opp post code
                CompanyNumber:
                    formData.value.company_reg_no || "", // org Company Reg No:
                siteorcompanyname: formData.value.name || "", // org name
                // siteorcompanyaddress1:
                //     props.opportunityData?.supply_address?.building_name || "", // opp supply add building name
                // siteorcompanyaddress2:
                //     props.opportunityData?.supply_address?.street || "", // opp street
                // siteorcompanyaddress3:
                //     props.opportunityData?.supply_address?.street || "", // opp street
                // siteorcompanyaddress4:
                //     props.opportunityData?.supply_address?.city || "", // opp city
                // siteorcompanypostcode:
                //     props.opportunityData?.supply_address?.postcode || "", // opp post code
                siteorcompanylandline: "", // person landline
                siteorcompanyemail: "", // person email
                siteorcompanycontactname: "", // person name
                siteorcompanyjobtitle: "", //person jot title
            },
        });



        // Assuming formData is a reactive object
        const formattedTelephone = computed({
            get() {
                // Ensure formData and formData.value.telephone are defined
                if (!formData.value || !formData.value.telephone) {
                    return ""; // Return an empty string if there's no telephone number
                }

                const number = formData.value.telephone.replace(/\D/g, "");

                // Check if the number is valid (e.g., 10 digits)
                if (number.length === 10) {
                    // Format the number
                    return `(${number.substring(0, 3)}) ${number.substring(
                        3,
                        6
                    )}-${number.substring(6)}`;
                } else {
                    // Return the original value if the number is not valid
                    return formData.value.telephone;
                }
            },
            set(value) {
                // Clean the input value to store only digits
                const cleanedValue = value.replace(/\D/g, "");

                // If the cleaned value is empty, set formData.telephone to an empty string
                formData.value.telephone = cleanedValue.length ? cleanedValue : "";
            },
        });

        const clearDate = () => {
            if (formData.value.person.date_of_birth) {
                date_of_birth.value = formData.value.person.date_of_birth;
            } else {
                date_of_birth.value = null;
            }

            if (formData.value.owner_details.dob) {
                dob.value = formData.value.owner_details.dob;
            } else {
                dob.value = null;
            }
        }

        const fetchOrgData = async (id) => {
            try {
                const data = await store.dispatch("organisation/fetchOrgData", id);
                logs.value = data.logs;
                organisationData.value = data;
                orgPersons.value = data.persons;
                // Assign main formData fields
                Object.assign(formData.value, {
                    id: data.id || null,
                    name: data.name || "",
                    postcode: data.postcode || "",
                    telephone: data.telephone || "",
                    fax: data.fax || "",
                    responsible: data.responsible || null,
                    assign_to: data.assign_to || null,
                    logo: data.logo || null,
                    comment: data.comment || "",
                    territory_id: data.territory_id || "",
                    vat_group: data.vat_group || "",
                    type: data.type || "",
                    status: data.status || "",
                    industry: data.industry || "",
                    address: data.address || "",
                    city: data.city || "",
                    state: data.state || "",
                    zip: data.zip || "",
                    country_id: data.country_id || null,
                    alert_text: data.alert_text || "",
                    www: data.www || "",
                    vat_no: data.vat_no || "",
                    erp_id: data.erp_id || "",
                    org_urn: data.org_urn || "",
                    original_lead_source: data.original_lead_source || "",
                    no_of_employees: data.no_of_employees || "",
                    sic_code: data.sic_code || "",
                    sic_description: data.sic_description || "",
                    company_reg_no: data.company_reg_no || "",
                    charity_reg_no: data.charity_reg_no || "",
                    charity_import_id: data.charity_import_id || "",
                    charity_lead_source: data.charity_lead_source || "",
                    charity_duplicate_batch: data.charity_duplicate_batch || "",
                    content: data.content || "",
                    purchased_data_source: data.purchased_data_source || "",
                    purchased_data_id: data.purchased_data_id || "",
                    allocation_batch_id: data.allocation_batch_id || "",
                    email_domain: data.email_domain || "",
                    flag_no_meter_opp: data.flag_no_meter_opp || "",
                    org_rellocation_marker: data.org_rellocation_marker || "",
                    outlook_sync: data.outlook_sync || "",
                    tps: data.hasOwnProperty("tps") ? data.tps || "" : "",
                    parent_company_name: data.parent_company_name,
                    company_incorporated_date: data.company_incorporated_date ? new Date(data.company_incorporated_date) : null,
                    customer_value: data.customer_value,
                    credit_score: data.credit_score,
                    company_sector: data.company_sector,
                    turnover: data.turnover,
                    net_worth: data.net_worth,
                    building_use: data.building_use,
                    website_url: data.website_url,
                    vat_declaration: data.vat_declaration,
                    vat_percent: data.vat_percent,
                    micro_buisness: data.micro_buisness,
                    main_address: data.main_address,
                    // address_line_1: data.address.address_line_1,
                    // address_line_1: data.address.address_line_2,
                    // address_line_1: data.address.address_line_3,
                    address_line_4: data.address_line_4,
                });

                // const address = JSON.parse(data.address)
                // formData.value.address = address.address
                // formData.value.city = address.city
                // formData.value.state = address.state
                // formData.value.address_line_4 = address.address_line_4

                // Handle person data
                if (data.persons && data.persons.length > 0) {
                    personData.value = data.persons;
                    const firstPerson = data.persons[0];
                    Object.assign(formData.value.person, {
                        id: firstPerson.id || "",
                        first_name: firstPerson.first_name || "",
                        last_name: firstPerson.last_name || "",
                        email: firstPerson.email || "",
                        salutation: firstPerson.salutation || "",
                        mobile_telephone: firstPerson.mobile_telephone || "",
                        job_title: firstPerson.job_title || "",
                        customer_direct_debit_day: firstPerson.customer_direct_debit_day || "",
                        customer_payment_method: firstPerson.customer_payment_method || "",
                        date_of_birth: firstPerson.date_of_birth ? new Date(firstPerson.date_of_birth) : null,
                        direct_line: firstPerson.direct_line,
                        landline: firstPerson.landline,
                        // marketing_prefrences: firstPerson.marketing_prefrences
                        marketing_prefrences: firstPerson.marketing_prefrences ? firstPerson.marketing_prefrences.split(',') : []
                    });
                    date_of_birth.value = formData.value.person.date_of_birth;
                }

                if (data.payment_details && data.payment_details.length > 0) {
                    // personData.value = data.payment_details;
                    const firstPerson = data.payment_details[0];
                    Object.assign(formData.value.payment_details, {
                        payment_type: firstPerson.payment_type || "",
                        direct_debit_start_date: firstPerson.direct_debit_start_date ? new Date(firstPerson.direct_debit_start_date) : null,
                        account_holder: firstPerson.account_holder || "",
                        account_number: firstPerson.account_number || "",
                        sort_code: firstPerson.sort_code || "",
                        bank_name: firstPerson.bank_name || "",
                        bank_postcode: firstPerson.bank_postcode || ""
                    });

                }

                // Handle owner details
                if (data.owner_details && data.owner_details.length > 0) {
                    ownerData.value = data.owner_details;
                    const owner = data.owner_details[0];
                    formData.value.owner_details.id = owner.id || "";
                    formData.value.owner_details.first_name = owner.first_name || "";
                    formData.value.owner_details.dob = owner.date_of_birth ? new Date(owner.date_of_birth) : null;
                    formData.value.owner_details.primary_partner = owner.primary_partner || 0;
                    formData.value.owner_details.default_address = owner.default_address || "";

                    if (owner.current_address) {
                        const currentAddress = JSON.parse(owner.current_address);
                        Object.assign(formData.value.owner_details, {
                            current_postcode: currentAddress.current_postcode || "",
                            current_address_line_1: currentAddress.current_address_line_1 || "",
                            current_address_line_2: currentAddress.current_address_line_2 || "",
                            current_address_line_3: currentAddress.current_address_line_3 || "",
                            current_address_line_4: currentAddress.current_address_line_4 || "",
                            years_at_current_address: currentAddress.years_at_current_address || "",
                        });
                    }

                    if (owner.previous_address) {
                        const previousAddress = JSON.parse(owner.previous_address);
                        Object.assign(formData.value.owner_details, {
                            previous_postcode: previousAddress.previous_postcode || "",
                            previous_address_line_1: previousAddress.previous_address_line_1 || "",
                            previous_address_line_2: previousAddress.previous_address_line_2 || "",
                            previous_address_line_3: previousAddress.previous_address_line_3 || "",
                            previous_address_line_4: previousAddress.previous_address_line_4 || "",
                            years_at_previous_address: previousAddress.years_at_previous_address || "",
                        });
                    }
                }
                formData.value.content = "";
                imagePreview.value = data.logo;
            } catch (error) {
                console.error("Error fetching organization data:", error);
            }
        };


        const handleSubmit = async () => {
            const query = route.query.add;
            const response = await store.dispatch("organisation/saveOrgData", { ...formData.value, company_incorporated_date: changeFormate(formData.value.company_incorporated_date), person: { ...formData.value.person, date_of_birth: changeFormate(formData.value.person.date_of_birth), marketing_prefrences: formData.value.person.marketing_prefrences.toString() }, owner_details: { ...formData.value.owner_details, date_of_birth: changeFormate(formData.value.owner_details.dob) }, payment_details: { ...formData.value.payment_details, direct_debit_start_date: changeFormate(formData.value.payment_details.direct_debit_start_date) } });
            if (response.status) {
                handleCancel();
            } else {
                apiErrors.value = response.errors;
                message.error(apiErrors.value);
            }
        };
        const handleCancel = () => {
            const query = route.query.add;
            // const kanban = route.query.kanban;
            if (query === "opp") {
                router.push("/crm/create-meter-opportunity");
            } else if (query === "act") {
                router.push("/crm/create-activity");
            } else if (query === "contract") {
                router.push("/crm/create-contract");
            } else if (query === "dashboard") {
                router.push("/dashboard");
            } else {
                router.push("/crm/organisation-list");
            }
        };
        const handleImageUpload = (event) => {
            const file = event.target.files[0];
            if (file) {
                formData.value.logo = file;
                const reader = new FileReader();
                reader.onload = (e) => {
                    imagePreview.value = e.target.result; // Show the image preview
                };
                reader.readAsDataURL(file); // Convert the file to a data URL
            }
        };

        const removeImage = () => {
            formData.value.logo = ""; // Clear the image URL in the form data
            imagePreview.value = ""; // Clear the image preview
        };
        // Watch for changes in the selected territory

        const fetchOptions = async () => {
            try {
                const response = await apiClient.get("settings/organisation-person");
                settingOptions.value = response.data.data.dropdowns;
            } catch (error) {
                console.error("Error fetching options data:", error);
            }
        };

        const onPersonChange = () => {
            const selectedPerson = personData.value.find(
                (person) => person.id === formData.value.person.id
            );
            if (selectedPerson) {
                formData.value.person.first_name = selectedPerson.first_name;
                formData.value.person.last_name = selectedPerson.last_name;
                formData.value.person.email = selectedPerson.email;
                formData.value.person.salutation = selectedPerson.salutation || "";
                formData.value.person.mobile_telephone = selectedPerson.mobile_telephone || "";
                formData.value.person.job_title = selectedPerson.job_title || "";
                formData.value.person.customer_direct_debit_day = selectedPerson.customer_direct_debit_day || "";
                formData.value.person.customer_payment_method = selectedPerson.customer_payment_method || "";
                formData.value.person.direct_line= selectedPerson.direct_line,
                formData.value.person.landline= selectedPerson.landline,
                formData.value.person.marketing_prefrences= selectedPerson.marketing_prefrences ? selectedPerson.marketing_prefrences.split(',') : []
                if (selectedPerson.date_of_birth) {
                    formData.value.person.date_of_birth = new Date(selectedPerson.date_of_birth);
                }
                else {
                    formData.value.person.date_of_birth = null
                }
                date_of_birth.value = new Date(selectedPerson.date_of_birth)
            } else {
                formData.value.person.first_name = "";
                formData.value.person.last_name = "";
                formData.value.person.email = "";
                formData.value.person.salutation = "";
                formData.value.person.mobile_telephone = "";
                formData.value.person.job_title = "";
            }
        };

        const onOwnerChange = () => {
            const selectedOwner = ownerData.value.find(
                (owner) => owner.id === formData.value.owner_details.id
            );

            if (selectedOwner) {
                // Update owner details
                formData.value.owner_details.first_name = selectedOwner.first_name || "";
                formData.value.owner_details.dob = selectedOwner.dob
                    ? new Date(selectedOwner.dob)
                    : null;
                formData.value.owner_details.primary_partner = selectedOwner.primary_partner || 0;
                formData.value.owner_details.default_address = selectedOwner.default_address || "";
                formData.value.owner_details.years_at_current_address = selectedOwner.years_at_current_address || "";
                formData.value.owner_details.current_address_line_1 = selectedOwner.current_address_line_1 || "";
                formData.value.owner_details.current_address_line_2 = selectedOwner.current_address_line_2 || "";
                formData.value.owner_details.current_address_line_3 = selectedOwner.current_address_line_3 || "";
                formData.value.owner_details.current_address_line_4 = selectedOwner.current_address_line_4 || "";
                formData.value.owner_details.current_postcode = selectedOwner.current_postcode || "";
                formData.value.owner_details.years_at_previous_address = selectedOwner.years_at_previous_address || "";
                formData.value.owner_details.previous_address_line_1 = selectedOwner.previous_address_line_1 || "";
                formData.value.owner_details.previous_address_line_2 = selectedOwner.previous_address_line_2 || "";
                formData.value.owner_details.previous_address_line_3 = selectedOwner.previous_address_line_3 || "";
                formData.value.owner_details.previous_address_line_4 = selectedOwner.previous_address_line_4 || "";
                formData.value.owner_details.previous_postcode = selectedOwner.previous_postcode || "";
            } else {
                // Reset owner details if no match found
                formData.value.owner_details = {
                    id: "",
                    first_name: "",
                    dob: null,
                    primary_partner: 0,
                    default_address: "",
                    years_at_current_address: "",
                    current_address_line_1: "",
                    current_address_line_2: "",
                    current_address_line_3: "",
                    current_address_line_4: "",
                    current_postcode: "",
                    years_at_previous_address: "",
                    previous_address_line_1: "",
                    previous_address_line_2: "",
                    previous_address_line_3: "",
                    previous_address_line_4: "",
                    previous_postcode: "",
                };
            }
        };


        watch(
            () => formData.value.territory_id,
            async (newTerritory) => {
                if (newTerritory) {
                    store.dispatch("organisation/fetchTerritoryCompanyUsers", [newTerritory]);
                    // store.dispatch('organisation/fetchAssignedToUsers', [newTerritory]);
                } else {
                    Responsibles.value = []; // Clear Responsible if no territory selected
                    assignedToOptions.value = [];
                }
            }, {
            immediate: true,
        } // Immediate to handle initial load if needed
        );

        onMounted(async () => {
            const orgId = route.query.org_Id;
            if (orgId) {
                fetchOrgData(orgId);
            }
            store.dispatch("organisation/fetchDropdownOptions");
            store.dispatch("organisation/fetchTerritories");
            store.dispatch("organisation/fetchCountries");
            await fetchOptions();
        });

        return {
            schema,
            formData,
            organisationData,
            org_id,
            handleSubmit,
            handleCancel,
            isEditing,
            Territories,
            Responsibles,
            Countries,
            handleImageUpload,
            removeImage,
            imagePreview,
            assignedToOptions,
            options,
            apiErrors,
            logs,
            formattedTelephone,
            personData,
            fetchOptions,
            settingOptions,
            onPersonChange,
            date_of_birth,
            clearDate,
            isTps,
            dob,
            ownerData,
            onOwnerChange,
            loaData,
            orgPersons,
        };
    },
};
</script>
<style>
.logs_Section {
    max-height: 320px;
    overflow-x: hidden;
}

.organisation {
    display: flex;
    width: 100%;
    /* flex-wrap: nowrap; */
    justify-content: space-between;
    gap: 1rem;
    align-items: stretch;
    ;
}

.organisation-detail {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    gap: 1rem;
    width: 100%;
    border: 1px solid #ccc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    border-radius: 8px;
    background-color: #fff;
    margin: 0rem !important;
}


.form-wrap {
    width: 100% !important;
}

.company-field {
    /* height: 100px; */
    padding-left: 0 !important;
    margin: 0 !important;
}

/* @media (min-width: 768px) {
  .form-wrap {
    width: calc(50% - 1rem);
  }
} */

.toggle.ios,
.toggle-on.ios,
.toggle-off.ios {
    border-radius: 20px;
}

.toggle.ios .toggle-handle {
    border-radius: 20px;
}

.loa-section {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 1rem;
    width: 100%;
}

.nowrap-label {
    white-space: nowrap;
    overflow: hidden;
    /* Optional: to handle overflowing text */
    text-overflow: ellipsis;
    /* Optional: adds '...' for overflowing text */
}
</style>
