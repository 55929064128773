<template>
    <layouts-header></layouts-header>
    <layouts-sidebar></layouts-sidebar>
    <div class="page-wrapper">
        <div class="content">
            <div class="row align-items-center mb-5">
                <div class="col-md-6">
                    <div class="col-md-12">
                        <p style="text-transform: capitalize; margin-bottom: 10px;">{{ moduleName }}</p>
                        <h3>{{ reportName }}</h3>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text-end">
                        <router-link :to="{
                            path: '/crm/report-edit', query: {
                                reportId: reportId,
                                moduleId: moduleId,
                            }
                        }" class="btn table_action_btn me-3"><i class="ti ti-edit"></i> Overview
                            Configuration</router-link>
                        <router-link :to="{
                            path: '/crm/overview/report', query: {
                                modN: moduleName,
                                repN: reportName,
                                reportId: reportId,
                                moduleId: moduleId,
                            },
                        }" class="btn table_action_btn"><i class="ti ti-edit"></i> Report</router-link>
                    </div>
                </div>
            </div>

            <div class="table-responsive custom-table" v-if="chartSeries[0]?.data.length > 0">
                <div id="deals-chart">
                    <apexchart v-if="chartSeries?.length" :type="queryChartType" height="475" :options="chartOptions"
                        :series="chartSeries">
                    </apexchart>
                    <!-- <div v-else class="no-data-container">
                        <i class="no-data-icon">📄</i>
                        <p class="no-data-message">No Data Found</p>
                    </div> -->
                </div>
            </div>
            <div v-else class="no-data-container">
                <i class="no-data-icon">📄</i>
                <p class="no-data-message">No Data Found</p>
            </div>
        </div>
    </div>

    <!-- Prompt User Modal -->
    <Popup v-if="module_data.length > 0" :content="module_data" :generateReport="generateReport" />
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { ErrorMessage, Field, Form } from "vee-validate";
import Popup from './propmt-popup.vue'

export default {
    components: {
        Form,
        Field,
        ErrorMessage,
        Popup
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const reportId = ref(route.query.reportId || '')
        const moduleId = ref(route.query.moduleId || '')

        const module_data = ref([]);
        const data = ref([]);
        const chartSeries = ref([]);
        const chartOptions = ref({
            chart: {
                type: route.query.type,
            },
            title: {
                text: "",
                align: "center",
            },
        });
        const chartType = ref(route.query.type);

        const queryChartType = ref(null)

        const moduleName = ref(route.query.modN || "");
        const reportName = ref(route.query.repN || "");

        const modifiedData = ref(null)

        const generateReport = async (id, formData = []) => {
            try {
                let modifiedFormData = []
                if (formData.length > 0) {
                    formData.map((data) => {
                        return data.map((items) => {
                            const { module_name, field_name, ...item } = items;
                            modifiedFormData.push(item)
                        })
                    })
                }
                modifiedData.value = modifiedFormData
                const responseData = await store.dispatch("report/generateReport", { id, modifiedData: modifiedData.value });
                data.value = responseData;
                processReportData();
            } catch (error) {
                console.error("Error fetching report data:", error);
            }
        };

        const processReportData = () => {
            if (module_data.value.length > 0) {
                const dynamicValues = [];
                const dynamicLabels = [];
                const fieldId = route.query.field_id

                module_data.value.forEach((org) => {
                    org.children.forEach((item) => {
                        if (item.id == fieldId) {
                            const countMap = new Map();

                            data.value?.forEach((row) => {
                                const fieldValue = row[item.field];
                                if (fieldValue !== null && fieldValue !== undefined) {
                                    if (countMap.has(fieldValue)) {
                                        countMap.set(fieldValue, countMap.get(fieldValue) + 1);
                                    } else {
                                        countMap.set(fieldValue, 1);
                                    }
                                }
                            });
                            countMap.forEach((count, label) => {
                                if (label !== null && label !== undefined) {
                                    dynamicLabels.push(label);
                                    dynamicValues.push(count);
                                }
                            });
                            if (chartType.value === 'bar' && route.query.type !== 'column') {

                                const funnelData = [];

                                const labelCount = {};

                                data.value.forEach((row) => {
                                    const fieldValue = row[item.field];

                                    if (fieldValue !== null && fieldValue !== undefined) {
                                        if (labelCount[fieldValue]) {
                                            labelCount[fieldValue] += 1;
                                        } else {
                                            labelCount[fieldValue] = 1;
                                        }
                                    }
                                });

                                for (const [key, value] of Object.entries(labelCount)) {
                                    if (key !== null && key !== undefined) {
                                        funnelData.push({ x: key, y: value });
                                    }
                                }

                                chartSeries.value = [
                                    {
                                        name: "Funnel Series",
                                        data: funnelData,
                                    },
                                ];


                                chartOptions.value = {
                                    chart: {
                                        type: 'bar',
                                        height: 200,
                                    },
                                    plotOptions: {
                                        bar: {
                                            borderRadius: 0,
                                            horizontal: true,
                                            barHeight: '80%',
                                            isFunnel: true,
                                            distributed: true,
                                        },
                                    },
                                    dataLabels: {
                                        enabled: true,
                                        formatter: function (val, opt) {
                                            return `${opt.w.globals.labels[opt.dataPointIndex]}: ${val}`;
                                        },
                                        dropShadow: {
                                            enabled: true,
                                        },
                                    },
                                    title: {
                                        text: `Report: Field-${item.label}`,
                                        align: 'center',
                                    },
                                    labels: funnelData.map(item => item.x),
                                    legend: {
                                        show: false,
                                    },
                                }

                            } else if (chartType.value === 'column') {


                                chartSeries.value = [
                                    {
                                        name: item.label,
                                        data: dynamicValues,
                                    },
                                ];

                                chartOptions.value = {
                                    xaxis: {
                                        categories: dynamicLabels,
                                    },
                                    chart: {
                                        type: 'bar',
                                        height: 350,
                                    },
                                    plotOptions: {
                                        bar: {
                                            horizontal: false,
                                            distributed: true,
                                            columnWidth: '30%',
                                        },
                                    },
                                    title: {
                                        text: `Report: Field-${item.label}`,
                                        align: "center",
                                    },
                                };
                            }
                            else if (["pie", "donut"].includes(chartType.value)) {
                                chartSeries.value = dynamicValues;
                                chartOptions.value = {
                                    labels: dynamicLabels,
                                    chart: {
                                        type: chartType.value,
                                    },
                                    title: {
                                        text: `Report: Field-${item.label}`,
                                        align: "center",
                                    },
                                };
                            }

                            else {
                                chartSeries.value = [
                                    {
                                        name: item.label,
                                        data: dynamicValues,
                                    },
                                ];
                                chartOptions.value = {
                                    xaxis: {
                                        categories: dynamicLabels,
                                    },
                                    chart: {
                                        type: chartType.value,
                                    },
                                    title: {
                                        text: `Report: Field-${item.label}`,
                                        align: "center",
                                    }
                                };
                            }

                        }
                    });
                });
            }
        };


        const fetchModuleData = async (id) => {
            try {
                const moduleResponse = await store.dispatch("report/fetchModuleData", id);
                module_data.value = moduleResponse;
            } catch (error) {
                console.error("Error fetching module data:", error);
            }
        };


        onMounted(async () => {
            const reportId = route.query.reportId;
            const moduleId = route.query.moduleId;

            if (reportId && moduleId) {
                if (route.query.type === 'column') {
                    queryChartType.value = 'bar';
                } else if (route.query.type === 'bar') {
                    queryChartType.value = 'bar';
                } else {
                    queryChartType.value = route.query.type;
                }

                try {
                    await fetchModuleData(moduleId);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
        });

        return {
            chartSeries,
            chartOptions,
            moduleName,
            reportName,
            moduleId,
            reportId,
            queryChartType,
            generateReport,
            modifiedData,
            module_data

        };
    },
};
</script>

<style scoped>
.no-data-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    /* border: 1px dashed #ccc; */
    border-radius: 8px;
    background-color: #f9f9f9;
    color: #555;
}

.no-data-icon {
    font-size: 40px;
    margin-bottom: 10px;
    color: #999;
}

.no-data-message {
    font-size: 16px;
    font-weight: 500;
    color: #666;
}
</style>
