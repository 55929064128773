<template>
  <a-table
    class="stripped table-hover"
    :columns="columns"
    :data-source="data"
    :row-selection="rowSelection"
    :pagination="pagination"
    @change="handleTableChange"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'date_and_hour'">
          {{ record.date_and_hour }}
      </template>
      <template v-else-if="column.key === 'org_name'">
      <router-link :to="{ path: '/crm/organisation-details', query: { org_Id: record.orgId }}" class="profile-split d-flex flex-column">
          {{ record.org_name }}
        </router-link>
    </template>
      <template v-else-if="column.key === 'action'">
        <div class="icon-field">
            <router-link :to="{ path: '/crm/edit-activity', query: {territory_id: record.terr_id, org_Id: record.orgId, actId: record.key }}" class="btn table_action_btn"><i class="ti ti-edit"></i> Edit</router-link>
        </div>
      </template>
    </template>
  </a-table>
</template>

<script>
import {
  ref,
  watchEffect
} from 'vue';
import {
  useStore
} from "vuex";
import {
  useRouter,
  useRoute
} from 'vue-router';
const columns = [{
    title: "Date and hour",
    dataIndex: "date_and_hour",
    key: "date_and_hour",
    sorter: {
      compare: (a, b) => {
        a = a.number;
        b = b.number;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Org. Name",
    dataIndex: "org_name",
    key: "org_name",
    sorter: {
      compare: (a, b) => {
        a = a.org_name;
        b = b.org_name;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Org.  Post code/ZIP",
    dataIndex: "postcode",
    sorter: {
      compare: (a, b) => {
        a = a.postcode;
        b = b.postcode;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Org. City",
    dataIndex: "org_city",
    sorter: {
      compare: (a, b) => {
        a = a.org_city;
        b = b.org_city;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Per. Full name",
    dataIndex: "per_full_name",
    sorter: {
      compare: (a, b) => {
        a = a.per_full_name;
        b = b.per_full_name;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Activity Type",
    dataIndex: "activity_type",
    sorter: {
      compare: (a, b) => {
        a = a.activity_type;
        b = b.activity_type;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    sorter: {
      compare: (a, b) => {
        a = a.description;
        b = b.description;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    dataIndex: "Action",
    key: "action",
  },
];
export default {
  props: {
    searchActQuery: String,
    filters: Object,
  },
  data() {
    return {
      columns,
      data: [],
      selectedUserId: null,
      showDeleteModal: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      rowSelection: {
        onChange: () => {},
        onSelect: () => {},
        onSelectAll: () => {},
      },
    };
  },

  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const data = ref([]);
    const pagination = ref({
      current: 1,
      pageSize: 10,
      total: 0,
    });

    const fetchActivities = async () => {
      const params = {
        search: props.searchActQuery, // Use the correct prop
        date_filter: props.filters.timeFrame,
        page: pagination.value.current,
        pageSize: pagination.value.pageSize,
      };
      const response = await store.dispatch('activities/fetchActivities', params);
      if (response) {
        data.value = response.activities.map((act) => ({
          key: act.id,
          terr_id: act.organization?.territory_id ? act.organization?.territory_id : '',
          date_and_hour: (act.date ? formatDate(act.date) : '') + ' ' + (act.time ? act.time : ''),
          org_name: act.organization?.name ? act.organization?.name : '',
          orgId: act.organization.id,
          org_city: act.organization.city ? act.organization.city : '',
          postcode: act.organization.postcode ? act.organization.postcode : '',
          description: act.description ? act.description : '',
          per_full_name: (act.organization_person?.first_name ? act.organization_person?.first_name : '')  + ' ' + (act.organization_person?.last_name ? act.organization_person?.last_name : ''),
          activity_type: act.action ? act.action : '' ,
        }));
        pagination.value.total = response.total;
      }
    };
    // Function to convert date format
   const formatDate = (date) => {
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    };
    const handleTableChange = (newPagination) => {
      pagination.value.current = newPagination.current;
      pagination.value.pageSize = newPagination.pageSize;
      fetchActivities();
    };

    watchEffect(() => {
      fetchActivities();
    });

    return {
      data,
      formatDate,
      pagination,
      fetchActivities,
      handleTableChange,
    };
  },
};
</script>
