<template>
<layouts-header></layouts-header>
<layouts-sidebar></layouts-sidebar>

<!-- Page Wrapper -->
<div class="page-wrapper">
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <!-- Page Header -->
        <page-header :title="title" />
        <!-- /Page Header -->
        <!-- v-if="Object.keys(assignedData).length > 0" -->
        <Form @submit="handleSubmit" :validation-schema="schema">
          <div class="row align-items-center py-3 mb-3 bg-white mx-0">
            <div class="col"></div>
            <div class="col-md-10">
              <!-- <div class="row mb-4">
                        <div class="col-md-4">
                          <h5 class="text-teal"><span>Total Rows:</span> <span>{{ assignedData.total_rows }}</span></h5>
                        </div>
                        <div class="col-md-4 text-center">
                          <h5 class="text-danger"><span>Unassigned Rows: </span> <span>{{ assignedData.total_rows - assignedData.assgined_rows_count }}</span></h5>
                        </div>
                        <div class="col-md-4 text-end">
                          <h5 class="text-success"><span>Assigned Rows: </span> <span>{{ assignedData.assgined_rows_count }}</span></h5>
                        </div>
                      </div> -->
              <div v-for="(row, index) in rows" :key="index" class="row position-relative">
                <div class="col-md-3">
                  <label class="col-form-label">Start</label>
                  <Field :name="'rows[' + index + '].start'" as="input" type="number" class="form-control" v-model="row.start" />
                  <ErrorMessage :name="'rows[' + index + '].start'" class="text-danger" />
                </div>
                <div class="col-md-3">
                  <label class="col-form-label">End</label>
                  <Field :name="'rows[' + index + '].end'" as="input" type="number" class="form-control" v-model="row.end" />
                  <ErrorMessage :name="'rows[' + index + '].end'" class="text-danger" />
                </div>
                <div class="col-md-3">
                  <div class="form-wrap">
                    <label class="col-form-label">Territory</label>
                    <Field as="select" :name="'rows[' + index + '].territory_id'" class="form-select" v-model="row.territory_id" @change="handleTerritoryChange(index)">
                      <option value="">-----Select-----</option>
                      <option v-for="ter in Territories" :key="ter.id" :value="ter.id">{{ ter.name }}</option>
                    </Field>
                    <ErrorMessage :name="'rows[' + index + '].territory_id'" class="text-danger" />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-wrap">
                    <label class="col-form-label">Responsible</label>
                    <Field as="select" :name="'rows[' + index + '].responsible'" class="form-select" v-model="row.responsible">
                      <option value="">-----None-----</option>
                      <option v-for="res in row.responsibles" :key="res.id" :value="res.id">{{ res.name }}</option>
                    </Field>
                    <ErrorMessage :name="'rows[' + index + '].responsible'" class="text-danger" />
                  </div>
                </div>

                <!-- Conditional Delete Button for all rows except the first -->
                <div class="mt-2 position-absolute delete_row" v-if="index > 0">
                  <a href="#" @click="deleteRow(index)"><i class="ion-close-round text-danger" data-bs-toggle="tooltip" title="Delete Row"></i></a>
                  <!-- <button type="button" class="btn btn-danger" @click="deleteRow(index)">Delete Row</button> -->
                </div>
              </div>

              <!-- Button to Add New Row -->
              <div class="row align-items-center">
                <div class="col-md-8">
                </div>
                <div class="col-md-4 text-end">
                  <button @click="addNewRow" type="button" class="btn btn-primary py-2 me-2">Add Row</button>
                  <button type="submit" class="btn btn-teal">Assign</button>
                </div>
              </div>
            </div>
            <div class="col"></div>
          </div>
        </Form>
        <div class="card main-card">
          <div class="card-body">

            <!-- Leads List -->
            <div class="table-responsive custom-table">
              <leads-table ref="childRef"></leads-table>
            </div>
            <div class="row align-items-center">
              <div class="col-md-6">
                <div class="datatable-length"></div>
              </div>
              <div class="col-md-6">
                <div class="datatable-paginate"></div>
              </div>
            </div>
            <!-- /Leads List -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  ref,
  onMounted,
  watch,
  computed
} from "vue";
import {
  useStore
} from "vuex";
import {
  useRoute,
  useRouter
} from "vue-router";
import {
  Form,
  ErrorMessage,
  Field
} from "vee-validate";
import * as Yup from "yup";

export default {
  components: {
    Form,
    Field,
    ErrorMessage
  },
  data() {
    return {
      title: "Leads",
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const childRef = ref(null);
    const file_id = ref(route.query.fileId || localStorage.getItem('fileId') || null);
    // Reactive state
    const rows = ref([{
      territory_id: '',
      responsible: '',
      start: '',
      end: '',
      responsibles: []
    }]);

    const Territories = computed(() => store.getters['organisation/getTerritories']);
    // Validation schema using yup
    const schema = Yup.object().shape({
      rows: Yup.array().of(
        Yup.object().shape({
          start: Yup.string().required('Start is required'),
          end: Yup.string().required('End is required'),
          territory_id: Yup.string().required('Territory is required'),
          responsible: Yup.string().required('Responsible is required'),
        })
      ),
    });

    const assignedData = ref({});

    // Fetch territories on mount
    onMounted(() => {
      store.dispatch('organisation/fetchTerritories')
    });

    // Handle the territory change
    const handleTerritoryChange = async (index) => {
      const selectedTerritoryId = rows.value[index].territory_id;
      if (selectedTerritoryId) {
        try {
          // Fetch the responsible users for the selected territory
          const response = await store.dispatch('organisation/fetchTerritoryCompanyUsers', [selectedTerritoryId]);
          // Only update the responsibles for the current row
          rows.value[index].responsibles = response || [];
          rows.value[index].responsible = ''; // Reset responsible selection
        } catch (error) {
          console.error("Error fetching responsibles:", error);
        }
      }
    };

    // Method to add a new row
    const addNewRow = () => {
      rows.value.push({
        territory_id: '',
        responsible: '',
        start: '',
        end: '',
        responsibles: []
      });
    };

    const handleSubmit = async () => {
      const assignData = rows.value.map((row) => ({
        from: row.start,
        to: row.end,
        territory_id: row.territory_id,
        assign_to: row.responsible,
      }));
      const data = await store.dispatch('leads/assignLeads', {
        assign_data: assignData,
      });
      if (childRef.value) {
        childRef.value.fetchLeadsData();
      }
    };

    // Method to delete a row (except the first row)
    const deleteRow = (index) => {
      rows.value.splice(index, 1); // Removes the row at the given index
    };

    return {
      childRef,
      handleSubmit,
      schema,
      handleTerritoryChange,
      file_id,
      Territories,
      rows,
      addNewRow,
      deleteRow,
      assignedData
    }
  }
};
</script>

<style lang="scss" scoped>
.delete_row {
  right: -30px;
  top: 25px;
  width: 25px;
  height: 25px;
  border: 1px solid red;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    margin-left: 1px;
  }
}
</style>
