<template>
  <div class="col-xxl-3 col-xl-4 col-md-6" v-for="item in ContactGrid" :key="item.id">
    <div class="contact-grid">
      <div class="grid-head">
        <div class="users-profile">
          <router-link to="/crm/report-details" class="avatar">
            <img
              :src="require(`@/assets/img/profiles/${item.customer_image}`)"
              alt="img"
            />
          </router-link>
          <div class="name-user">
            <h6>
              <router-link to="/crm/report-details">{{ item.customer_name }}</router-link>
            </h6>
            <p>{{ item.customer_no }}</p>
          </div>
        </div>
        <div class="dropdown table-action">
          <a
            href="javascript:;"
            class="action-icon"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa fa-ellipsis-v"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-right" style="">
            <a class="dropdown-item edit-popup" href="javascript:;"
              ><i class="ti ti-edit text-blue"></i> Edit</a
            >
            <a
              class="dropdown-item"
              href="javascript:;"
              data-bs-toggle="modal"
              data-bs-target="#delete_contact"
              ><i class="ti ti-trash text-danger"></i> Delete</a
            >
            <router-link class="dropdown-item" to="/crm/report-details"
              ><i class="ti ti-eye text-blue-light"></i> Preview</router-link
            >
          </div>
        </div>
      </div>
      <div class="grid-body">
        <div class="address-info">
          <p><i class="ti ti-mail"></i>{{ item.email }}</p>
          <p><i class="ti ti-phone"></i>{{ item.phone }}</p>
          <p><i class="ti ti-map-pin-pin"></i>{{ item.location }}</p>
        </div>
        <div class="grid-badges">
          <span class="badge badge-tag badge-success-light">Collab</span>
          <span class="badge badge-tag badge-warning-light">Rated</span>
        </div>
      </div>
      <div class="grid-footer d-flex justify-content-between">
        <ul class="social-links d-flex align-items-center">
          <li>
            <a href="javascript:;"><i class="ti ti-mail"></i></a>
          </li>
          <li>
            <a href="javascript:;"><i class="ti ti-phone-check"></i></a>
          </li>
          <li>
            <a href="javascript:;"><i class="ti ti-message-circle-share"></i></a>
          </li>
          <li>
            <a href="javascript:;"><i class="ti ti-brand-skype"></i></a>
          </li>
          <li>
            <a href="javascript:;"><i class="ti ti-brand-facebook"></i></a>
          </li>
        </ul>
        <div class="star-user">
          <div class="set-star">
            <i class="fa fa-star filled me-1"></i>{{ item.rating }}
          </div>
          <a
            href="javascript:void(0);"
            class="avatar"
            data-bs-toggle="tooltip"
            data-bs-original-title="Mervin"
            data-bs-placement="top"
          >
            <img :src="require(`@/assets/img/profiles/${item.owner_image}`)" alt="img" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactGrid from "@/assets/json/contacts.json";
export default {
  data() {
    return {
      ContactGrid: ContactGrid,
    };
  },
  mounted() {
    if (document.getElementsByClassName("edit-popup").length > 0) {
      var addPopups = document.getElementsByClassName("edit-popup");
      for (var i = 0; i < addPopups.length; i++) {
        addPopups[i].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarCloses = document.getElementsByClassName("sidebar-close1");
      for (var j = 0; j < sidebarCloses.length; j++) {
        sidebarCloses[j].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }
  },
};
</script>
