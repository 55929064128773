<template>
<a-table class="stripped table-hover" :columns="columns" :data-source="data" :pagination="pagination" :row-selection="rowSelection" @change="handleTableChange">
  <template #bodyCell="{ column, record }">
    <template v-if="column.key === 'Name'">
      <h2 class="table-avatar d-flex align-items-center">
        <router-link :to="{ path: '/pipelines/create-pipeline', query: {userId: record.key}}" class="profile-split d-flex flex-column">{{ record.name }}</router-link>
      </h2>
    </template>
    <template v-else-if="column.key === 'action'">
          <router-link :to="{ path: '/pipelines/create-pipeline', query: {userId: record.key}}" class="btn table_action_btn"><i class="ti ti-edit"></i> Edit</router-link>
          <!-- <a class="btn table_action_btn ms-2" href="javascript:;" @click="confirmDelete(record.key)">
            <i class="ti ti-trash text-danger"></i> Delete
          </a> -->
    </template>
  </template>
</a-table>

<!-- <div class="modal-backdrop fade show" v-if="showDeleteModal"></div>
<div v-if="showDeleteModal" class="modal custom-modal fade show" style="display: block;" tabindex="-1" win_probability="dialog" aria-modal="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header border-0 m-0 justify-content-end">
        <button class="btn-close" @click="showDeleteModal = false" aria-label="Close">
          <i class="ti ti-x"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="success-message text-center">
          <div class="success-popup-icon">
            <i class="ti ti-trash-x"></i>
          </div>
          <h3>Delete Account</h3>
          <p class="del-info">Are you sure you want to delete?</p>
          <div class="col-lg-12 text-center modal-btn">
            <button class="btn btn-light" @click="showDeleteModal = false">Cancel</button>
            <button class="btn btn-danger" @click="deleteUser(selectedUserId)">Yes, Delete it</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
</template>

<script>
import {
  ref,
  reactive,
  onMounted
} from 'vue';
import {
  useRouter
} from 'vue-router';
import apiClient from "@/axios";
import {
  message
} from "ant-design-vue";

export default {
  props: {
    searchUserQuery: String,
    // filters: Object,
  },
  setup(props) {
    const router = useRouter();

    // Reactive state
    const columns = [{
        title: "Name",
        dataIndex: "name",
        key: "Name",
        sorter: {
          compare: (a, b) => a.name.localeCompare(b.name)
        }
      },
      {
        title: "Win Probability",
        dataIndex: "win_probability",
        sorter: {
          compare: (a, b) => a.win_probability.localeCompare(b.win_probability)
        }
      },
      {
        title: "Follow Up Days",
        dataIndex: "follow_up_days",
        // sorter: {
        //   compare: (a, b) => a.follow_up_days.localeCompare(b.follow_up_days)
        // }
      },
      {
        title: "Action",
        dataIndex: "Action",
        key: "action",
        sorter: false
      },
    ];

    const data = ref([]);
    const pagination = ref({
      current: 1,
      pageSize: 10,
      total: 0
    });
    const rowSelection = reactive({
      onChange: () => {},
      onSelect: () => {},
      onSelectAll: () => {},
    });
    const showDeleteModal = ref(false);
    const selectedUserId = ref(null);

    const fetchPipelinesLevel = async (page = 1, pageSize = 10) => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          return;
        }
        const response = await apiClient.get("/pipeline-level/list", {
          params: {
            // search: props.searchUserQuery,
            page,
            pageSize
          }
        });
        
        if (response.status) {
          data.value = response.data.data.data.map((pipeline) => ({
            key: pipeline.id,
            name: pipeline.name,
            win_probability: pipeline.win_probability,
            follow_up_days: pipeline.follow_up_days,
          }));
          pagination.value.total = response.data.total;
        } else {
          console.error("Failed to fetch users:", response);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    const deleteUser = async (id) => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          message.error("No token found. Please log in again.");
          return;
        }
        const response = await apiClient.delete(`company/users/delete/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
        });
        if (response.status) {
          message.success("User deleted successfully!");
          showDeleteModal.value = false;
          fetchPipelinesLevel(pagination.value.current, pagination.value.pageSize);
        } else {
          message.error("Failed to delete User.");
        }
      } catch (error) {
        console.error("Error deleting User:", error);
        message.error("Error deleting User.");
      }
    };
    const confirmDelete = (id) => {
      selectedUserId.value = id;
      showDeleteModal.value = true;
    };

    const handleTableChange = (newPagination) => {
      pagination.value.current = newPagination.current;
      fetchPipelinesLevel(pagination.value.current, newPagination.pageSize);
    };

    // Lifecycle hook
    onMounted(() => {
      fetchPipelinesLevel();
    });

    return {
      columns,
      data,
      pagination,
      rowSelection,
      showDeleteModal,
      selectedUserId,
      fetchPipelinesLevel,
      deleteUser,
      confirmDelete,
      handleTableChange,
    };
  },
};
</script>
