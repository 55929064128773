<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" />

          <div class="row">
            <div class="col-xl-12 col-lg-12">
              <div class="contact-head">
                <div class="row align-items-center">
                  <div class="col-sm-6">
                    <ul class="contact-breadcrumb">
                      <li>
                        <router-link to="/companies/companies-list"
                          ><i class="ti ti-arrow-narrow-left"></i>Companies</router-link
                        >
                      </li>
                      <li>{{ isEditing ? "Edit Company" : "Create New Company" }}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- Company Settings -->
              <div class="card">
                <div class="card-body">
                  <div class="settings-header">
                    <h4>Create Company</h4>
                  </div>
                  <div class="settings-form">
                    <Form @submit="registerUser" :validation-schema="schema">
                      <div class="settings-sub-header">
                        <h6>Company Information</h6>
                        <p>Provide the information below</p>
                      </div>
                      <div class="profile-details pb-3">
                        <div class="row mb-3">
                          <div class="col-md-6">
                            <div class="form-wrap">
                              <div class="profile-upload">
                                <div class="profile-upload-img">
                                  <span v-if="!imagePreview"
                                    ><i class="ti ti-photo"></i
                                  ></span>
                                  <img
                                    v-if="imagePreview"
                                    :src="imagePreview"
                                    class="img-fluid"
                                  />
                                  <button
                                    v-if="imagePreview"
                                    type="button"
                                    class="profile-remove"
                                    @click="removeImage"
                                  >
                                    <i class="feather-x"></i>
                                  </button>
                                </div>
                                <div class="profile-upload-content">
                                  <label class="profile-upload-btn">
                                    <i class="ti ti-file-broken"></i> Upload Logo
                                    <input
                                      type="file"
                                      class="input-img"
                                      @change="handleImageUpload"
                                    />
                                  </label>
                                  <p>Upload User Logo (In jpeg, png, jpg).</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label">
                                Company Name <span class="text-danger">*</span>
                              </label>
                              <Field
                                name="company_name"
                                as="input"
                                type="text"
                                class="form-control"
                                v-model="form.company_name"
                              />
                              <ErrorMessage name="company_name" class="text-danger" />
                              <span
                                class="text-danger"
                                v-if="
                                  apiErrors &&
                                  apiErrors['company_name'] &&
                                  apiErrors['company_name'].length
                                "
                                >{{ apiErrors["company_name"][0] }}</span
                              >
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label"> Company Address </label>
                              <Field
                                name="company_address"
                                as="input"
                                type="text"
                                class="form-control"
                                v-model="form.company_address"
                              />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label"> Phone Number </label>
                              <Field
                                name="company_phone_number"
                                as="input"
                                v-model="form.company_phone_number"
                                type="text"
                                class="form-control"
                              />
                              <span
                                class="text-danger"
                                v-if="
                                  apiErrors &&
                                  apiErrors['company_phone_number'] &&
                                  apiErrors['company_phone_number'].length
                                "
                                >{{ apiErrors["company_phone_number"][0] }}</span
                              >
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label"
                                >Company Email <span class="text-danger">*</span></label
                              >
                              <Field
                                name="company_email"
                                as="input"
                                v-model="form.company_email"
                                type="email"
                                class="form-control"
                              />
                              <ErrorMessage name="company_email" class="text-danger" />
                              <span
                                class="text-danger"
                                v-if="
                                  apiErrors &&
                                  apiErrors['company_email'] &&
                                  apiErrors['company_email'].length
                                "
                                >{{ apiErrors["company_email"][0] }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="profile-details pb-3">
                        <div class="settings-sub-header">
                          <h6>User Details</h6>
                          <p>Please enter the user details</p>
                        </div>
                        <div class="row">
                          <div class="col-lg-4 col-md-6">
                            <div class="form-wrap">
                              <label class="col-form-label">
                                First Name <span class="text-danger">*</span>
                              </label>
                              <Field
                                name="user_first_name"
                                as="input"
                                type="text"
                                class="form-control"
                                v-model="form.admin_user.user_first_name"
                              />
                              <ErrorMessage name="user_first_name" class="text-danger" />
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6">
                            <div class="form-wrap">
                              <label class="col-form-label">Last Name</label>
                              <Field
                                name="last_name"
                                as="input"
                                v-model="form.admin_user.user_last_name"
                                type="text"
                                class="form-control"
                              />
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6">
                            <div class="form-wrap">
                              <label class="col-form-label"> Phone Number </label>
                              <Field
                                name="user_phone_number"
                                as="input"
                                v-model="form.admin_user.user_phone_number"
                                type="text"
                                class="form-control"
                              />
                              <!-- <ErrorMessage name="user_phone_number" class="text-danger" /> -->
                              <span
                                class="text-danger"
                                v-if="
                                  apiErrors &&
                                  apiErrors['admin_user.user_phone_number'] &&
                                  apiErrors['admin_user.user_phone_number'].length
                                "
                                >{{ apiErrors["admin_user.user_phone_number"][0] }}</span
                              >
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6">
                            <div class="form-wrap">
                              <label class="col-form-label"
                                >Email <span class="text-danger">*</span></label
                              >
                              <Field
                                name="user_email"
                                as="input"
                                v-model="form.admin_user.user_email"
                                type="email"
                                class="form-control"
                              />
                              <ErrorMessage name="user_email" class="text-danger" />
                              <span
                                class="text-danger"
                                v-if="
                                  apiErrors &&
                                  apiErrors['admin_user.user_email'] &&
                                  apiErrors['admin_user.user_email'].length
                                "
                                >{{ apiErrors["admin_user.user_email"][0] }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="profile-address pb-3">
                        <div class="settings-sub-header">
                          <h6>Subscription Details</h6>
                        </div>
                        <div class="row">
                          <div class="col-lg-4 col-md-6">
                            <div class="form-wrap">
                              <label class="col-form-label">
                                Days <span class="text-danger">*</span>
                              </label>
                              <Field
                                name="subscription_duration_value"
                                as="input"
                                type="number"
                                @blur="calculateExpiryDate"
                                class="form-control"
                                v-model="form.subscription_duration_value"
                                :min="1"
                              />
                              <ErrorMessage
                                name="subscription_duration_value"
                                class="text-danger"
                              />
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6">
                            <div class="form-wrap">
                              <label class="col-form-label">Expiry Date</label>
                              <div class="icon-form">
                                <date-picker
                                  placeholder=""
                                  class="datetimepicker form-control"
                                  :lower-limit="disabledDates"
                                  textInput
                                  v-model="form.subscription_end_date"
                                  :editable="true"
                                  :clearable="true"
                                  :ipnut-format="dateFormat"
                                />
                                <span class="form-icon"
                                  ><i class="ti ti-calendar-check"></i
                                ></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="profile-address pb-3" v-if="permissionArray.length > 0">
                        <div class="settings-sub-header pb-1">
                          <h6>Permissions</h6>
                        <!-- Select All Checkbox -->
                        <div class="form-check my-2">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="selectAll"
                            :checked="allSelected"
                            @change="toggleSelectAll"
                          />
                          <label class="form-check-label" for="selectAll"
                            >Select All</label
                          >
                        </div>
                        </div>

                        <div class="row m-0">
                          <div
                            v-for="permission in permissionArray"
                            :key="permission.name"
                            class="col-12 col-xs-12 col-md-2 mb-3 form-check"
                          >
                            <input
                              type="checkbox"
                              class="form-check-input"
                              :id="permission.name"
                              :value="permission.name"
                              v-model="form.permissions"
                            />
                            <label class="form-check-label" :for="permission.name">
                              {{ permission.display_name }}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="submit-button text-end">
                        <button type="submit" class="btn btn-primary">
                          {{ isEditing ? "Update" : "Create" }}
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
              <!-- /Company Settings -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import $ from "jquery";
import { changeFormate } from "../../../util";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      title: "Companies",
      dateFormat: "dd/MM/yyyy",
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isEditing = ref(false);
    const apiErrors = ref(null);
    const disabledDates = ref(new Date());
    const imagePreview = ref("");
    if (route.query.comp_id) {
      isEditing.value = true;
    }
    const permissionArray = ref([]);
    const schema = Yup.object().shape({
      company_name: Yup.string().required("Company name is required"),
      company_email: Yup.string().required("Company email is required"),
      user_first_name: Yup.string().required("First name is required"),
      user_email: Yup.string().required("User email is required"),
      subscription_duration_value: Yup.number()
        .transform((value, originalValue) => (originalValue === "" ? null : value)) // Transform empty string to null
        .min(1, "Duration Days must be at least 1")
        .required("Duration Days is required"),
    });
    const form = ref({
      id: route.query.comp_id ? route.query.comp_id : null,
      company_name: "",
      company_address: "",
      company_email: "",
      company_phone_number: "",
      subscription_duration_value: null,
      subscription_end_date: null,
      company_logo: null,
      permissions: [],
      admin_user: {
        user_first_name: "",
        user_last_name: "",
        user_email: "",
        user_phone_number: "",
      },
    });
    // Function to calculate expiry date
    const allSelected = computed(() => {
      return (
        permissionArray.value.length > 0 &&
        permissionArray.value.every((permission) =>
          form.value.permissions.includes(permission.name)
        )
      );
    });

    const toggleSelectAll = () => {
      if (allSelected.value) {
        // Deselect all
        form.value.permissions = [];
      } else {
        // Select all
        form.value.permissions = permissionArray.value.map(
          (permission) => permission.name
        );
      }
    };
    const calculateExpiryDate = () => {
      const days = parseInt(form.value.subscription_duration_value, 10);
      if (days < 1) {
        return;
      } else {
        form.value.subscription_end_date = null;
        if (!isNaN(days)) {
          const currentDate = form.value.subscription_end_date
            ? new Date(form.value.subscription_end_date)
            : new Date();
          const expiryDate = new Date(currentDate.setDate(currentDate.getDate() + days));
          form.value.subscription_end_date = expiryDate;
        }
      }
    };
    // Function to calculate subscription days when expiry date changes
    const calculateDays = () => {
      if (form.value.subscription_end_date) {
        const currentDate = new Date();
        const selectedDate = new Date(form.value.subscription_end_date);
        const timeDiff = selectedDate - currentDate; // Difference in milliseconds
        const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24)); // Convert to days
        form.value.subscription_duration_value = daysDiff >= 0 ? daysDiff : 0; // Set the value and ensure it's not negative
      }
    };
    // Assuming form is a reactive object
    const formattedTelephone = computed({
      get() {
        if (!form.value || !form.value.phone_number) {
          return "";
        }
        const number = form.value.phone_number.replace(/\D/g, "");
        if (number.length === 10) {
          return `(${number.substring(0, 3)}) ${number.substring(
            3,
            6
          )}-${number.substring(6)}`;
        } else {
          return form.value.phone_number;
        }
      },
      set(value) {
        const cleanedValue = value.replace(/\D/g, "");
        form.value.phone_number = cleanedValue.length ? cleanedValue : "";
      },
    });

    const registerUser = async () => {
      const data = await store.dispatch("users/createCompanyAdmin", {
        ...form.value,
        subscription_end_date: changeFormate(form.value.subscription_end_date),
      });

      // Handle the response data
      if (data.status) {
        router.push("/companies/companies-list");
      } else {
        apiErrors.value = data.errors;
      }
    };

    const fetchPermissions = async () => {
      try {
        const data = await store.dispatch("users/fetchCompanyPermissions");
        permissionArray.value = data.permissions || [];
        // Initialize the permissions if the form.permissions is empty
        if (!form.value.permissions.length && !isEditing.value) {
          form.value.permissions = permissionArray.value.map(
            (permission) => permission.name
          );
        }
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };
    const fetchCompany = async (id) => {
      const data = await store.dispatch("users/getCompanyData", id);
      imagePreview.value = data.company_logo;
      Object.assign(form.value, data);
      Object.assign(form.value.admin_user, data.admin_user);
      if (data.subscription_end_date) {
        form.value.subscription_end_date = new Date(data.subscription_end_date);
      }
      if (!data.permissions.length && !isEditing.value) {
        form.value.permissions = permissionArray.value.map(
          (permission) => permission.name
        );
      }
    };
    const handleImageUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        form.value.company_logo = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          imagePreview.value = e.target.result; // Show the image preview
        };
        reader.readAsDataURL(file); // Convert the file to a data URL
      }
    };

    const removeImage = () => {
      form.value.company_logo = "";
      imagePreview.value = "";
    };
    onMounted(async () => {
      fetchPermissions();
      const compId = route.query.comp_id;
      if (compId) {
        fetchCompany(compId);
      }
    });
    return {
      schema,
      imagePreview,
      allSelected,
      toggleSelectAll,
      handleImageUpload,
      fetchPermissions,
      permissionArray,
      removeImage,
      isEditing,
      disabledDates,
      formattedTelephone,
      fetchCompany,
      calculateExpiryDate,
      calculateDays,
      form,
      registerUser,
      apiErrors,
    };
  },
};
</script>

<style>
.profile-upload-img {
  width: 150px;
  height: 150px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.color-selectors {
  display: flex;
  gap: 10px;
}

.color-field {
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  transition: border-color 0.3s;
}

.color-field:hover {
  border-color: #ccc;
}

.color-field .checkmark {
  font-size: 20px;
  color: white;
}

.color-field.selected {
  border-color: #000;
}
</style>
