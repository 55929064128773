// src/store/modules/org.js
import apiClient from '@/axios';

const state = {
  personData: {},         // To store individual person data
  personOptions: [],      // To store dropdown options for persons
  orgPersons: []
};

const getters = {
  getpersonData: (state) => state.personData,
  getpersonOptions: (state) => state.personOptions,
  getorgPersons: (state) => state.orgPersons,
};

const mutations = {
  SET_PERSON_DATA(state, data) {
    state.personData = data;
  },
  SET_PERSON_DROPDOWNS(state, data) {
    state.personOptions = data;
  },
  SET_ORG_PERSON_DATA(state, data) {
    state.orgPersons = data;
  }
};

const actions = {
  async fetchOrgPersons({ commit }, id) {
    try {
      const response = await apiClient.get(`/organisation/${organistoinId.value}/persons`);
     commit('SET_ORG_PERSON_DATA', response.data)
    } catch (error) {
      console.error("Error fetching organisations persons:", error);
    }
  },

  async fetchPersonData({ commit }, id) {
    try {
      const response = await apiClient.get(`/organisation/viewPerson/${id}`);
      commit('SET_PERSON_DATA', response.data.data);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching person data:', error);
    }
  },

  async fetchPersonOptions({ commit }) {
    try {
      const response = await apiClient.get('/settings/organisation-person');
      commit('SET_PERSON_DROPDOWNS', response.data.data.dropdowns);
      return response.data;
    } catch (error) {
      console.error('Error fetching person dropdowns data:', error);
    }
  },

  async createPerson({ dispatch }, formData) {
    try {
      let response;
      if (formData.id) {
        // Check if the logo is a string, if so, set it to null
        if (typeof formData.logo === 'string') {
          formData.logo = null;
        }
        response = await apiClient.post(
          `organisation/updatePerson/${formData.id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
      } else {
        response = await apiClient.post(
          '/organisation/createPerson',
          formData
        );
      }

      return response.data;
    } catch (error) {
      console.error('Error saving organization data:', error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
