<template>
<div class="account-content">
  <div class="login-wrapper account-bg">
    <div class="login-content">
      <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
        <div class="login-user-info">
          <div class="login-logo">
            <img src="@/assets/img/anviam.png" class="img-fluid" alt="Logo" />
          </div>
          <div class="login-heading">
            <h4>Sign In</h4>
            <p>Access the CRMS panel using your email and passcode.</p>
          </div>
          <div class="form-wrap">
            <label class="col-form-label">Email Address</label>
            <div class="form-wrap-icon">
              <Field name="email" type="text" v-model="email" placeholder="Enter Your Email" class="form-control" :class="{ 'is-invalid': errors.email }" />
              <div class="invalid-feedback">{{ errors.email }}</div>
              <i class="ti ti-mail" v-if="!Object.keys(errors).length"></i>
            </div>
              <div class="emailshow text-danger" id="email"></div>
          </div>
          <div class="form-wrap">
            <label class="col-form-label">Password</label>
            <div class="pass-group">
              <Field name="password" :type="showPassword ? 'text' : 'password'" v-model="password" placeholder="Enter Your Password" class="form-control pass-input mt-2" :class="{ 'is-invalid': errors.password }" />
              <span @click="toggleShow" class="toggle-password" v-if="!Object.keys(errors).length">
                <i :class="{
                      'ti ti-eye': showPassword,
                      'ti ti-eye-off': !showPassword,
                    }"></i>
              </span>
              <div class="invalid-feedback">{{ errors.password }}</div>
              <div class="emailshow text-danger" id="password"></div>
            </div>
          </div>
          <div class="form-wrap form-wrap-checkbox">
            <div class="custom-control custom-checkbox">
              <label class="check">
                <input type="checkbox" v-model="rememberMe" />
                <span class="box"></span> Remember Me
              </label>
            </div>
            <div class="text-end">
              <router-link to="forgot-password" class="forgot-link">Forgot Password?</router-link>
            </div>
          </div>
          <div class="form-wrap">
            <button type="submit" class="btn btn-primary">Sign In</button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</div>
</template>

<script>
// import apiClient from '../../../../../src/axios';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ref, onMounted } from 'vue';
import * as Yup from 'yup';

import {
  Form,
  Field
} from "vee-validate";

export default {
  components: {
    /* eslint-disable vue/no-reserved-component-names */
    Form,
    Field,
  },
  data() {
    return {
      showPassword: false,
      password: null,
      emailError: "",
      passwordError: "",
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const rememberMe = ref(false);
    const email = ref('');
    const password = ref('');
    const schema = Yup.object().shape({
      email: Yup.string()
        .required("Email is required")
        .email("Email is invalid"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
    });
     onMounted(() => {
      const userData = JSON.parse(localStorage.getItem('user'));
      if(userData){
        if(userData && userData.roles[0] === 'super_admin'){
          router.push("/companies")
        } else {
          router.push("/dashboard");
        }
      } else {
          router.push("/");
        }
      const storedData = localStorage.getItem('storedData');
      if (storedData) {
        const { email: storedEmail, password: storedPassword } = JSON.parse(storedData);
        email.value = storedEmail || '';
        password.value = storedPassword || '';
        rememberMe.value = true;
      }
    });
    const onSubmit = async (values) => {
      document.getElementById("email").innerHTML = "";
      document.getElementById("password").innerHTML = "";
      
      try {
        const loginData = {
          email: values.email,
          password: values.password,
        };
        const response = await store.dispatch('auth/login', loginData);

        if (rememberMe.value) {
          localStorage.setItem('storedData', JSON.stringify(loginData));
        } else {
          localStorage.removeItem('storedData');
        }
        if(response.data.roles[0] === 'super_admin'){
          router.push("/companies")
        } else {
          router.push("/dashboard");
        }
      } catch (error) {
        if (error === 'Incorrect password') {
          document.getElementById("password").innerHTML = "Incorrect password";
        } else if (error === 'Email is not valid') {
          document.getElementById("email").innerHTML = "Email is not valid";
        } else {
          document.getElementById("email").innerHTML = error;
        }
      }
    };
    return {
      schema,
      onSubmit,
      checked: ref(false),
      rememberMe,
      email,
      password,
    };
  },
};
</script>
