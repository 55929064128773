<template>
  <div class="wrapper">
    <div class="kanban-wrapper leads-kanban-wrapper" ref="kanbanWrapper" @scroll="syncScrollFromKanban">
      <div v-for="(pipe, index) in modifiedPipeName" :key="index" class="kanban-list-items" :data-pipe-id="pipe.id"
        :class="{ 'focused': focusedIndex === index }" v-dragscroll>
        <!-- Kanban List Head -->
        <div class="kanban-list-head">
          <div class="row justify-content-between align-items-center">
            <div :class="pipe.DotClass" class="col-7 text-wrap">
              <h6>{{ pipe.name }}</h6>
              <!-- Display card count using the computed property -->
            </div>
            <div class="kanban-action-btns col-5 d-flex align-items-center justify-content-end pe-0">
              <span class="count-field">{{ getCardsCountByPipe(pipe.id) }} of {{ getCardsCountByPipe(pipe.id) }}</span>
              <router-link :to="{
                path: '/crm/create-meter-opportunity',
                query: { kanban: true, pipe_line: pipe.id },
              }" class="btn btn-primary p-1">
                <i class="ti ti-plus"></i>
              </router-link>
            </div>
          </div>
        </div>
        <!-- Kanban Cards -->
        <ul class="kanban-drag-wrap ui-sortable kanban-ul" ref="kanbanList">
          <draggable :list="getCardsByPipe(pipe.id)" :group="{ name: 'kanban', pull: true, put: true }"
            @end="onDragEnd($event)" :data-pipe-id="pipe.id">
            <li v-for="card in getCardsByPipe(pipe.id)" :key="card.id" :data-card-id="card.id"
              :data-card-info="JSON.stringify(card)">
              <div class="kanban-card ui-sortable-handle position-relative">
                <!-- Card Details -->
                <div class="kanban-card-head">
                  <span :class="card.CardClass"></span>
                  <div class="kanban-card-title d-block">
                    <div class="row align-items-center">
                      <div class="col-md-2">
                        <!-- <router-link :to="{
                          path: '/crm/meter-opportunities-edit',
                          query: { kanban: true, territory_id: card.territory_id, org_Id: card.organization.id, opp_Id: card.id },
                        }">
                          <span>{{ card.initials }}</span>
                        </router-link> -->
                        <a :href="$router.resolve({
                          path: '/crm/meter-opportunities-edit',
                          query: {
                            kanban: true,
                            territory_id: card.territory_id,
                            org_Id: card.organization.id,
                            opp_Id: card.id,
                          },
                        }).href" target="_blank">
                          <span>{{ card.initials }}</span>
                        </a>
                      </div>
                      <div class="col-md-6 p-0">
                        <h6>
                          <router-link :to="{
                            path: '/crm/organisation-details',
                            query: { kanban: true, territory_id: pipe.id, org_Id: card.organization.id, opp_Id: card.id },
                          }" class='truncate-text'>
                            {{ card.organization.name.toUpperCase() }}
                          </router-link>
                          <p :title="card.description" class="fw-normal truncate-text description">{{ card.description
                            }}</p>
                        </h6>
                      </div>
                      <div class="col-md-4">
                        <p class="text-end" title="Opportunity Nr number"># {{ card.meter_opportunity_nr_auto }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Card Body -->
                <div class="kanban-card-body">
                  <div class="row align-item-center card-price">
                    <div class="col-4 pe-0">
                      <p v-if="card.date_of_sale" title="Date of Sale" class="background-class"
                        :class="getDateClass(card.date_of_sale)">
                        <i class="ti ti-calendar-check"></i>
                        {{ formatDate(card.date_of_sale) }}
                      </p>
                    </div>
                    <div class="col-4 pe-0">
                      <p v-if="card.next_update_latest_date" title="Next Update Latest" class="background-class"
                        :class="getDateClass(card.next_update_latest_date)">
                        <i class="ti ti-calendar-check"></i>
                        {{ formatDate(card.next_update_latest_date) }}
                      </p>
                    </div>
                    <div class="col-4">
                      <p class="text-end" title="SC Forcast Total Commision">
                        <i class="ion-cash"></i>
                        {{ card.sc_forecast_total_commission }}
                      </p>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div v-if="card.organization.hasOwnProperty('tps')" class="col-2">
                      <p class="text-end" style="color: red; font-weight: 600; font-size: 12px;" title="TPS">
                        TPS - {{ card.organization.tps ? card.organization.tps : "N/A" }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="action-btn">
                  <router-link :to="{ path: '/crm/quoted-price-list', query: { opp_Id: card.id } }"
                    v-if="card.quotes_count !== 0" class="quoted-details-icon" title="Quoted Prices"> <i
                      class="ion-pricetags text-blue"></i></router-link>
                  <router-link :to="{ path: '/quoting', query: { opp_Id: card.id, for: card.main_production_service } }"
                    v-if="card.is_valid !== 0" class="get-quote-icon ms-2" title="Get Quote"> <i
                      class="ti-bar-chart text-blue"></i></router-link>
                  <!-- <router-link class="edit-kanban-icon ms-2" title="Edit" :to="{
                    path: '/crm/meter-opportunities-edit',
                    query: { kanban: true, territory_id: card.territory_id, org_Id: card.organization.id, opp_Id: card.id },
                  }">
                    <span><i class="ti ti-edit text-blue"></i></span>
                  </router-link> -->

                  <a :href="$router.resolve({
                    path: '/crm/meter-opportunities-edit',
                    query: {
                      kanban: true,
                      territory_id: card.territory_id,
                      org_Id: card.organization.id,
                      opp_Id: card.id,
                    },
                  }).href" target="_blank" title="Edit" class="edit-kanban-icon ms-2">
                    <span><i class="ti ti-edit text-blue"></i></span>
                  </a>
                </div>
              </div>
            </li>
          </draggable>
        </ul>
      </div>
    </div>
    <div class="horizontal-scroll-button-container" ref="container" @mousemove="onMouseMove" @mouseup="onMouseUp"
      @mousedown="onMouseDown">
      <div v-for="(pipe, index) in modifiedPipeName" :key="index" class="scroll-button" :data-pipe-id="pipe.id"
        @click="setFocus(index)" :class="{ 'clicked': clickedButtonIndex === index }" data-dragscroll>
        {{ }}
      </div>
      <div class="drag-border draggable" id="drag-wrapper" ref="scrollButtonContainer"
        :style="{ left: `${position.x}px`, top: `${position.y}px`, zIndex: zIndex }" @mousedown="onDragStart">
      </div>
    </div>
  </div>
</template>
<script>
import {
  VueDraggableNext
} from "vue-draggable-next";
import {
  ref,
  onMounted,
  computed,
  watchEffect,
  onUnmounted,
  watch
} from "vue";
import {
  useRouter
} from "vue-router";
import {
  useStore
} from "vuex";

import { dragscroll } from 'vue-dragscroll'

export default {
  data() {
    return {
      isDragging: false,
      position: { x: 5, y: 2 },
      // offset: { x: 0, y: 0 },
      zIndex: 1,
      containerWidth: 0,
      containerHeight: 0,
      kanbanWrapper: null,
      clickedButtonIndex: null,
      focusedIndex: null
    };
  },
  mounted() {
    this.containerWidth = this.$refs.container.clientWidth;
    this.containerHeight = this.$refs.container.clientHeight;
  },
  methods: {
    onDragStart(event) {

      const kanbanWrapper = this.$refs.kanbanWrapper;
      const container = this.$refs.container;

      if (kanbanWrapper && container) {
        this.isDragging = true;
        // this.offset.x = event.clientX - this.position.x;
        this.zIndex = 10;

        this.initialKanbanWrapperScroll = kanbanWrapper.scrollLeft;
        this.initialButtonContainerScroll = container.scrollLeft;
      }
    },

    onMouseDown(event) {
      this.isDragging = true;
      this.zIndex = 1000;
      this.initialX = event.clientX - this.position.x;
    },
    syncScrollFromKanban() {
      if (!this.isDragging) {
        const kanbanWrapper = this.$refs.kanbanWrapper;
        const container = this.$refs.container;
        const button = this.$refs.scrollButtonContainer;

        if (kanbanWrapper && container && button) {
          const scrollRatio = kanbanWrapper.scrollLeft / (kanbanWrapper.scrollWidth - kanbanWrapper.clientWidth);
          const maxDragPosition = container.offsetWidth - button.offsetWidth;
          this.position.x = scrollRatio * maxDragPosition;
        }
      }
    },

    onMouseMove(event) {
      if (this.isDragging) {
        let deltaX = event.clientX - this.initialX;
        const container = this.$refs.container;
        const button = this.$refs.scrollButtonContainer;

        const maxDragPosition = container.offsetWidth - button.offsetWidth;
        deltaX = Math.max(0, Math.min(deltaX, maxDragPosition));

        this.position.x = deltaX;

        const kanbanWrapper = this.$refs.kanbanWrapper;
        const scrollPercentage = this.position.x / maxDragPosition;
        const maxScroll = kanbanWrapper.scrollWidth - kanbanWrapper.clientWidth;

        kanbanWrapper.scrollLeft = scrollPercentage * maxScroll;

        button.style.left = `${this.position.x}px`;
      }
    },


    onMouseUp() {
      this.isDragging = false;
      this.zIndex = 1;
    },


    setFocus(index) {
      this.focusedIndex = index;

      const kanbanWrapperElement = this.$refs.kanbanWrapper;

      if (kanbanWrapperElement) {
        const kanbanItem = kanbanWrapperElement.children[index];
        if (kanbanItem) {
          kanbanItem.scrollIntoView({ behavior: 'smooth', block: 'center' });

          kanbanItem.classList.add('focused-item');
          setTimeout(() => {
            this.focusedIndex = null;
            kanbanItem.classList.remove('focused-item'); // Remove the focus style
          }, 3000);
        }
      }
    }

  },


  props: {
    searchBoardQuery: String,
    filters: Object,
  },
  components: {
    draggable: VueDraggableNext,
  },
  directives: {
    dragscroll,
  },


  setup(props) {
    const router = useRouter();
    const store = useStore();
    const modifiedPipeName = ref([]);
    const opportunities = computed(() => store.getters["opportunity/getOppListData"]);
    const pipelineLevels = computed(() => store.getters["opportunity/getPipelineLevels"]);
    const kanbanWrapper = ref(null);
    const scrollButtonContainer = ref(null);
    const clickedButtonIndex = ref(null);
    const isTps = ref(false)
    // Get today's date without time
    const today = new Date().setHours(0, 0, 0, 0);

    // Computed property for date class
    const getDateClass = (dateOfSale) => {
      const saleDate = new Date(dateOfSale).setHours(0, 0, 0, 0);
      if (saleDate === today) {
        return "yellow"; // Date is today
      } else if (saleDate < today) {
        return "red"; // Date is before today
      } else {
        return "green"; // Date is after today
      }
    };
    const formatDate = (date) => {
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    };
    // Transform pipeline names
    watchEffect(() => {
      if (pipelineLevels.value && pipelineLevels.value.length) {
        modifiedPipeName.value = pipelineLevels.value.map((item) => ({
          ...item,
          name: item.name.replace(/\[\d+\]\s*/, ""), // Remove "[n]" from the name
        }));
      }
    });

    const fetchBoardOpportunities = async () => {
      const params = {
        search: props.searchBoardQuery, // Use the correct prop
      };
      await store.dispatch("opportunity/fetchOpportunitiesLists", params);
    }

    onMounted(() => {
      fetchBoardOpportunities();
    });


    // Get cards by pipe ID
    const getCardsByPipe = (pipeID) => {
      return Array.isArray(opportunities.value) ?
        opportunities.value.filter((card) => card.pipeline_level === pipeID) : [];
    };

    // Get card count by pipe ID
    const getCardsCountByPipe = (pipeID) => {
      return getCardsByPipe(pipeID).length;
    };

    const onDragEnd = async (event) => {
      const draggableCard = event.item;
      const cardInfo = JSON.parse(draggableCard.dataset.cardInfo);
      const sourceElement = event.from;
      const targetElement = event.to;
      const sourcePipelineId = sourceElement.__draggable_component__.$attrs["data-pipe-id"];
      const targetPipelineId = targetElement.__draggable_component__.$attrs["data-pipe-id"];
      if (sourcePipelineId !== targetPipelineId) {
        const form = ref({
          id: cardInfo.id,
          pipeline_level: targetPipelineId,
        });
        const response = await store.dispatch("opportunity/updateOpportunity", form.value);
        if (response.status) {
          await store.dispatch("opportunity/fetchOpportunitiesLists");
        }
      }
    };
    return {
      modifiedPipeName,
      formatDate,
      fetchBoardOpportunities,
      getCardsByPipe,
      getCardsCountByPipe,
      onDragEnd,
      getDateClass,
      scrollButtonContainer,
      isTps
    };
  },

};
</script>

<style lang="scss" scoped>
.kanban-card {
  &:hover {
    .action-btn {
      display: flex;
    }
  }
}

.action-btn {
  position: absolute;
  top: 5px;
  right: 10px;
  display: none;
}

.edit-kanban-icon,
.get-quote-icon,
.quoted-details-icon {
  border: 1px solid #4a00e54a;
  border-radius: 50%;
  padding: 1px;
  height: 25px;
  width: 25px;
  text-align: center;
}

.quoted-details-icon {
  right: 40px;
}

.get-quote-icon {
  right: 62px;
}

.background-class {
  border-radius: 20px;
  padding: 2px 5px;
}

.yellow {
  color: #db7f28;
  background-color: rgb(255 165 0 / 15%);
  font-weight: 600;
}

.red {
  color: red;
  background: rgb(255 0 0 / 15%);
}

.green {
  color: green;
  background: rgb(0 128 0 / 15%);
}

.card-price {
  p {
    font-size: 10px;
  }
}

.count-field {
  background-color: #00918e33;
  color: #036664;
  padding: 2px 10px;
  border-radius: 15px;
  margin-right: 10px;
  font-size: 12px;
}

.description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.scroll-button {
  margin-right: 2px;
  padding: 4px 8px;
  background-color: #e8e8e8;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  min-width: fit-content;
  height: 40px;
  user-select: none;
}

.kanban-wrapper {
  overflow-x: auto;
  white-space: nowrap;
}

.kanban-wrapper {
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.kanban-wrapper::-webkit-scrollbar {
  display: none;
}

.horizontal-scroll-button-container {
  display: flex;
  width: 242px;
  white-space: nowrap;
  padding: 5px;
  background-color: #fff;
  // border: 1px solid #e8e8e8;
  border-radius: 8px;
  margin-left: auto;
  z-index: 100;
  margin-bottom: 1rem;
  position: fixed;
  bottom: 10px;
  right: 30px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.kanban-ul {
  max-height: 65vh;
  overflow-y: auto;
}

.kanban-ul {
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.kanban-ul::-webkit-scrollbar {
  display: none;
}

.wrapper {
  position: relative;
}

.horizontal-scroll-button-container::-webkit-scrollbar {
  display: none;
}

.kanban-list-items.focused {
  transition: border 0.3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
}

.drag-border {
  position: absolute;
  border: 1px solid blue !important;
  width: 82px;
  height: 46px;
  border-radius: 8px;
  cursor: pointer;
}
</style>