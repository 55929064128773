<template>
    <div v-if="isVisible && callsData?.length" class="col-xl-3 alert-popup">
        <div class="card border-0">
            <div :class="['alert', alertClass, 'border', borderClass, 'mb-0', 'p-3']">
                <div class="d-flex align-items-start">
                    <div class="me-2">
                        <i :class="iconClass" class="flex-shrink-0"></i>
                    </div>
                    <div :class="textClass + ' w-100'">
                        <div class="fw-semibold d-flex justify-content-between">
                            Call Reminder
                            <button type="button" class="btn-close p-0" @click="dismissAlert" aria-label="Close">
                                <i class="fas fa-xmark"></i>
                            </button>
                        </div>
                        <div class="fs-12 mb-1">
                            {{ callsData[0]?.message }}
                        </div>
                        <div class="fs-12">
                            <a href="javascript:void(0);" :class="textClass + ' fw-semibold'"
                                @click="openAction">open</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="isVisible && Object.keys(importData)?.length" class="col-xl-3 alert-popup">
        <div class="card border-0">
            <div :class="['alert', alertClass1, 'border', borderClass1, 'mb-0', 'p-3']">
                <div class="d-flex align-items-start">
                    <div class="me-2">
                        <i :class="iconClass1" class="flex-shrink-0"></i>
                    </div>
                    <div :class="textClass1 + ' w-100'">
                        <div class="fw-semibold d-flex justify-content-between">
                           {{ importMessage }}
                            <button type="button" class="btn-close p-0" @click="dismissAlert" aria-label="Close">
                                <i class="fas fa-xmark"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps({
    callsData: {
        type: Array,
        required: true,
    },
    importData: {
        type: Object,
        required: true,
    }
});

const isVisible = ref(true);
const importMessage = ref("");
let alertTimeout = null;
const router = useRouter();

const dismissAlert = () => {
    isVisible.value = false;
};

const openAction = () => {
    isVisible.value = false;
    router.push({
        path: "/crm/meter-opportunities-edit",
        query: {
            territory_id: props.callsData[0]?.callDetails?.territory_id,
            org_Id: props.callsData[0]?.callDetails?.organization_id,
            opp_Id: props.callsData[0]?.callDetails?.id
        }
    });
};

const startAlertTimer = () => {
    clearTimeout(alertTimeout);
    alertTimeout = setTimeout(() => {
        isVisible.value = false;
    }, 30000);
}

onMounted(() => {
    startAlertTimer();
});

onBeforeUnmount(() => {
    clearTimeout(alertTimeout);
});

watch(
    () => props.callsData,
    (newVal) => {
        if (newVal.length) {
            isVisible.value = true;
            startAlertTimer();
        }
    }
);

watch(
    () => props.importData,
    (newVal) => {
        if (Object.keys(newVal).length) {
            isVisible.value = true;
            startAlertTimer();
        }
    }
);

const minutesUntilCall = computed(() => props.callsData[0]?.minutesUntilCall);

const warning_count = computed(() => props.importData?.warning_count);

const alertClass = computed(() => {
    switch (minutesUntilCall.value) {
        case 30:
            return 'alert-secondary';
        case 10:
            return 'alert-primary';
        case 2:
            return 'alert-solid-primary';
        default:
            return '';
    }
});

const borderClass = computed(() => {
    switch (minutesUntilCall.value) {
        case 30:
            return 'border-secondary';
        case 10:
            return 'border-primary';
        case 2:
            return 'border-primary';
        default:
            return '';
    }
});

const iconClass = computed(() => {
    switch (minutesUntilCall.value) {
        case 30:
            return 'feather-check-circle';
        case 10:
            return 'feather-info';
        case 2:
            return 'feather-info';
        default:
            return '';
    }
});

const textClass = computed(() => {
    switch (minutesUntilCall.value) {
        case 30:
            return 'text-secondary';
        case 10:
            return 'text-primary';
        case 2:
            return 'text-fixed-white';
        default:
            return '';
    }
});

const alertClass1 = computed(() => {
    if (warning_count.value === 0) {
        importMessage.value = "Success! The file import has been fully processed.";
        return 'alert-success';
    } else {
        importMessage.value = "Success! The file import has been fully processed, but some warnings were encountered. Please review for details.";
        return 'alert-primary';
    }
});

const borderClass1 = computed(() => (warning_count.value === 0 ? 'border-success' : 'border-primary'));

const iconClass1 = computed(() => (warning_count.value === 0 ? 'feather-check-circle' : 'feather-alert-circle'));

const textClass1 = computed(() => (warning_count.value === 0 ? 'text-success' : 'text-primary'));
</script>

<style scoped>
.alert-popup {
    z-index: 99999;
    position: fixed;
    top: 20px;
    right: 20px;
}
</style>
