<template>
  <div class="col-xl-12 col-lg-12">
    <div class="card">
      <div class="card-body settings-form">
        <form class="toggle-height">
          <div class="pro-create">
            <div class="button-group-container col-md-12">
              <div class="col-md-7">
                <div class="col-md-12">
                  <p style="text-transform: capitalize; margin-bottom: 10px;">{{ moduleName }}</p>
                  <h3>{{ reportName }}</h3>
                </div>
              </div>
              <div class="col-md-5 button-group">
                <div class="text-end">
                  <button class="btn btn-info px-3" @click.prevent="reportEdit">Overview Configuration</button>
                </div>

                <div class="filter-set-view" v-if="data?.length > 0">
                  <div class="filter-set-head filter-btn">
                    <button @click.prevent="toggleAccordion" class="btn btn-light"><i
                        class="ti ti-filter-share me-2"></i>Filter</button>
                    <div class="accordion filter-drop-down" id="accordionExample" v-if="showAccordion">
                      <div class="filter-set-content">
                        <div class="filter-set-contents accordion-collapse collapse show" id="collapseTwo"
                          data-bs-parent="#accordionExample">
                          <div class="filter-content-list">
                            <div class="form-wrap icon-form">
                              <span class="form-icon"><i class="ti ti-search"></i></span>
                              <input type="text" class="form-control" placeholder="Search Columns"
                                v-model="searchTerm" />
                            </div>
                            <ul>
                              <li v-for="option in filteredColumnOptions" :key="option.value">
                                <div class="filter-checks">
                                  <label class="checkboxs">
                                    <input type="checkbox" :checked="selectedColumns.includes(option.value)"
                                      @change="handleColumnSelection(option.value)" />
                                    <span class="checkmarks"></span>
                                  </label>
                                </div>
                                <div class="collapse-inside-text">
                                  <h5>{{ option.label }}</h5>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <button class="btn btn-light px-3" v-if="data?.length > 0" @click.prevent="exportData">
                  {{  "Export" }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>


  <!-- Scrollable Table -->
  <div class="table-container" v-if="data.length > 0">
    <a-table class="stripped table-hover" :columns="filteredColumns" :data-source="data" :pagination="false">
      <template #bodyCell="{ column, record }">
        <span>{{ record[column?.field] }}</span>
      </template>
    </a-table>
  </div>
  <div v-else class="no-data-container">
    <i class="no-data-icon">📄</i>
    <p class="no-data-message">No Data Found</p>
  </div>

  <!-- Fixed Pagination -->
  <div class="pagination-container" v-if="data.length > 0">
    <a-pagination :current="pagination.current" :pageSize="pagination.pageSize" :total="pagination.total"
      @change="handleTableChange" class="d-flex" />
  </div>

  <!-- Prompt User Modal -->
  <Popup v-if="module_data.length > 0" :content="module_data" :generateReport="generateReport" />
</template>

<script>
import { ref, computed, onMounted, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { ErrorMessage, Field, Form } from "vee-validate";
import Popup from './propmt-popup.vue'

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    Popup
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const module_data = ref([])
    const data = ref([])
    const columns = ref([]);
    const selectedColumns = ref([]);
    const pagination = ref({ current: 1, pageSize: 10, total: 0 });
    const columnOptions = ref([]);
    const searchTerm = ref("");
    const showAccordion = ref(false);
    const moduleName = ref(route.query.modN || '');
    const reportName = ref(route.query.repN || '');

    const modifiedData = ref(null)

    // const showModal = ref(false);
    // const selectedType = ref("");
    // const selectedDelimiter = ref("");
    // const exportTypes = ref(["CSV", "Excel"]);
    // const delimiters = ref([",", ";"]);

    const toggleAccordion = () => {
      showAccordion.value = !showAccordion.value;
    };

    const generateReport = async (id, formData = []) => {
      try {
        let modifiedFormData = []
        if (formData.length > 0) {
          formData.map((data) => {
            return data.map((items) => {
              const { module_name, field_name, ...item } = items;
              modifiedFormData.push(item)
            })
          })
        }
        modifiedData.value = modifiedFormData
        const responseData = await store.dispatch("report/generateReport", { id, modifiedData: modifiedData.value });
        data.value = responseData;
        const response = module_data.value
        let dynamicColumns = []

        if (response.length > 0) {
          response.map((org) => {
            return org.children.map((item) => {
              dynamicColumns.push({
                title: `${org.label}/${item.label.toUpperCase()}`,
                field: item.field,
                dataIndex: item.id,
                key: item.id,
                sorter: (a, b) => a[item.label]?.toString().localeCompare(b[item.label]?.toString()),
              })

            });
          })

          columns.value = dynamicColumns;

          columnOptions.value = dynamicColumns.map((col) => ({
            label: col.title,
            value: col.key,
          }));
          selectedColumns.value = columnOptions.value
            .slice(0, 6)
            .map(option => option.value);

        }

        pagination.value.total = response.length;
      } catch (error) {
        console.error("Error fetching report data:", error);
      }
    };

    const filteredColumns = computed(() => {
      return selectedColumns.value.length === 0 ? columns.value : columns.value.filter(col => selectedColumns.value.includes(col.key));
    });

    const filteredColumnOptions = computed(() => {
      return columnOptions.value.filter(option => option.label.toLowerCase().includes(searchTerm.value.toLowerCase()));
    });

    const handleColumnSelection = (value) => {
      const index = selectedColumns.value.indexOf(value);
      if (index > -1) {
        selectedColumns.value.splice(index, 1);
      } else {
        selectedColumns.value.push(value);
      }
    };

    const handleTableChange = async (paginationData, filters, sorter) => {
      pagination.value.current = paginationData.current;
      const reportId = route.query.reportId;
      if (reportId) {
        await generateReport(reportId);
      }
    };


    // const openModal = () => {
    //   showModal.value = true;
    // };

    // const closeModal = () => {
    //   showModal.value = false;
    // };

    const exportData = async () => {
      try {

        const reportId = route.query.reportId;
        const response = await store.dispatch("report/downloadReport", { reportId, columns: selectedColumns.value, modifiedData: modifiedData.value });

        if (response && response.data && response.data.url) {
          const fileUrl = response.data.url;
          const link = document.createElement("a");
          link.href = fileUrl;
          link.download = "";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error("Error: Report download failed or no URL found.");
        }
      } catch (error) {
        console.error("Error during report download:", error);
      }
    };

    const reportEdit = () => {
      const reportId = route.query.reportId;
      const moduleId = route.query.moduleId
      router.push({ path: "/crm/report-edit", query: { reportId, moduleId } });
    };

    const fetchModuleData = async (id) => {
      try {
        const data = await store.dispatch("report/fetchModuleData", id);
        module_data.value = data;
      }
      catch (error) {
        console.error("Error fetching report data:", error);
      }
    };

    onMounted(async () => {
      const reportId = route.query.reportId;
      const moduleId = route.query.moduleId;
      if (reportId) {
        await fetchModuleData(moduleId)
      }
    });

    return {
      data,
      pagination,
      columns,
      selectedColumns,
      columnOptions,
      filteredColumns,
      filteredColumnOptions,
      handleTableChange,
      handleColumnSelection,
      searchTerm,
      showAccordion,
      toggleAccordion,
      exportData,
      reportEdit,
      moduleName,
      reportName,
      fetchModuleData,
      generateReport,
      modifiedData,
      module_data
      // formData,
      // cancelPrompt,
      // applyChange,
      // settingField,
      // showModal,
      // selectedType,
      // selectedDelimiter,
      // exportTypes,
      // delimiters,
      // openModal,
      // closeModal,
    };
  },
};
</script>


<style scoped>
.button-group-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.button-group {
  display: flex;
  align-items: center;
  align-items: self-end;
  justify-content: end;
  gap: 30px;
}

.select {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  padding-bottom: 10px;
}

label {
  padding-bottom: 10px;
}

.filter-btn {
  position: relative;
}

.filter-drop-down {
  width: 350px;
  z-index: 9999;
  position: absolute;
  top: 90%;
  left: -160%;
}

.table-container {
  max-height: 100%, ;
  overflow-y: scroll;
  margin-bottom: 25px;
  /* overflow-x: scroll; */
  /* scrollbar-width: none;  */
}

.table-container::-webkit-scrollbar {
  width: 0;
}

.pagination-container {
  display: flex;
  justify-content: end;
  margin-top: 10px;
  text-align: center;
}

.ant-table-wrapper {
  margin-bottom: 20px !important;
}

.filter-set-contents .filter-content-list ul {
  max-height: 280px !important;
}
.no-data-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    /* border: 1px dashed #ccc; */
    border-radius: 8px;
    background-color: #f9f9f9;
    color: #555;
}

.no-data-icon {
    font-size: 40px;
    margin-bottom: 10px;
    color: #999;
}

.no-data-message {
    font-size: 16px;
    font-weight: 500;
    color: #666;
}
</style>
