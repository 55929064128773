<template>
  <div class="col-12 theiaStickySidebar">
    <!-- Settings Sidebar -->
    <!-- <div class="card mb-0"> -->
      <!-- <div class="card-body"> -->
        <div class="settings-sidebar">
          <h4>Data import</h4>
          <div class="row">
          <div class="col-2"></div>
            <div class="col text-center p-2">
              <router-link to="/data-import/import-data-status">Status</router-link>
            </div>
            <div class="col text-center p-2">
              <!-- <router-link to="/data-import/upload-file"
                >Upload File</router-link
              > -->
              <router-link  :to="{ path: '/data-import/upload-file', query: { fileId: file_id, useMapping: mapping_id } }">Upload File</router-link>
            </div>
            <div class="col text-center p-2">
              <!-- <router-link to="/data-import/validate">Validate {{ file_id }}</router-link> -->
              <router-link  :to="{ path: '/data-import/validate', query: { fileId: file_id, useMapping: mapping_id } }">Validate</router-link>
            </div>
            <div class="col text-center p-2">
              <!-- <router-link to="/data-import/map-data">Map Data {{ file_id }}</router-link> -->
              <router-link  :to="{ path: '/data-import/map-data', query: { fileId: file_id, useMapping: mapping_id } }">Map Data</router-link>
            </div>
            <div class="col text-center p-2">
              <!-- <router-link to="/data-import/assign-data">Assign Data</router-link> -->
              <router-link  :to="{ path: '/data-import/assign-data', query: { fileId: file_id, useMapping: mapping_id } }">Assign Data</router-link>
            </div>
            <div class="col text-center p-2">
              <router-link to="/data-import/create-mode">Create Mode</router-link>
            </div>
            <div class="col-2"></div>
          </div>
        <!-- </div> -->
      <!-- </div> -->
    </div>
    <!-- /Settings Sidebar -->
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    file_id: Number,
    mapping_id: Number,
  },
};
</script>
<style scoped lang="scss">
 .settings-sidebar{
  .col:has(.active){
    border-bottom: 4px solid #E41F07;
    .active{
      color: #E41F07;
    }
  }
 }
</style>