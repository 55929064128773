<template>
<!-- Add Lead -->
<div class="modal custom-modal fade" id="add_opportunities" role="dialog">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header border-0">
        <div class="row w-100">
          <div class="col-6">
            <h4>Create Organisation</h4>
          </div>
          <div class="col-6 pe-0">
            <button class="btn-close ms-auto" data-bs-dismiss="modal" aria-label="Close">
              <i class="ti ti-x"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <Form @submit="createOpportunity" :validation-schema="schema">
          <div class="row">
            <div class="col-md-12">
              <div class="form-wrap">
                <label class="col-form-label">Organisation <span class="text-danger">*</span></label>
                <div class="icon-form">
                  <Field name="organisation_id" type="text" class="form-control" placeholder="Search organisation name / postcode" v-model="searchQuery"
                  @keydown.enter="searchOrganisations" @input="onInput" />
                  <span class="form-icon">
                    <i class="ti ti-building-community"></i>
                  </span>
                </div>
                <ul v-if="filteredOrganisations.length" class="dropdown-menu shadow show w-100 p-0">
                  <li v-for="organisation in filteredOrganisations" :key="organisation.id" @click="selectOrganisation(organisation)" class="dropdown-item" style="cursor: pointer;">
                    {{ organisation.name + ' ' + ( organisation.postcode ? organisation.postcode : '' ) }}
                  </li>
                </ul>
                <ErrorMessage name="organisation_id" class="text-danger" />
              </div>
            </div>
            <div class="col-md-12" v-if="form.organization_id">
              <div class="form-wrap">
                <label class="col-form-label">Person</label>
                <multiselect v-model="form.organisation_person" :options="orgPersons" track-by="id" label="name" :multiple="false" tag-placeholder="Person" placeholder="Select..."></multiselect>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-wrap">
                <label class="col-form-label">Order date <span class="text-danger">*</span></label>
                <div class="icon-form">
                  <date-picker placeholder="" textInput v-model="form.date_of_sale" class="datetimepicker form-control" :editable="true" :clearable="true" :input-format="dateFormat" />
                  <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                </div>
                <!-- <ErrorMessage name="date_of_sale" class="text-danger" /> -->
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-wrap">
                <label class="col-form-label">Pipeline level <span class="text-danger">*</span></label>
                <Field as="select" name="pipeline_level" class="form-select" v-model="form.pipeline_level">
                  <option value="">-----Select-----</option>
                  <option v-for="res in pipelineLevels" :key="res.id" :value="res.id">
                    {{ res.name }}
                  </option>
                </Field>
                <ErrorMessage name="pipeline_level" class="text-danger" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-wrap">
                <label class="col-form-label">Sales responsible</label>
                <select id="userRes" class="form-select" v-model="form.sales_responsible"  :disabled="saleResponsibleOptions.length === 1">
                  <option v-for="res in saleResponsibleOptions" :key="res.id" :value="res.id">
                    {{ res.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-wrap">
                <label class="col-form-label">Description</label>
                <textarea class="form-control" rows="1" v-model="form.description"></textarea>
              </div>
            </div>
          </div>
          <div class="submit-button text-end">
            <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
            <button type="submit" class="btn btn-primary">Create</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  ref,
  watch,
  onMounted,
  computed,
} from "vue";
import apiClient from "@/axios";
import {
  useStore
} from "vuex";
import {
  useRoute,
  useRouter
} from 'vue-router';
import Multiselect from 'vue-multiselect';
import $ from 'jquery';

import {
  Form,
  Field,
  ErrorMessage,
  useForm
} from "vee-validate";
import * as Yup from "yup";
import moment from 'moment';
import { changeFormate } from '../../util'
export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  data() {
    return {
      dateFormat: "dd/MM/yyyy",
      title: "Settings",
      availStatus: [
        'No', 'Yes'
      ],
      loginSmsCode: ['always']
    };
  },
  methods: {
    async createOpportunity (){
      // try {
        const response = await store.dispatch('opportunity/createOpportunity', {...form.value, date_of_sale: changeFormate(form.value.date_of_sale) });
        if(response.status){
          // context.emit('list-updated');
          // Close the modal
          // this.$refs.modal.close();
        }
        // router.push("/crm/organisation-list");
      // } catch (error) {
      //   apiErrors.value = error.response.data.errors;
      //   console.error('Error saving organization data:', error);
      // }
    },
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    // const responsibleUsers = ref([]);
    const apiErrors = ref(null);

    const searchQuery = ref('');
    const orgOptions = computed(() => store.getters['opportunity/getorgOtionsData']);
    const orgPersons = computed(() => store.getters['opportunity/getPersonsData']);
    const pipelineLevels = computed(() => store.getters['opportunity/getPipelineLevels']);
    const saleResponsibleOptions = computed(() => store.getters['organisation/getfetchAssignedTo']);

    const filteredOrganisations = ref([]);

    const onInput = () => {
      searchOrganisations();
    }

    const searchOrganisations = () => {
      const query = searchQuery.value.toLowerCase();
      if (query) {
        filteredOrganisations.value = orgOptions.value.filter((org) => {
          const nameMatch = org.name.toLowerCase().includes(query);
          const postcodeMatch = org.postcode ?
            org.postcode.toString().toLowerCase().includes(query) :
            false; // If postcode is null or undefined, this will prevent the error
          return nameMatch || postcodeMatch;
        });
      } else {
        filteredOrganisations.value = [];
      }
    };

    const selectOrganisation = (organisation) => {
      form.value.organization_id = organisation.id;
      searchQuery.value = organisation.name + ' ' + organisation.postcode;
      store.dispatch('opportunity/fetchPersons', organisation.id)
      store.dispatch('organisation/fetchAssignedToUsers', organisation.territory_id)
      filteredOrganisations.value = [];
    };

    const schema = Yup.object().shape({
      organisation_id: Yup.string()
        .required("Organisation is required"),
      // date_of_sale: Yup.string()
      //   .required("Order date is required")
      pipeline_level: Yup.string()
        .required("Pipeline level is required"),
    });

    const form = ref({
      organization_id: null,
      organisation_person: null,
      sales_responsible: null,
      pipeline_level: null,
      date_of_sale: '',
      description: '',
    });

    const onPersonSelected = (selectedPerson) => {
      if (selectedPerson) {
        form.value.organisation_person = selectedPerson.id;
      }
    };

    const createOpportunity = async () => {
      // try {
        const response = await store.dispatch('opportunity/createOpportunity', form.value);
        if(response.status){
          // context.emit('list-updated');
          // Close the modal
          // this.$refs.modal.close();
        }
        // router.push("/crm/organisation-list");
      // } catch (error) {
      //   apiErrors.value = error.response.data.errors;
      //   console.error('Error saving organization data:', error);
      // }
    };

    const fetchResponsibles = async (role) => {
      if (role === 'role') {
        form.value.territories = [];
      }

      const selectedRole = form.value.role;
      let selectedTerritoryIds = [];
      const selectedTer = form.value.territories;

      // Determine if multiple selections are allowed
      if (isMultiple.value && Array.isArray(selectedTer)) {
        selectedTerritoryIds = selectedTer.map(territory => territory.id);
      } else if (!isMultiple.value && selectedTer && selectedTer.id) {
        selectedTerritoryIds = [selectedTer.id];
      }

      if (selectedRole !== '') {
        try {
          const response = await apiClient.get('/dropdown/company/assigned-managers', {
            params: {
              role: selectedRole,
              territory_ids: selectedTerritoryIds.length > 0 ? selectedTerritoryIds : []
            }
          });
          responsibleUsers.value = response.data.map(user => ({
            id: user.id,
            name: `${user.first_name} ${user.last_name}`
          }));
        } catch (error) {
          console.error('Error fetching roles:', error);
        }
      }
    };
    onMounted(async () => {
      store.dispatch('opportunity/getorgOptions');
      store.dispatch('opportunity/fetchPipelines');
    });

    return {
      schema,
      form,
      orgOptions,
      searchQuery,
      filteredOrganisations,
      searchOrganisations,
      selectOrganisation,
      createOpportunity,
      fetchResponsibles,
      orgPersons,
      onPersonSelected,
      pipelineLevels,
      saleResponsibleOptions,
      apiErrors,
      onInput
    };
  },
  watch: {
    saleResponsibleOptions: {
      immediate: true,
      handler(newOptions) {
        if (newOptions.length === 1) {
          this.form.sales_responsible = newOptions[0].id;
        }
      },
    },
  },
};
</script>
