<template>
<div class="card" v-if="filteredSuppliers.length > 0">
  <div class="row mx-0 p-3">
    <div class="col-12 p-0">
      <h4 class="mb-0 p-2 text-center bg-soft-warning text-dark">Your prices</h4>
      {{ supplierNames }}
    </div>
    <div class="col-12 table-responsive mb-0 p-0">
      <table class="table table-hover border">
        <thead>
          <tr>
            <th scope="col" class="bg-teal text-white">Unit Rate Uplift</th>
            <th scope="col" class="bg-teal text-white text-center">
              Standing Charge Uplift (pence/day)
            </th>
            <th scope="col" class="bg-teal text-white text-center">
              Supplier
            </th>
            <th scope="col" class="bg-teal text-white text-center">
              Product
            </th>
            <th scope="col" class="bg-teal text-white text-center">
              Term
            </th>
            <th scope="col" class="bg-teal text-white text-center">
              Day rate (pence/kWh)
            </th>
            <th scope="col" class="bg-teal text-white text-center" >
              Night rate (pence/kWh)
            </th>
            <th scope="col" class="bg-teal text-white text-center">
              Standing charge (pence/day)
            </th>
            <th scope="col" class="bg-teal text-white text-center">
              Annual cost
            </th>
            <th scope="col" class="bg-teal text-white text-center">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="supplier in filteredSuppliers" :key="supplier.Id">
            <td>{{ supplier.Uplift }}</td>
            <td class="text-center">{{ supplier.StandingChargeUplift || '0' }}</td>
            <td class="text-center">{{ supplier.Supplier }}</td>
            <td class="text-center">{{ supplier.PlanType || 'Default'}}</td>
            <td class="text-center">{{ supplier.Term }}</td>
            <td class="text-center">{{ supplier.DayUnitrate }}</td>
            <td class="text-center">{{ supplier.NightUnitrate }}</td>
            <td class="text-center">{{ supplier.StandingCharge }}</td>
            <td class="text-center fw-bold">{{ supplier.AnnualPrice }}</td>
            <td class="text-center"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</template>

<script>
import {
  ref,
  onMounted,
  computed
} from "vue";
import {
  useStore
} from "vuex";

export default {
  props: {
    elecSuppliersRateList: {
      type: Array,
      default: [],
    },
    selectedSuppliers: {
      type: Array,
      default: [],
    },
  },
  setup(props) {
    // Computed property to filter suppliers with non-zero AnnualPrice
    const filteredSuppliers = computed(() => {
      return props.elecSuppliersRateList.filter(supplier =>
        props.selectedSuppliers.includes(supplier.Supplier)
      );
    });
    return {
      filteredSuppliers,
    };
  },
};
</script>

<style scoped>
</style>
