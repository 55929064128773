<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="table-responsive custom-table">
        <report-overview-table></report-overview-table>
      </div>
    </div>
  </div>
</template>

<script>
</script>

<style scoped>
.custom-table{
  height: 850px;
}
</style>
