<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>
  <div class="page-wrapper meter-opp">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <div class="contact-head">
            <div class="row align-items-center">
              <div class="col-sm-6">
                <ul class="contact-breadcrumb">
                  <li>
                    <router-link to="/crm/contracts-list">
                      <i class="ti ti-arrow-narrow-left"></i>
                      Contracts
                    </router-link>
                  </li>
                  <li>Create Contracts</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12 col-lg-12">
              <div class="card">
                <div class="card-body settings-form">
                  <Form @submit="createContract" :validation-schema="schema">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-wrap">
                          <label class="col-form-label">Organisation <span class="text-danger">*</span></label>
                          <div class="icon-form">
                            <Field name="organisation_id" type="text" class="form-control"
                              placeholder="Search organisation name / postcode" v-model="searchQuery"
                              @keyup="searchOrganisations" @input="onInput" @focus="onFocus" @blur="onBlur" />
                            <span class="form-icon">
                              <i class="ti ti-building-community"></i>
                            </span>
                          </div>
                          <ul v-if="isFocused && !isOrganisationSelected"
                            class="dropdown-menu shadow show pe-0" style="width: 97%;">
                            <!-- Display search results if there are matches -->
                            <li v-if="filteredOrganisations.length" class="dropdown-listing">
                              <ul>
                                <li v-for="organisation in filteredOrganisations" :key="organisation.id"
                                  @click="selectOrganisation(organisation)" class="dropdown-item"
                                  style="cursor: pointer;">
                                  {{ organisation.name + ' ' + (organisation.postcode || '') }}
                                </li>
                              </ul>
                            </li>

                            <!-- Display 'No organization found' message if no results -->
                            <li v-else class="dropdown-item text-muted">
                              No organization found
                            </li>

                            <!-- 'Add New Organization' link -->
                            <li class="px-3">
                              <router-link class="text-primary fw-bold"
                                :to="{ path: '/crm/create-organisation', query: { add: 'contract' } }">
                                Add New Organisation
                              </router-link>
                            </li>
                          </ul>
                          <ErrorMessage name="organisation_id" class="text-danger" />
                        </div>
                      </div>
                      <!-- <div class="col-md-12" v-if="form.organization_id">
                        <div class="form-wrap">
                          <label class="col-form-label">Person</label>
                        <multiselect v-model="selectedPerson" :options="orgPersons" track-by="id" label="name" :multiple="false" tag-placeholder="Person" placeholder="Select..."></multiselect>
                        </div>
                      </div> -->
                      <div class="col-md-12" v-if="form.organization_id">
                        <div class="form-wrap">
                          <label class="col-form-label">Person </label>
                          <div class="icon-form">
                            <Field name="organization_person_id" type="text" class="form-control"
                              placeholder="Search person name" v-model="searchPersonQuery" @input="onPersonInput" @focus="onFocusPerson" @blur="onBlurPerson" @keyup="searchPersons" />

                            <span class="form-icon">
                              <i class="ti ti-user"></i>
                            </span>
                          </div>

                          <!-- Dropdown for search results -->
                          <ul v-if="isPersonFocused && !isPersonSelected"
                            class="dropdown-menu shadow show pe-0" style="width: 97%;">
                            <li v-if="filteredPersons?.length" class="dropdown-listing">
                              <ul>
                                <li v-for="person in filteredPersons" :key="person.id" @click="selectPerson(person)"
                                  class="dropdown-item" style="cursor: pointer;">
                                  {{ person.name }}
                                </li>
                              </ul>
                            </li>

                            <li v-else class="dropdown-item text-muted">
                              No person found
                            </li>

                            <li class="px-3">
                              <router-link class="text-primary fw-bold"
                                :to="{ path: '/crm/create-person', query: { add: 'contract', org_Id: form.organization_id } }">
                                Add New Person
                              </router-link>
                            </li>
                          </ul>

                          <!-- Error message -->
                          <ErrorMessage name="organization_person_id" class="text-danger" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Date of Sale <span class="text-danger">*</span></label>
                          <div class="icon-form">
                            <date-picker placeholder="" textInput v-model="form.date_of_sale" class="datetimepicker form-control"
                              :editable="true" :clearable="true" :input-format="dateFormat" />
                            <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Overall status <span class="text-danger">*</span></label>
                          <Field as="select" name="overall_status" class="form-select" v-model="form.overall_status">
                            <option v-for="res in Options.contract_overall_status" :key="res.value" :value="res.value">
                              {{ res.text }}
                            </option>
                          </Field>
                          <ErrorMessage name="early_contract_start" class="text-danger" />
                        </div>
                      </div> -->
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Responsible</label>
                          <select id="userRes" class="form-select" v-model="form.contract_responsible"
                            :disabled="saleResponsibleOptions.length === 1">
                            <option v-for="res in saleResponsibleOptions" :key="res.id" :value="res.id">
                              {{ res.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-wrap">
                          <label class="col-form-label">Description</label>
                          <textarea class="form-control" rows="1" v-model="form.contract_description"></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="submit-button text-end">
                      <!-- <router-link to="/crm/contracts-list" class="btn btn-light">Back</router-link> -->
                      <button type="button" class="btn btn-light" @click="handleCancel">
                        Back
                      </button>
                      <button type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref,
  watch,
  onMounted,
  computed,
} from "vue";
import apiClient from "@/axios";
import {
  useStore
} from "vuex";
import {
  useRoute,
  useRouter
} from 'vue-router';
import Multiselect from 'vue-multiselect';
import $ from 'jquery';

import {
  Form,
  Field,
  ErrorMessage,
  useForm
} from "vee-validate";
import * as Yup from "yup";
import moment from 'moment';
import { changeFormate } from '../../../../util'
export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  data() {
    return {
      dateFormat: "dd/MM/yyyy",
      title: "Settings",
      availStatus: [
        'No', 'Yes'
      ],
      loginSmsCode: ['always']
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isOrganisationSelected = ref(false);
    const isPersonSelected = ref(false);
    const isFocused = ref(false);
    const isPersonFocused = ref(false);

    // const responsibleUsers = ref([]);
    const apiErrors = ref(null);

    const searchQuery = ref('');
    const searchPersonQuery = ref('');
    const filteredPersons = ref([]);
    const orgOptions = computed(() => store.getters['opportunity/getorgOtionsData']);
    const Options = computed(() => store.getters['contract/getcontractOptions']);
    const orgPersons = computed(() => store.getters['opportunity/getPersonsData']);
    const saleResponsibleOptions = computed(() => store.getters['organisation/getresUsersOptions']);

    const filteredOrganisations = ref([]);

    const onInput = () => {
      searchOrganisations();
      isOrganisationSelected.value = false
    }
    const onFocus = () => {
      isFocused.value = true
      searchOrganisations();
    };

    const onFocusPerson = () => {
      isPersonFocused.value = true
      searchPersons();
    };

    const onBlur = () => {
      setTimeout(() => {
        isFocused.value = false; // Hide dropdown on blur
      }, 200);
    };

    const onBlurPerson = () => {
      setTimeout(() => {
        isPersonFocused.value = false; // Hide dropdown on blur
      }, 200);
    };
    const onPersonInput = () => {
      isPersonSelected.value = false;
      searchPersons();
    };

    const searchPersons = () => {
      const query = searchPersonQuery.value.toLowerCase();
      if (query) {
        filteredPersons.value = orgPersons.value.filter(person =>
          person.name.toLowerCase().includes(query)
        );

      } else {
        filteredPersons.value = orgPersons.value;
      }
    };

    const selectPerson = (person) => {
      form.value.organization_person_id = person.id;
      searchPersonQuery.value = person.name;
      filteredPersons.value = [];
      isPersonSelected.value = true;
    };

    const searchOrganisations = () => {
      const query = searchQuery.value.toLowerCase();
      if (query) {
        filteredOrganisations.value = orgOptions.value.filter((org) => {
          const nameMatch = org.name.toLowerCase().includes(query);
          const postcodeMatch = org.postcode ?
            org.postcode.toString().toLowerCase().includes(query) :
            false; // If postcode is null or undefined, this will prevent the error
          return nameMatch || postcodeMatch;
        });
      } else {
        filteredOrganisations.value = orgOptions.value;
      }
    };

    const selectOrganisation = (organisation) => {
      form.value.organization_id = organisation.id;
      searchQuery.value = (organisation.name) + ' ' + (organisation.postcode || '');
      store.dispatch('opportunity/fetchPersons', organisation.id)
      store.dispatch('organisation/fetchTerritoryCompanyUsers', organisation.territory_id)
      filteredOrganisations.value = [];
      isOrganisationSelected.value = true;
    };

    const schema = Yup.object().shape({
      organisation_id: Yup.string()
        .required("Organisation is required"),
      // date_of_sale: Yup.string()
      //   .required("Order date is required"),
      // overall_status: Yup.string()
      //   .required("Overall Status is required"),
    });

    const form = ref({
      organization_id: null,
      organization_person_id: null,
      contract_responsible: null,
      overall_status: 'New',
      date_of_sale: new Date(),
      contract_description: '',
    });

    const selectedPerson = ref(null);

    const createContract = async () => {
      const data = await store.dispatch('contract/createContract', { ...form.value, date_of_sale: changeFormate(form.value.date_of_sale) });
      if(data.status){
        handleCancel();
      }
      
    };
    const handleCancel = () => {
      const query = route.query.add;
      if (query === "org-details") {
        router.push({
          path: "/crm/organisation-details",
          query: {
            org_Id: route.query.org_Id,
            territory_id: route.query.territory_id,
          },
        });
      } else {
          router.push("/crm/contracts-list");
      }
    };
    const fetchResponsibles = async (role) => {
      if (role === 'role') {
        form.value.territories = [];
      }

      const selectedRole = form.value.role;
      let selectedTerritoryIds = [];
      const selectedTer = form.value.territories;

      // Determine if multiple selections are allowed
      if (isMultiple.value && Array.isArray(selectedTer)) {
        selectedTerritoryIds = selectedTer.map(territory => territory.id);
      } else if (!isMultiple.value && selectedTer && selectedTer.id) {
        selectedTerritoryIds = [selectedTer.id];
      }

      if (selectedRole !== '') {
        try {
          const response = await apiClient.get('/dropdown/company/assigned-managers', {
            params: {
              role: selectedRole,
              territory_ids: selectedTerritoryIds.length > 0 ? selectedTerritoryIds : []
            }
          });
          responsibleUsers.value = response.data.map(user => ({
            id: user.id,
            name: `${user.first_name} ${user.last_name}`
          }));
        } catch (error) {
          console.error('Error fetching roles:', error);
        }
      }
    };
    // Watch for changes to selectedPerson
    watch(selectedPerson, (newVal) => {
      if (newVal) {
        form.value.organization_person_id = newVal.id; // Assign only the ID
      } else {
        form.value.organization_person_id = null; // Clear the ID if nothing is selected
      }
    });

    watch(searchQuery, (newQuery) => {
      if (!newQuery) {
        isOrganisationSelected.value = false;
      }
    });
    watch(searchPersonQuery, (newQuery) => {
      if (!newQuery) {
        isPersonSelected.value = false;
      }
    });
    onMounted(async () => {
      const orgId = route.query.org_Id;
      if (orgId) {
        const defaultOrganisation = orgOptions.value.find(org => org.id === orgId);
        if (defaultOrganisation) {
          selectOrganisation(defaultOrganisation);
        }
      }
      store.dispatch('opportunity/getorgOptions');
      store.dispatch('contract/fetchContractOptions');
    });

    return {
      schema,
      form,
      orgOptions,
      Options,
      handleCancel,
      searchQuery,
      filteredOrganisations,
      searchOrganisations,
      selectOrganisation,
      createContract,
      fetchResponsibles,
      orgPersons,
      saleResponsibleOptions,
      apiErrors,
      onInput,
      selectedPerson,
      isOrganisationSelected,
      isPersonSelected,
      searchPersons,
      selectPerson,
      onPersonInput,
      searchPersonQuery,
      filteredPersons,
      onFocus,
      searchPersonQuery,
      isFocused,
      onBlur,
      onBlurPerson,
      onFocusPerson,
      isPersonFocused,
    };
  },
  watch: {
    saleResponsibleOptions: {
      immediate: true,
      handler(newOptions) {
        if (newOptions.length === 1) {
          this.form.contract_responsible = newOptions[0].id;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.meter-opp {
  .card {
    .card-body {
      min-height: 80vh;
    }
  }
}

.dropdown-listing {
  overflow: auto;
  max-height: 250px;
}
</style>
